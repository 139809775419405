import Article from "../../containers/Overviews/Article";
import Audience from "../../containers/Overviews/Audience";
import Brand from "../../containers/Overviews/Brand";
import Currency from "../../containers/Overviews/Currency";
import Dashboard from "../../containers/Dashboard/Dashboard";
import Language from "../../containers/Overviews/Language";
import ProductCategory from "../../containers/Overviews/ProductCategory";
import Maintenance from "../../containers/Overviews/Maintenance";
import MaintenanceType from "../../containers/Overviews/MaintenanceType";
import PartnerTier from "../../containers/Overviews/PartnerTier";
import Platform from "../../containers/Overviews/Platform";
import PriceBook from "../../containers/Overviews/PriceBook";
//import PriceBookStatus from "../../containers/Overviews/PriceBookStatus";
//import PriceList from "../../containers/Overviews/PriceList";
import Products from "../../containers/Overviews/Products";
import ProductFamily from "../../containers/Overviews/ProductFamily";
import ProductRelationType from "../../containers/Overviews/ProductRelationType";
import ProductType from "../../containers/Overviews/ProductType";
import Process from "../../containers/Overviews/Process";
import ProcessStep from "../../containers/Overviews/ProcessStep";
import Program from "../../containers/Overviews/Program";
import Property from "../../containers/Overviews/Property";
import Size from "../../containers/Overviews/Size";
import ProductStatus from "../../containers/Overviews/ProductStatus";
import Unit from "../../containers/Overviews/Unit";
import Version from "../../containers/Overviews/Version";
//import VersionRelationType from "../../containers/Overviews/VersionRelationType";
import ProductWorkflow from "../../containers/ProductWorkflow/ProductWorkflow";
import PricebookWorkflow from "../../containers/PricebookWorkflow/PricebookWorkflow";
import ComparePricebooksLayout from "../../containers/ComparePricebooks/ComparePricebooksLayout";
import { Partners } from "../../containers/SalesApp/Partners";
import { FunctionComponent } from "react";
import Start from "./Start";
import PricebookDetail from "../../containers/SalesApp/PricebookDetail";
import Pricebooks from "../../containers/SalesApp/Pricebooks";
import PartnerManage from "../../containers/Partner/PartnerManage";
import { SmartSellerMain } from "../../containers/SalesApp/SmartSeller/SmartSellerMain";
import { Checklist } from "../../containers/SalesApp/Checklist";
import { SmartSellerDashboard } from "../../containers/SalesApp/Dashboard";
import Relations from "../../containers/Relations/Relations";


export type RouteConfig = {
    key: string,
    path: string,
    component: FunctionComponent|any,
    props?: {[key:string]: any},
    children?: JSX.Element | JSX.Element[]
}

export const pricebooksRoutes: RouteConfig[] = [
    {key:"prf_id",                  path:"product_family/:id",      component: ProductWorkflow},
    {key:"pbk_id",                  path:"pricebook/:id",           component: PricebookWorkflow},
    {key:"pricebook",               path:"pricebook",               component: PriceBook},
    {key:"comparePricebooks",       path:"comparePricebooks",       component: ComparePricebooksLayout},
    {key:"audience",                path:"audience",                component: Audience},
    {key:"brand",                   path:"brand",                   component: Brand},
    {key:"dashboard",               path:"dashboard",               component: Dashboard},
    {key:"product_family",          path:"product_family",          component: ProductFamily},
    {key:"article",                 path:"article",                 component: Article},
    {key:"currency",                path:"currency",                component: Currency},
    {key:"language",                path:"language",                component: Language},
    {key:"maintenance",             path:"maintenance",             component: Maintenance},
    {key:"maintenance_type",        path:"maintenance_type",        component: MaintenanceType},
    {key:"partner_tier",            path:"partner_tier",            component: PartnerTier},
    {key:"platform",                path:"platform",                component: Platform},
    {key:"products",                path:"products",                component: Products},
    {key:"product_relation_type",   path:"product_relation_type",   component: ProductRelationType},
    {key:"product_type",            path:"product_type",            component: ProductType},
    {key:"process",                 path:"process",                 component: Process},
    {key:"process_steps",           path:"process_steps",           component: ProcessStep},
    {key:"program",                 path:"program",                 component: Program},
    {key:"property",                path:"property",                component: Property},
    {key:"size",                    path:"size",                    component: Size},
    {key:"status",                  path:"status",                  component: ProductStatus},
    {key:"unit",                    path:"unit",                    component: Unit},
    {key:"version",                 path:"version",                 component: Version},
    {key:"product_category",        path:"product_category",        component: ProductCategory},
    {key:"main",                    path:"main",                    component: Start},
    {key:"manage",                  path:"manage",                  component: PartnerManage},
    {key:"relations",               path:"relations",               component: Relations},
]

export const salesRoutes: RouteConfig[] = [
    {key:"pricebookDetail", path:"sales/pricebook/:id", component: PricebookDetail},
    {key:"pricebook", path:"sales/pricebook", component: Pricebooks},
    {key:"main", path:"sales/main",  component: Start},
    {key:"partners", path:"sales/partners",  component: Partners},
    {key:"smartseller", path:"sales/smartseller",  component: SmartSellerMain},
    {key:"checklist", path:"sales/checklist",  component: Checklist},
    {key:"dashboard", path:"sales/dashboard",  component: SmartSellerDashboard},
]