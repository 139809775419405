import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys, deleteRelation } from "../../models/config";
import { AdminConfig } from '../../components/MasterDetail/types';

import { product_category, pct_prd } from '../../models/config_all';

export function ProductCategory(props:any){
    const defaultVisibleColumns = ["prf_id","name"]
    const visibleColumns = props.visibleColumns !== undefined ? props.visibleColumns : defaultVisibleColumns

    let config = formatConfig(product_category,{visibleColumns: visibleColumns});
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'pct_prd':
                relConfig = pct_prd;
                break;
            default:
                relConfig = product_category;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });
    
    //delete unwanted RLs
    config.relations = deleteRelation(config.relations,"pct_prd")

    return(
        <Overview 
            model="product_category" 
            pk="pct_id" 
            config={config}
            {...props}
        />
    )
}

export default ProductCategory;


