import React, { useReducer, useEffect, useState, useCallback } from "react";
import { DataGrid, ActionSheet } from "devextreme-react";
import { Item } from 'devextreme-react/action-sheet';
import { Editing, Paging, Pager, FilterRow, Column } from "devextreme-react/data-grid";
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

//custom components
import { APIStore, DataLoader } from '../../models/store';
import Loading from '../Loading/Loading';
import Detail from "./Detail";
import { getColumnElements } from "../../models/config";

//css
import "./Overview.css";

/*
*   there is a lot of passing props around these overview/detail components, 
*   so all type definitions come from a central place to avoid multiple definitions
*/

//types
import { OverviewProps, OverviewState, OverviewAction } from './types';
import { clientKey } from "../../constants";
import BulkEdit from "../BulkEdit/BulkEdit";

type pageOptions = {
    hideHeader: boolean,
    hideDetailPage: boolean,
} 

type editorOptions = {
    mode: string,
    allowAdding: boolean,
    allowUpdating: boolean,
    allowDeleting: boolean,
    useIcons: boolean,
    form?: any
}

//variables
let _isMounted = false;
const initialState:OverviewState = {
    selectedEntry:{},
    fkData:{},
    editRowItem:{},
    actionSheetTarget:undefined
};

//we need access to the store from outside the main function, therefore define globally
var storeObject:APIStore;
var store:CustomStore;



export function Overview(props:OverviewProps){
    //* #####################################
    //* ###### ( C A L C U L A T E D ) ######
    //* ######## C O N S T A N T S ##########
    //* #####################################
    const dataLoader:DataLoader = new DataLoader();

     //we use a custom store which calls our own pricebooks rest API
     storeObject = new APIStore()
     if(props.include !== undefined){
          //include join tables if defined
          storeObject.setInclude(props.include);
     }
     store = storeObject.create(props.model,props.pk)
     const ds = new DataSource({
          store: store,
          sort: "name",
          filter: props.filter
     });

    let selfReferencingFks:string[] = [];
    Object.keys(props.config.fks).forEach((key:string) => {
        if(props.config.fks[key].model === props.config.table){
            selfReferencingFks.push(key);
        }
    });
        
    let formattedTitle = props.model.split("_").join(" ").replace(/(\w)(\w*)/g,function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
    
        
    //* #####################################
    //* ##### H O O K S   &   S T A T E #####
    //* #####################################
    /*  
       a reducer manages your state for you. 
       it also avoids passing lots of litte functions down to your components
       you need to define your initial state and a function that processes your state changes
       you get the state variable ("state") and a function to call your reducer with ("dispatch")
       they return the content of the next state depending on the provided action
       action can be a simple string or have complex data attached
    */

    //Ausnahmen, da Schreibweise sich geändert hat und Models nicht angepasst werden können
    if (formattedTitle === "Pricebook" ) {
        formattedTitle = "Price Book";
    } else if (formattedTitle === "Pricelist" ) {
        formattedTitle = "Price List";
    }

    function reducer(state: OverviewState, action:OverviewAction) {
        // ...operator (also called 'spread operator') makes a shallow copy of the original variable
        let newState = {...state};
        switch(action.type){
            //edit is called when clicking the pen button in the datagrid row; overrides standard behaviour
            case 'edit':
                newState.selectedEntry = action.data;
                // hide action sheet
                break;
            case 'copy':
                // clone current entry
                let newEntry = {...action.data};
                if (newEntry.name !== undefined){
                    newEntry.name += ' (copy)'
                }
                // clear PK
                if(typeof store.key() !== "string"){
                    (store.key() as string[]).forEach((key:string) => {
                        newEntry[key] = null;
                    });
                }else{
                    newEntry[(store.key() as string)] = null;
                }
                // insert entry
                store.insert(newEntry)
                .then( (response) => {
                    // use new entry from store to edit
                    newState.selectedEntry = response.data;
                });
                break;
            case 'save':
                //this action will be called from the detail to update or create the currently selected row
                //the store is where our data is stored, in this case the store is our custom API store
                if (action.data[store.key()[0]] === undefined || action.data[store.key() as string] === null || action.data[store.key() as string] === "") {
                    store.insert(action.data);
                    newState.selectedEntry = {};
                } else {
                    store.update((action.data as any)[store.key() as string],action.data);
                    newState.selectedEntry = action.data;
                }
                // update self referencing fk lookups
                selfReferencingFks.forEach(async (key) =>{
                    await dataLoader.getDatafromAPI(props.config.fks[key].model).then((result)=>{
                        newState['fkData'][key] = result.data;
                    })
                })
                break;
            case 'delete':
                //this action will be called from the detail to update the currently selected row
                //the store is where our data is stored, in this case the store is our custom API store
                store.remove((action.data as any)[store.key() as string])
                break;
            case 'back':
                //this action will be called from the detail to get back to the overview page by clearing the selected Entry
                newState.selectedEntry = {};
                break;   
            case 'fkLoad':
                const fkData = action.data.fkData
                Object.keys(fkData).forEach((fkKey:string)=>{
                    const curFkData = fkData[fkKey].filter((row:{[key:string]:any})=>{
                        let bMatch = true
                        if(action.data.filter !== undefined){
                            Object.keys(action.data.filter).forEach((filterKey:string)=>{
                                if(row[filterKey] !== undefined && row[filterKey] !== action.data.filter[filterKey]){
                                    bMatch = false
                                }
                            })
                        }
                        return bMatch
                    })
                    newState['fkData'][fkKey] = curFkData
                })
                break;
            case "fkClear":
                newState['fkData'] = {}
                break;
            case "setActionSheet":
                // set actionsheet visible
                if(action.data.actionSheetTarget !== undefined){
                    newState.actionSheetTarget = action.data.actionSheetTarget;
                    // remember selected row data
                    newState.editRowItem = action.data.actionSheetAction;
                }
                break;
            case "hideActionSheet":
                if(action.data.name === 'visible') {
                    if(!action.data.value) {
                        newState.actionSheetTarget = undefined;
                    };
                }
                break;
            default:
        }
        
        return newState;
    }

    // functions with "use".. are hooks. they must not be contained in conditional statements
    const [dataGridInstance, setDataGridInstance]: any = useState(undefined);
    const [currentEditKey, setCurrentEditKey]: any = useState(undefined);
    const [state, dispatch] = useReducer(useCallback(reducer,[]), initialState);

    const loaded = Object.keys(props.config.fks).length === Object.keys(state.fkData).length

    useEffect(() => { 
        /*  
        *   this replaces the componentDidMount function
        *   when this component is mounted, all data from referenced tables 
        *   (props.config.fks) gets loaded
        */

        //keep track of mounted state, so we can avoid setting state on unmounted components
        _isMounted = true;
        dispatch({type:"fkClear"})

        //loaded is true when all fk tables have been loaded
        let promises:any[] = Object.keys(props.config.fks).map((key) => dataLoader.getDatafromAPI(props.config.fks[key].model));
        Promise.all(promises)
        .then((result)=>{
            let res:{[key:string]:object} = {};
            //loop the fetch results and add them to a result object containing all the keys with their fk data
            for(let i=0;i<result.length;i++){
                res[Object.keys(props.config.fks)[i]] = result[i].data;
            }
            if(_isMounted){
                dispatch({type:"fkLoad",data:{fkData:res,filter:props.selectedPKs}});
            }
        });

        return function cleanup() {
            //gets executed when the component gets unmounted
            _isMounted = false;
        }
    },
        //you can specify variables that cause this function to be executed, no variables = (un)mount only
        [props.config,props.selectedPKs] // eslint-disable-line
    );

    useEffect(()=>{
        if(props.selectedEntry !== undefined){
            dispatch({type:'edit',data:props.selectedEntry})
        }
    },[props.selectedEntry])
    
    //* show an individual loading component while waiting for the data
    if(!loaded){
        return <Loading/>
    }

    //* #####################################
    //* ##### C O N F I G U R A T I O N #####
    //* #####################################

    let pageOptions:pageOptions = {
        hideHeader: false,
        hideDetailPage: false,
    } 
    pageOptions = Object.assign(pageOptions, props.pageOptions)
    const newRowisDetail:boolean = (props.newRowisDetail === undefined) ? true : props.newRowisDetail;
    let editorOptions:editorOptions = {
        mode: 'row',
        allowAdding: true,
        allowUpdating: false,
        allowDeleting: false,
        useIcons: true,
    }
    editorOptions = Object.assign(editorOptions, props.editorOptions)

    //* Form options
    if(editorOptions.mode === 'form'){
        //define columns to show
        editorOptions['form'] = {items:props.config.columns.map( item => {
            let result:any = {
                dataField: item.dataField,
                type: item.type,
                validationRules: [],
                visible: item.dataField !== clientKey
            };

            //add validation
            if(item.is_required_field === 1){
                result.validationRules.push({
                    type: "required",
                    message: "this field is required"
                })
            }
            return result
        })}

        editorOptions.form.onInitialized = (e:any)=>{ 
            // in case of self referencing columns, add validation to avoid circular reference
            if(selfReferencingFks.length){
                let regex = RegExp("^((?!" + currentEditKey + ").)*$"); //matches everything except the current rows primary key value
                e.component._options.items.forEach((column:any)=>{
                    selfReferencingFks.forEach(selfReferencingFkColumn=>{
                        if(column.dataField === selfReferencingFkColumn){
                            column.validationRules.push({
                                type: "pattern",
                                pattern: regex,
                                message: "This field cannot point to its own value!"
                            });
                        }
                    });
                });
            }
        };
    }    

    //* if you have selected a record, show the Detail component
    if(Object.keys(state.selectedEntry).length){
        if(props.detailRender === undefined){
            return (
                <Detail 
                    //using a reducer actually reduces the amount of functions passed to our child property
                    ovDispatch={dispatch} 
                    entry={state.selectedEntry} 
                    selectedPKs={props.selectedPKs}
                    config={props.config}
                    fkData={state.fkData}
                />
            )
        }else{
            //if detailRender is provided get values from "passProp" and render the component
            let componentProps:{[key:string]:string} = {}

            for(let key in props.detailRender.passProps){
                componentProps[key] = eval(props.detailRender.passProps[key]) // eslint-disable-line
            }
            return React.createElement(props.detailRender.component, componentProps, null)
        }
    }

    // icon to activate actionsheet 
    const OvActionSheetIcon = (item:any) => {
        const actionSheetTarget = "actionSheetTarget_" + item.rowIndex;
        return (
            <i 
                id={actionSheetTarget}
                onClick={() => {dispatch(
                    {
                        type:"setActionSheet",
                        data:{
                            actionSheetAction:item.data,
                            actionSheetTarget:item.cellElement
                        }
                    }
                )}}
                className={"fa fa-ellipsis-h fa-lg editColumnItem"}
                aria-hidden={"true"}
            ></i>
        )
    }

    let bulkEdit = <></>
    if(props.bulkEdit !== undefined){
        bulkEdit = (
            <BulkEdit 
                dsKey={props.pk as string} 
                dataGridInstance={dataGridInstance}
                editColumn={props.bulkEdit.editColumn||"price"}
                {...props.bulkEdit}
            />
        )
    }


    //* #####################################
    //* # D A T A   G R I D   A C T I O N S #
    //* #####################################

    const onInitNewRow = function (e:any) {
		//e.data = {...props.selectedPKs,clt_id:localStorage.getItem('client') || ''}
        const data = {...props.selectedPKs,clt_id:localStorage.getItem('client') || ''}
        if (newRowisDetail) {
            dispatch({type:"edit",data:data})
        }
    }


    //* #####################################
    //* ############ O U T P U T ############
    //* #####################################

    return(
        //React.Fragment groups contents together without having to use another dom element (div etc.)
        <React.Fragment>
            { !pageOptions.hideHeader &&
                <h4 className="overviewTitle">{formattedTitle}</h4>
            }
            {bulkEdit}
            <div id="gridContainer" className={pageOptions.hideHeader?"noOuterPadding":""}>
                <DataGrid dataSource={ds} 
                    //call our dispatch methods, providing the context of the clicked element
                    onInitNewRow={onInitNewRow}
                    //other parameters are standard widget configuration, options could be included in props.config if necessary
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    onEditingStart={(e:any)=> {setCurrentEditKey(e.key)}}
                    onRowRemoved={(e:any) => {
                        selfReferencingFks.forEach( key => {
                            dataLoader.getDatafromAPI(props.config.fks[key].model).then((result)=>{
                                let res:any = {};
                                res[key] = result.data;
                                dispatch({type:"fkLoad",data:{fkData:res,filter:props.selectedPKs}});
                            })
                        })
                    }}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnChooser={{enabled:true, mode:'select'}} 
                    loadPanel={{enabled:false}}
                    selection ={props.bulkEdit === undefined ? {} : {
                        mode:"multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always",
                        allowSelectAll: true
                    }}
                    >
                    <Editing {...editorOptions}/>
                    
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>
                    
                    {
                        getColumnElements(props.config.columns,state.fkData,props.config.fks,props.selectedPKs)
                    }

                    <Column 
                        cellRender={!pageOptions.hideDetailPage ? OvActionSheetIcon : undefined}
                        alignment={"center"}
                        showInColumnChooser={false}
                    />

                </DataGrid>
                {/* Use single actionsheet for all rows */}
                { !pageOptions.hideDetailPage &&
                    <ActionSheet 
                        target={state.actionSheetTarget}
                        onOptionChanged={(e:any) => {
                            if(e.name === "visible"){
                                dispatch({type:"hideActionSheet", data:e} )
                            }
                        }}
                        visible={state.actionSheetTarget !== undefined}
                        className={"actionSheet"}
                        // onContentReady={}
                        usePopover={true}
                        showTitle={false}
                        // submit action and row data
                        onItemClick={(e:any) => dispatch({type:e.itemData.type, data:state.editRowItem})}
                        showCancelButton={true}
                    >
                        <Item text={"Edit"} icon={"fas fa-edit"} type={"edit"} />
                        <Item text={"Copy"} icon={"fas fa-file"} type={"copy"} />
                        <Item text={"Delete"} icon={"fas fa-trash-alt"} type={"delete"} />
                    </ActionSheet>
                }
            </div>
        </React.Fragment>
    )

}



export default Detail;