import React from 'react';


//Product Workflow

export const prfPropertyInfo = {
  short: "Edit the properties of a product family",
    long: "Edit the base data of a product family. Click \"save\" to apply the changes.",
}

export const productCategoryInfo = {
  short: "Define the categories of your products",
    long:(
    <>
      <h4>Usage:</h4>
            <p><b>
                Create new categories by clicking „Add new category“. <br/>
                Provide a name for the category and add the appropriate values for it by clicking „Add new value“.<br/>
                Use the arrow keys to sort the categories and their values. Click „Save“ to apply your configuration.
            </b></p>
      <h4>Categories:</h4>
      <p>
                Categories represent the composition of your product family by calssifying your product‘s types/characteristics into different groups.<br/>
                A product family could have a category named „Edition“ and „License“ for example.
      </p>
      <h4>Values:</h4>
      <p>
                Values define the actual available variants of each category.<br/>
                Your category „Edition“ for example could have the values „Standard“ and „Premium“.<br/>
                A single product consists of one value for each category in the specified sort order, e.g. „Standard edition concurrent license“.<br/>
                A product's price is calculated by multiplying the product family‘s base price with each variant‘s „factor“ property.<br/>
        The SKU string is constructed from the value‘s' „SKU Label“ property.
      </p>
      <h4>Fields:</h4>
      <p>
                A product's price is calculated by multiplying the product family base price with every choice's „factor“ property along the way. <br/>
        The SKU string is also constructed from the values' „SKU Label“ property
      </p>
      <h4>Sort Order:</h4>
      <p>
                Categories and their values should be sorted from most to least valuable because their sorting determines how products can be upgraded.<br/>
                If you should be able to upgrade from product A to product B, B must be further above in the list.
      </p>
    </>
    ),
}

export const generateProductsInfo = {
  short: "Generate products",
    long: (<>
        Below you now see all possible combinations for the just defined category values. Those combinations will form your products.<br/> 
        The „Type“ column`s colour coding informs you what kind of changes are being applied to your database:
        <ul style={{fontWeight:"bold"}}>
            <li style={{color:"var(--success-text-color)"}}>New</li>
        <p>The product does not yet exist. It will be created if checked.</p>
            <li style={{color:"var(--update-text-color)"}}>Update</li>
            <p>The product already exists, but changes haven been applied. It will be updated if checked.</p>
            <li style={{color:"var(--error-text-color)"}}>Delete</li>
            <p>The product exists, but is absent in the currently generated result. It will be deleted if checked.</p>
      </ul>
        Products that are already up to date do not appear on this page.<br/> 
      Check the suggested changes to your products and hit "Save" to apply them.
    </>),
}

export const productInfo = {
  short: "Edit products",
    long: (<>
        Select a product to edit or delete it. <br/>
        Caution: When you delete a product, all upgrades and articles related to the product will also be deleted.
    </>)
}

export const generateUpgradesInfo = {
  short: "Generate upgrades",
    long: (<>
        This page generates all possible upgrades. Products will only be paired with those who are at higher level.<br/>
        Whether or not a product is considered at higher level than another is defined by the sort order in the "Categories" step<br/>
        Upgrades that already exist and are present in the currently generated result will not appear on this page.<br/>
        The „Type“ column`s colour coding informs you what kind of changes are being applied to your database:
        <ul style={{fontWeight:"bold"}}>
            <li style={{color:"var(--success-text-color)"}}>New</li>
        <p>The upgrade does not yet exist. It will be created if checked.</p>
            <li style={{color:"var(--error-text-color)"}}>Delete</li>
            <p>The upgrade exists, but is absent in the currently generated result. It will be deleted if checked.</p>
      </ul>
        Check the suggested upgrades you want to create and hit „Save“ to create them.
    </>),
}

export const upgradeInfo = {
  short: "Edit upgrades",
  long: "Select an upgrade to edit or delete it."
}

export const generateArticlesInfo = {
  short: "Generate articles",
    long: (<>
        Below you now see all possible combinations of your products, upgrades and maintenances that will result in your articles.<br/>
        The „Type“ column`s colour coding informs you what kind of changes are being applied to your database:
        <ul style={{fontWeight:"bold"}}>
            <li style={{color:"var(--success-text-color)"}}>New</li>
        <p>The article does not yet exist, It will be created if checked.</p>
            <li style={{color:"var(--update-text-color)"}}>Update</li>
            <p>The article already exists, but changes haven been applied. It will be updated if checked.</p>
            <li style={{color:"var(--error-text-color)"}}>Delete</li>
            <p>The article exists, but is absent in the currently generated result. It will be deleted if checked.</p>
      </ul>
        Articles that are already up to date do not appear on this page.<br/> 
      Check the suggested changes to your articles and hit „Save“ to apply them.
    </>
  )
};
export const importPricesInfo = {
  short: "Import articles from legacy price books",
  long: (
    <>
      If you have a legacy price book that you want to use instead of using category based prices you can import your existing prices and SKUs here.
      <br/><br/>
      Please upload a valid CSV file containing articles from your legacy price book to compare SKUs and prices and update the generated articles. <br/>
      The CSV file should have the following layout:
      <b>SKU;Article Name;Price</b> and should only contain articles from the selected product family. 
      <br/><br/>
      To help appacus match articles more accurately you can enforce certain <b>keywords</b>.<br/>
      A keyword is defined as a word that <b>must be part of</b> the article name on both lists.<br/> 
      It is always a good idea to choose values for your product categories as your keywords. If you like to have more than one keyword please separate each of them with a comma (e.g. Standard,Workgroup). 
      <br/><br/>
      After hitting submit the CSV file will be imported and appacus matches generated articles with the import list.<br/> 
      This will show a list of article pairs:<br/> 
      The upper one is the generated article from appacus and the lower one is a dropdown list of imported articles that best match the generated article. If you select a different article from the list the SKU and price column will change accordingly.<br/>
      The different text colors represent if the selected list item is a 
      <span style={{color:"var(--success-text-color)"}}> good/perfect</span>,
      <span style={{color:"var(--update-text-color)"}}> average</span>,or 
      <span style={{color:"var(--error-text-color)"}}> bad </span> 
      match for the current article.<br/>
      Articles that are identical (same SKU and price) are invisible by default (this can be changed by clicking the eye symbol).
      <br/><br/>
      <b>IMPORTANT:</b><br/> 
      Please always check all associations before saving!
      You can select <b>(empty)</b> if no item from the list matches your article. In this case no change will be made.<br/> 
      After checking and modifying all articles you can hit the "Save" button to modify the generated articles. After that you will see a summary of your import.
    </>
  )
};

export const articleInfo = {
  short: "Edit articles",
  long: "Select an article to edit or delete it."
}

//Pricebooks Workflow

export const pbkPropertyInfo = {
  short: "Edit the properties of a price book",
    long: "Edit the base data of a price book. Click \"Save\" to apply the changes.",
}

export const pbkArticlesInfo = {
  short: "Assign articles to this price book",
    long: "Check the articles that shall appear on this price book. Click \"Save\" to apply the changes",
}

export const pbkArticlesEditInfo = {
  short: "Edit assigned articles from this price book",
    long: (<>
        Check the articles that shall be edited and use the operations with a value to edit them.<br/>
      Click „Save“ to apply the changes.
      <ul>
        <li>+ : adds the value to all selected articles</li>
        <li>- : subtracts the value from all selected articles</li>
        <li>× : multiplies all selected articles with the inserted factor</li>
        <li>÷ : divides all selected articles by the divisor</li>
            <li>+ % : adds the procentual price to all selected articles (e.g. 150 +% 10 = 165)</li>
            <li>- % : subtracts the procentual price from all selected articles (e.g. 150 -% 10 = 135)</li>
            <li>round ≈ : dynamically rounds up/off the price to the given decimal place.</li>
        <li>round up ≃ : rounds the price up to the given decimal place.</li>
        <li>round off ≂ : rounds the price off to the given decimal place.</li>
      </ul>
        Note: If the rounding operator is selected, use only 0 and a single 1 (e.g. 100 or 0.1)
    </>)
}

export const priceListInfo = {
  short: "Manage price lists",
  long: "Manage existing price lists for this price book"
}

export const pricelistArticleInfo = {
  short: "Check individual prices per price list",
  long: "Select the price list you want to view prices for."
}

export const pbkShareInfo = {
  short: "Share price book",
    long: "Select a file type and define a file name. By default, the file name will be the price book's \"Label\" value. Click \"Download Excel\" to download the file."
}

export const pricebookCompareInfo = {
  short: "Price book comparison",
    long: <>
        Select base and target price books to compare them.<br/>
        The comparison will show you which values have been changed from base to target price book.<br/>
      The „Type“ column shows which type of change applies to one article:
        <ul style={{fontWeight:"bold"}}>
            <li style={{color:"var(--success-text-color)"}}>New</li>
            <p>The article is not assigned to the base price book, but to the target price book.</p>
            <li style={{color:"var(--error-text-color)"}}>Deleted</li>
            <p>The article is assigned to the base price book, but not to the target price book.</p>
            <li style={{color:"var(--neutral-text-color)"}}>edited</li>
            <p>The article is assigned to both price books, but values are different.<br/>The differences are listed in one cell, the upper value belongs to the base and the lower one to the target price book.</p>
            <li style={{color:"grey"}}>equals</li>
            <p>The article is assigned to both price books and their values are equal.</p>
      </ul>
        Note: The articles are associated by their id. If you delete one article and create it again, they will be handled as different articles.
    </>
}

export const priceBookPublishInfo = {
  short: "Publish Price Book",
    long: <>
            When you choose to publish a price book its properties and all objects related to it (article prices, price lists, etc.) will be locked and can not be changed anymore. <br/>
            This will be required for sharing your price book with others.<br/><br/>
            <span >
                <i className={"fas fa-exclamation-circle"} style={{color:"var(--error-text-color)"}}></i>&nbsp;
                Please keep in mind that this action 
                <span style={{color:"var(--error-text-color)",fontWeight:"bold"}}>
          &nbsp;can not be undone
        </span>
        , so please make sure to check your pricelist before publishing.
      </span>
    </>
 
}