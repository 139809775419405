import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';


import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';


import './index.css';
import { Main } from './components/Main/Main';
import { Router, Switch, Route } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { Login } from './containers/Login/Login';
import showToast from './components/handleError';
import { Application } from './models/types';



//for more options: https://docs.bugsnag.com/platforms/javascript/react/configuration-options/
type bugsnagOptions = {
    apiKey:string,
    metaData:Object,
    autoNotify?: boolean,
};

var bugsnagOptions:bugsnagOptions = {
    apiKey: '0909ea9648b59f1cd2dd157c9e7fe0f1',
    metaData: {
        hostUrl: window.location.hostname
    }
    
};

if (window.location.hostname === "localhost") {
    bugsnagOptions.autoNotify = false;
}

const bugsnagClient = bugsnag(bugsnagOptions);
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');



export type AppState = {
    application: Application,
    loggedInApplication: Application,
    loggedIn: boolean,
}

export const history = createBrowserHistory();

ReactDOM.render(
    <ErrorBoundary>
        <Appacus/>
    </ErrorBoundary>
    , document.getElementById('root')
);

function Appacus(){

    const [appState,setAppState] = useState<AppState>({
        loggedIn: localStorage.getItem('loggedIn') === "true",
        loggedInApplication: localStorage.getItem('loggedInApplication') as Application,
        application: window.location.href.indexOf("/sales") === -1 ? "pricebooks" : "sales"
    })
 
    useEffect(() => { 
        //handle Logout
        if(appState.loggedIn === false){
            if(localStorage.getItem('msg') === "Logout"){
                showToast("success","Logout successful")
            }else if(localStorage.getItem('msg') === "Session"){
                showToast("error","Your session has timed out. Please log in again",{autoClose:10000})
            }
            localStorage.clear();
        }
        setAppState({...appState, loggedIn: localStorage.getItem('loggedIn') === "true"})
    },[appState.loggedIn]); // eslint-disable-line

    useEffect(()=>{
        if(appState.application === "sales"){
            document.title = "appaki Sales";
        }else if(appState.application === "pricebooks"){
            document.title = "appaki Price Books";
        }
    },[appState.application])

    const handleLogin = (app:Application) => {
        localStorage.setItem('loggedInApplication',app)
        setAppState({...appState,loggedIn: true, application:app, loggedInApplication:app})
    }

    const handleLogout = () => {
        setAppState({...appState, loggedIn: false})
        localStorage.setItem('loggedIn', "false");
        localStorage.setItem('msg', "Logout");
    }

    return(
        <Router history={history}>
            <>
                <ToastContainer/>
                <Switch>
                    <Route exact path="/sales" render={ (props) => <Login {...props} appState={appState} handleLogin={handleLogin}/> }/>
                    <Route exact path="/" render={ (props) => <Login {...props} appState={appState} handleLogin={handleLogin}/> }/>
                    <Route path={"/*"} key={"start"} render={
                        (props) => <Main {...props} appState={appState} handleLogout={handleLogout} />
                    }/>
                </Switch>
            </>
        </Router>
    )   
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();