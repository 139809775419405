import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { process } from '../../models/config_all';

export function Process(props:any){
    const defaultVisibleColumns = ["position","name","label"]
    const visibleColumns = props.visibleColumns !== undefined ? props.visibleColumns : defaultVisibleColumns

    let config = formatConfig(process,{visibleColumns: visibleColumns});

    return(
        <Overview 
            model="process" 
            pk="prc_id" 
            config={config}
            {...props}
        />
    )
}

export default Process;
