import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys, deleteRelation } from "../../models/config";
import { AdminConfig } from "../../components/MasterDetail/types";

import { version, ver_vrl } from "../../models/config_all";

export function Version(){
    let config = formatConfig(version);
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'ver_vrl':
                relConfig = ver_vrl;
                break;
            default:
                relConfig = version;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    //delete unwanted RLs
    config.relations = deleteRelation(config.relations,"ver_vrl")

    return(
        <Overview 
            model="version" 
            pk="ver_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default Version;


