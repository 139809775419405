import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Pricebook } from '../../models/types';
import { publishedPbkStatusId } from '../../constants';
import { Popup } from 'devextreme-react';
import { Ajax } from '../../models/ajax';
import Loading from '../../components/Loading/Loading';

type PublishPricebookProps = {
    pbk:Pricebook,
    setSaveButton:(arg0:boolean) => void,
    saveButton:boolean,
}

export function PublishPricebook(props:PublishPricebookProps){
    const [showConfirm,setShowConfirm] = useState(false)
    const [showConfirmUndo,setShowConfirmUndo] = useState(false)
    // const [confirmed,setConfirmed] = useState(false)
    

    useEffect(() => {
        if(props.saveButton){
            props.setSaveButton(false);
        }
    },[props.saveButton]) // eslint-disable-line
    
    const publishPricebook = () => {
        // call api publish action
        props.setSaveButton(true)
        new Ajax('pricebook/'+props.pbk.pbk_id+'/publish/',{
            method: "PATCH",
        }).setMessages("pricebook published","error")
        .execute().then(()=>{
            setShowConfirm(false)
        })
    }

    const unpublishPricebook = () => {
        // call api unpublish action
        props.setSaveButton(true)
        new Ajax('pricebook/'+props.pbk.pbk_id+'/unpublish/',{
            method: "PATCH",
        }).setMessages("pricebook unpublished","error")
        .execute().then(()=>{
            setShowConfirmUndo(false)
        })
    }

    let status = '';
    if(props.pbk.pricebook_status !== undefined){
        status = props.pbk.pricebook_status.label 
    }
    const published = props.pbk.pbs_id === publishedPbkStatusId

    return (
        <>
        {props.saveButton && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
        <div className="detailbackdrop shareDetail">
            <table className={'publish-status'}>
                <tbody>
                    <tr>
                        <th>Price Book:</th>
                        <td>{props.pbk.name}</td>
                    </tr>
                    <tr>
                        <th>Status:</th>
                        <td>
                            <span style={{color: published ? 'var(--error-text-color)':'var(--success-text-color)'}}>
                                {status}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <Button 
                name="publishBtn"
                className="btn-detail" 
                variant="primary" 
                disabled={published}
                style={{marginLeft:0}}
                onClick={() => setShowConfirm(true)}
                >
                Publish Price Book
            </Button>
            <Button 
                name="unpublishBtn"
                className="btn-detail" 
                variant="primary" 
                disabled={!published}
                style={{marginLeft:0}}
                onClick={() => setShowConfirmUndo(true)}
                >
                Unpublish Price Book
            </Button>
            <Popup
                visible={showConfirm}
                width={'auto'}
                height={'auto'}
                onHiding={() => setShowConfirm(false)}
                className={"confirm-popup"}
                closeOnOutsideClick={true}
                >
                {/* Todo: CSS */}
                <h4 className={"publish-warning"}><i className={"fas fa-exclamation-circle"}></i>&nbsp;Warning</h4>
                <span className={"publish-warning"}>
                     You will not be able to edit a price book and everything related to it once it is published.
                </span>
                <br/>
                <br/>
                <p className={'publish-status'}>Are you sure you want to publish the price book "{props.pbk.name}"?</p>
                <Button
                    onClick={() => publishPricebook()}
                    className={"btn-detail confirm-popup"}
                >
                    Yes
                </Button>
                <span className={"button-spacer"}>&nbsp;</span>
                <Button
                    onClick={() => setShowConfirm(false)}
                    className={"btn-detail confirm-popup cancel"}
                >
                    No
                </Button>
            </Popup>
            <Popup
                visible={showConfirmUndo}
                width={'auto'}
                height={'auto'}
                onHiding={() => setShowConfirmUndo(false)}
                className={"confirm-popup"}
                closeOnOutsideClick={true}
                >
                {/* Todo: CSS */}
                <h4 className={"publish-warning"}><i className={"fas fa-exclamation-circle"}></i>&nbsp;Warning</h4>
                <span className={"publish-warning"}>
                     This action can invalidate the integrity of article data! <br/>If you have new changes to article data consider creating a new pricebook to maintain data integrity!
                </span>
                <br/>
                <br/>
                <p className={'publish-status'}>Are you sure you want to undo publishing the price book "{props.pbk.name}"?</p>
                <Button
                    onClick={() => unpublishPricebook()}
                    className={"btn-detail confirm-popup"}
                >
                    Yes
                </Button>
                <span className={"button-spacer"}>&nbsp;</span>
                <Button
                    onClick={() => setShowConfirmUndo(false)}
                    className={"btn-detail confirm-popup cancel"}
                >
                    No
                </Button>
            </Popup>
            <br/>
        </div>
        </>
    );
}

export default PublishPricebook;