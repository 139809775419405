import React, { useState, useEffect } from 'react';

import { DataGrid, Popup, CheckBox } from "devextreme-react";
import { Editing, Paging, Pager, FilterRow, Column, Lookup } from "devextreme-react/data-grid";
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import {Article} from  '../../models/types';
import Loading from '../../components/Loading/Loading';

import { Ajax } from '../../models/ajax';
import { DataLoader } from '../../models/store';

type GenerateArticlesProps = {
    saveState: boolean,
    setSave: (b:boolean)=>void,
    prf_id:string
}

type GeneratedResult = {
    art_id: string,
    type: "update" | "new" | "delete",
    old: Article | null,
    new: Article | null
}

export function GenerateArticles(props:GenerateArticlesProps) : JSX.Element {
    const [reload, setReload]:boolean | any = useState(true);
    const [data, setData]:GeneratedResult[] | any = useState(undefined);
    const [columns, setColumns]:any = useState(["Label",  "SKU", "Price" ]);
    const [maintenances, setMaintenances]= useState<any[]>([]);
    const [popupVisible, setpopupVisible]:any = useState(false);
    const [dataGridInstance, setDataGridInstance]: any = useState(undefined);
    
    //get generated Articles and set in state.data
    useEffect(() => {
        new Ajax('generate/article/' + props.prf_id,{})
        .setMessages("")
        .execute().then(result=>{
            if(result.data.length){
                setData(result.data);
            }else{
                setData([])
            }
        })
    }, [reload,props.prf_id]);

    
    //get Maintenance Types
    useEffect (() => {
        new DataLoader().getDatafromAPI("maintenance", {filter: {prf_id: props.prf_id}, include: "maintenance_type", order: "label"})
        .then(result=>{
            setMaintenances(result.data)
        })
    }, [data]); // eslint-disable-line
    
    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && dataGridInstance !== undefined) {
            let saveData:GeneratedResult[] = [];
            let saveKeys = dataGridInstance.getSelectedRowKeys();
            let ignoreColumns:any = [];
            let dataGridColumns = dataGridInstance._controllers.columns._columns;
            let dataColumns:any = [];

            saveKeys.forEach ((key:string) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].art_id === key) {
                        saveData.push(data[i]);
                    }
                }
            });
            dataGridColumns.forEach ((column:any) => {
                if (column.caption){
                    dataColumns.push(column.caption);
                }
            });
            dataColumns.forEach ((column:any) => {
                if (columns.indexOf(column)<0 && column!=="Type"){
                    ignoreColumns.push(column.toLowerCase());
                }
            });
            new Ajax('generate/article/',{
                method: "PATCH",
                body: JSON.stringify({aGenArticles: saveData, aColumns: ignoreColumns})
            }).setMessages("Successfully saved "+ saveData.length+" articles!","Articles could not be saved!")
            .execute().finally(()=>{
                props.setSave(false)
                setReload(!reload)
            })
        }
    }, [props.saveState]); // eslint-disable-line

    //set type Lookup for Type Column
    const genTypes = [
        {type: "new"},
        {type: "update"},
        {type: "delete"}
    ];

    //set DataStore for Datagrid
    const store = new ArrayStore({
        key: "art_id", 
        data: data
    });

    const ds = new DataSource({
        store: store,
        sort: "type"
    });

    
    //Column Renderers
    const renderTypeColumn = (data:any) => {
        switch (data.value) {
            case "new":
                return <span className = "fa fa-plus typeIcon"></span>;
            case "update":
                return <span className = "fa fa-redo typeIcon"></span>;
            case "delete":
                return <span className = "fa fa-trash-alt typeIcon"></span>;
        }
    };  
    const renderLabelColumn = (data:any) => {
        switch(data.data.type) {
            case "new":
                return <span title={data.data.new.label}>{data.data.new.label}</span>;
            case "update":
                return (<><div title={data.data.new.label}>{data.data.new.label}{data.data.new.label===data.data.old.label?<></>:<><br/><span className = "oldValueFromUpdate">{data.data.old.label}</span></>}</div></>);
            case "delete":
                return <span title={data.data.old.label}>{data.data.old.label}</span>;
        }
    };
    const renderMntTypeColumn = (data:any) => {
        var new_mnt = data.data.new !== null ? data.data.new.mnt_id : undefined;
        var old_mnt = data.data.old !== null ? data.data.old.mnt_id : undefined;

        if( !((data.data.new !== null && data.data.new.mnt_id === null) && ( data.data.old !== null && data.data.old.mnt_id !== null))){
            let mnt_id = new_mnt !== undefined ? new_mnt : old_mnt;
            let mnt = maintenances.find((mnt) => mnt.mnt_id === mnt_id);
            if(mnt !== undefined && mnt.maintenance_type !== undefined){
                return <span title={mnt.maintenance_type.label}>{mnt.maintenance_type.label}</span>;
            }
        }else{
            return <span>-</span>;
        }
    };
    const renderSkuColumn = (data:any) => {
        switch(data.data.type) {
            case "new":
                return data.data.new.sku;
            case "update":
        return (<><span>{data.data.new.sku}</span>{data.data.new.sku===data.data.old.sku?<></>:<><br/><span className = "oldValueFromUpdate">{data.data.old.sku}</span></>}</>);
            case "delete":
                return data.data.old.sku;
        }
    };
    const renderPriceColumn = (data:any) => {
        const priceNew = data.data.new === null ? "" : Number(data.data.new.price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        const priceOld = data.data.old === null ? "" : Number(data.data.old.price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        switch(data.data.type) {
            case "new":
                return priceNew;
            case "update":
        return (<>{priceNew}{priceNew===priceOld?<></>:<><br/><span className = "oldValueFromUpdate">{priceOld}</span></>}</>);
            case "delete":
                return priceOld;
        }
    };
    
    const onValueChanged = (name:string,e:any)=>{
        let newPick = columns;
        if(e.value){
            newPick.push(name);
        } else {
            newPick.splice(newPick.indexOf(name),1);
        }
        setColumns(newPick);
    }

    return(
        <>
            {data === undefined && <Loading textMessage={<span>Loading&#8230;</span>} show={true} />}
            {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
            <button className="btn-detail btn btn-primary column-selection" onClick = {()=> {setpopupVisible(!popupVisible)}}>Columns to update: {columns.join(', ')}</button>
            <Popup
                className={'popup'}
                visible={popupVisible}
                dragEnabled={false}
                onHiding={()=> {setpopupVisible(!popupVisible)}}
                closeOnOutsideClick={true}
                showTitle={true}
                title={'Columns'}
                width={300}
                height={'auto'}
            >
                <>
                    <CheckBox defaultValue={true} text={"Label"} onValueChanged={(e:any)=> onValueChanged('Label', e)}/><br/>
                    <CheckBox defaultValue={true} text={"SKU"} onValueChanged={(e:any)=> onValueChanged('SKU', e)}/><br/>
                    <CheckBox defaultValue={true} text={"Price"} onValueChanged={(e:any)=> onValueChanged('Price', e)}/>
                </>
            </Popup>
            <div id="gridContainer" className = "noOuterPadding">
                <DataGrid
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    dataSource={ds}
                    columnAutoWidth={true}
                    columnChooser={{enabled:false, mode:'select'}}
                    headerFilter = {{visible: true}}
                    loadPanel={{enabled:false}}
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    selection = {{
                        mode:"multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always",
                        deferred: false
                    }}
                    >
                    <Editing
                        mode={'row'}
                    />
                    
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                <Column 
                    name={"type"}
                    alignment={"center"}
                    dataField = {"type"}
                    cellRender = {renderTypeColumn}
                    cssClass = "verticalAlignment col_type"
                    showInColumnChooser={false}
                    width = {"150px"}
                >
                    <Lookup
                        dataSource = {genTypes}
                        valueExpr = {"type"}
                        displayExpr = {"type"}
                    />
                </Column>
                <Column 
                    name={"label"}
                    alignment={"left"}
                    allowHeaderFiltering = {false}
                    caption={"Label"}
                    cellRender = {renderLabelColumn}
                    cssClass = "col_name"
                    dataField = {"new.label"}
                    showInColumnChooser={false}
                    width = {"calc(100% - 600px)"}
                    />
                <Column 
                    name={"label"}
                    alignment={"left"}
                    allowHeaderFiltering = {false}
                    caption={"Maintenance Type"}
                    cellRender = {renderMntTypeColumn}
                    cssClass = "col_name"
                    dataField = {"new.mnt_id"}
                    showInColumnChooser={false}
                    width = {"150px"}
                    />
                <Column 
                    name={"sku"}
                    alignment={"left"}
                    allowHeaderFiltering = {false}
                    caption={"SKU"}
                    cellRender = {renderSkuColumn}
                    cssClass = "col_sku"
                    dataField = {"new.SKU"}
                    showInColumnChooser={false}
                    width = {"150px"}
                    />
                <Column 
                    name={"price"}
                    alignment={"right"}
                    allowHeaderFiltering = {false}
                    caption={"Price"}
                    cellRender = {renderPriceColumn}
                    cssClass = "col_price"
                    dataField = {"new.price"}
                    showInColumnChooser={false}
                    width = {"150px"}
                />
                

                </DataGrid>
            </div>
        </>
    )

}

export default GenerateArticles;