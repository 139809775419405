//global variables
const apiUrlLocal = "http://api.local-pricebooks.com/index.cfm/api/v1/";
// const apiUrlTest = "https://test-appacus-api.heureka.com/index.cfm/api/v1/";
const apiUrlLive = "https://api.appaki.io/index.cfm/api/v1/";

export const getApiUrl = () => {
    if(window.location.hostname === "www.appaki.io"){
        return apiUrlLive
    // }else if(window.location.hostname.includes("test-")){
    //     return "https://" + window.location.hostname + "/api/v1/"
    }else{
        return apiUrlLocal
    }
}

export const clientKey = "clt_id"
export const clientModel = "client"
export const publishedPbkStatusId = "C4E05236-DACC-45AB-9A79C5ECB1A6627E"
export const draftPbkStatusId = "02EF7B5B-BEB9-454E-A455931580E74C14"

export const salesExtension = "sales/"

export const testUsrId = "104"

export const defaultClient = {
    clt_id: "",
    name: "",
    logo_file: "",
    css_file: "",
}

export const alphabetSortName = (a,b) => {
    return a.name.localeCompare(b.name)
};