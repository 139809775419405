import React, { useEffect, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import { Editing, Paging, Pager, FilterRow, Column, Lookup } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Product, Prd_prd } from '../../models/types';
import Loading from '../../components/Loading/Loading';

import { Ajax } from '../../models/ajax';

type GenerateUpgradesProps = {
    saveState: boolean,
    setSave: (b:boolean)=>void,
    prf_id:string
}


export function GenerateUpgrades(props:GenerateUpgradesProps) : JSX.Element {
    //default Value needs to be updated
    const [reload, setReload]:boolean | any = useState(true);
    const [data, setData]:Prd_prd[] | any = useState(undefined);
    const [lookupData, setLookupData]:Product[] | any = useState(undefined);
    const [dataGridInstance, setDataGridInstance]: any = useState(undefined);

    //get data on reload or prf change
    useEffect(() => {
        new Ajax('generate/upgrade/' + props.prf_id,{})
        .setMessages("").execute().then(result=>setData(result.data))
    }, [reload,props.prf_id]);

    //get lookup Data on component mount
    useEffect(() => {
        new Ajax('product',{}).setMessages("")
        .execute().then(result=>setLookupData(result.data))
    },[])

    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && dataGridInstance !== undefined) {
            let saveData:Prd_prd[] = [];
            let saveKeys:{prd_id:string,base_prd_id:string}[] = dataGridInstance.getSelectedRowKeys();
            saveKeys.forEach ((key:{prd_id:string,base_prd_id:string}) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].prd_id === key.prd_id && data[i].base_prd_id === key.base_prd_id) {
                        saveData.push(data[i]);
                    }
                }
            });
            new Ajax('generate/upgrade/',{
                method: "PATCH",
                body: JSON.stringify({upgrades: saveData})
            }).setMessages("Successfully saved "+ saveData.length+" upgrades!","Upgrades could not be saved!")
            .execute().finally(()=>{
                props.setSave(false)
                setReload(!reload)
            })
        }
    }, [props.saveState]); // eslint-disable-line


    //set type Lookup for Type Column
    const genTypes = [
        {type: "new"},
        {type: "delete"}
    ];

    const store = new ArrayStore({
        key: ["prd_id","base_prd_id"], 
        data: data
    })

    const ds = new DataSource({
        store: store,
        sort: "type"
    });

    //Column Renderer
    const renderTypeColumn = (data:any) => {
        switch (data.value) {
            case "new":
                return <span className = "fa fa-plus typeIcon"></span>;
            case "delete":
                return <span className = "fa fa-trash-alt typeIcon"></span>;
        }
    };  

    return (
        <>
            {data === undefined && lookupData === undefined && <Loading textMessage={<span>Loading&#8230;</span>} show={true} />}
            {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
            <div id="gridContainer" className="noOuterPadding">
                <DataGrid 
                    dataSource={ds}
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnChooser={{enabled:false, mode:'select'}} 
                    loadPanel={{enabled:false}}
                    selection = {{
                        mode:"multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always",
                        allowSelectAll: true
                    }}
                    >
                    <Editing
                        mode={'row'}
                    />
                    
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                    <Column 
                        alignment={"center"}
                        dataField = {"type"}
                        cellRender = {renderTypeColumn}
                        cssClass = "verticalAlignment col_type"
                        showInColumnChooser={false}
                        width = {"150px"}
                    >
                        <Lookup
                            dataSource = {genTypes}
                            valueExpr = {"type"}
                            displayExpr = {"type"}
                        />
                    </Column>
                    <Column 
                        dataField = {"base_prd_id"}
                        caption = {'Base Product'}
                        alignment={"left"}
                        showInColumnChooser={false}
                    >
                        <Lookup 
                            dataSource={lookupData}
                            valueExpr={'prd_id'} 
                            displayExpr={'label'}
                        />
                    </Column>
                    <Column 
                        dataField = {"prd_id"}
                        alignment={"left"}
                        caption = {'Target Product'}
                        showInColumnChooser={false}
                    >
                        <Lookup 
                            dataSource={lookupData}
                            valueExpr={'prd_id'} 
                            displayExpr={'label'}
                        />
                    </Column>
                    
                    

                </DataGrid>
            </div>
        </>
    );
}

export default GenerateUpgrades;
