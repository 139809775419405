import React from "react";

export type SelectionCounterProps = {
    instance?: any,
    data?: any[]
} 

type CounterState = {
    selected: number,
    visible: number,
    total: number
}

export function SelectionCounter(props:SelectionCounterProps) : JSX.Element {
    const [counter, setCounter] = React.useState<CounterState>({
        selected: 0,
        visible: 0,
        total: 0,
    });

    if(props.instance !== undefined){
        let newCounter = {
            selected: props.instance.getSelectedRowKeys().length,
            visible: props.instance.getVisibleRows().length,
            total: props.instance.totalCount(),
        }
        if(props.data !== undefined && props.data.length && newCounter.total < 1){
            newCounter.total = props.data.length
        }

        if(newCounter.selected !== counter.selected
            || newCounter.visible !== counter.visible
            || newCounter.total !== counter.total
        ){
            setCounter(newCounter)
        }

        return (<>
            <div><span style={{color:"#7A7A7A"}}>{counter.selected} / {counter.total} selected</span></div>
        </>)
    }

    return <></>
}