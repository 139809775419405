import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TextBox, Button, FileUploader } from "devextreme-react";
import  Ajax  from "../../../models/ajax";
import "./ImportPrices.css";
import { SaveResultReport } from "./SaveResult";
import { ComparisonList } from "./ComparisonList";
import { InlineSpinner } from "../../../components/Loading/Loading";
import { ImportData, ApiRow, ImportError, ImportSaveResult } from "./types"

type ImportPricesProps = {
  prf_id: string,
  importData?: ImportData[],
  importErrors?: ImportError[],
  apiResult?:ApiRow[]
}

export function ImportPrices(props: ImportPricesProps): JSX.Element {
  const [apiResult, setApiResult]  = useState<ApiRow[]|undefined>(props.apiResult||undefined);
  const [importData, setImportData] = useState<ImportData[] | undefined>(props.importData||undefined);
  const [importErrors, setImportErrors] = useState<ImportError[]>(props.importErrors||[]);
  const [priorities, setPriorities] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false)
  const [hideIdentical, setHideIdentical] = useState<boolean>(true)
  const [hideEmpty, setHideEmpty] = useState<boolean>(true)
  const [saveResult, setSaveResult] = useState<ImportSaveResult | undefined>(undefined);

  //API call
  const onSubmitImport = () => {
    setApiResult(undefined);
    setLoading(true)
    let body = {
      aFilter: priorities.split(","),
      aImportData: importData
    };
    new Ajax("search/" + props.prf_id, {
      method: "POST",
      body: JSON.stringify(body)
    })
    .setMessages("")
    .execute()
    .then((result: any) => {
      if(result.data !== undefined && result.data.length){
        setApiResult(result.data);
      }
      setLoading(false)
    });
  };

  const onSaveChanges = () => {
    let body = {
      data: apiResult
    };
    new Ajax("search/" + props.prf_id + "/save", {
      method: "POST",
      body: JSON.stringify(body)
    })
      .setMessages("")
      .execute()
      .then((result: any) => {
        console.log(JSON.stringify(result.data))
        setSaveResult(result.data);
      });
  };

  if (saveResult !== undefined) {
    return <SaveResultReport saveResult={saveResult} setSaveResult={setSaveResult}/>
  }

  let listContainerResult;
  if (apiResult !== undefined) {
    listContainerResult = (
      <ComparisonList
      apiResult={apiResult as ApiRow[]}
      setApiResult={setApiResult}
      />
    );
  } else {
    var text = "Select a file first"
    if(importData !== undefined){
      text = "Submit the import file to load data"
    }
    if(loading){
      text = "Loading data. Please wait."
    }
    listContainerResult = (
      <>
      {!loading && apiResult === undefined && <ImportErrorList errors={importErrors}/>}
      <Row>
        <Col sm={4}></Col>
        <Col className={"container-text"} >
          {loading && <><InlineSpinner size={"32"}/><br/></>}
          {text}
        </Col>
        <Col sm={4}></Col>
      </Row>
      </>
    );
  }

  let recordsTotal = document.getElementsByClassName("importRow").length;
  let recordsIdentical = document.getElementsByClassName("identical").length;
  let recordsEmpty = document.getElementsByClassName("empty").length;
  let recordsVisible = recordsTotal;
  recordsVisible -= hideIdentical ? recordsIdentical : 0;
  recordsVisible -= hideEmpty ? recordsEmpty : 0;

  return (
    <>
      {/* Create the Filter */}
      <Container fluid={true} className={"importDetail"}>
      <input id={"hideIdentical"} name={"hideIdentical"} type={"checkbox"} style={{display:"none"}} checked={hideIdentical} onChange={()=>setHideIdentical(!hideIdentical)}/>
      <input id={"hideEmpty"} name={"hideEmpty"} type={"checkbox"} style={{display:"none"}} checked={hideEmpty} onChange={()=>setHideEmpty(!hideEmpty)}/>
        {/* Settings Bar*/}
        <Row className={"importSettings"}>
          <Col id="uploadImport" sm={3}>
            <FileUploader
              id="fileUpload"
              selectButtonText="Import article CSV"
              allowedFileExtensions={[".csv"]}
              accept=".csv,text/csv"
              uploadMode="useForm"
              showFileList={false}
              onValueChanged={(e: any) => {
                let fr = new FileReader();
                fr.onload = (event: any) => {
                  const [importValues,importErrors] = formatFileContent(event.target.result);
                  setImportData(importValues);
                  setImportErrors(importErrors);
                };
                fr.readAsText(e.value[0], "UTF-8");
              }}
            ></FileUploader>
          </Col>
          <Col sm={4}>
            <TextBox
              id="priority-input"
              placeholder={
                'Please input prioritized keywords. Separate them using ","'
              }
              value={priorities}
              onValueChanged={(e:any) => setPriorities(e.value)}
            />
          </Col>
          <Col sm={1}></Col>
          <Col sm={1}>
            <Button
              id="submitBtn"
              className={"btn-primary"}
              disabled={importData === undefined}
              text="Submit"
              onClick={onSubmitImport}
            >
              <i className="fas fa-check"></i> &nbsp; Submit
            </Button>
          </Col>
          <Col sm={1} className={"filter-controls"}>
            <label htmlFor={"hideIdentical"}>
              <span>
                {!hideIdentical && <i className="fas fa-eye"></i>}
                {hideIdentical && <i className="fas fa-eye-slash"></i>}
                &nbsp; identical
              </span>
            </label>
            <br/>
            <label htmlFor={"hideEmpty"}>
              <span>
                {!hideEmpty && <i className="fas fa-eye"></i>}
                {hideEmpty && <i className="fas fa-eye-slash"></i>}
                &nbsp; empty
              </span>
            </label>
            
          </Col>
          <Col sm={1} className={"filter-controls visibleCounter"}>
            <span> visible: {recordsVisible} / {recordsTotal}</span>
          </Col>
        </Row>

        {/* Result list */}
        {listContainerResult}

        <Row key="saveButton">
          <Col className="no-padding" sm={11} lg={11}></Col>
          <Col className="no-padding" sm={1} lg={1}>
            <Button
              id="saveBtn"
              className={"btn-primary"}
              disabled={apiResult === undefined}
              text="Save articles"
              width={"100%"}
              onClick={onSaveChanges}
            >
              <i className="fas fa-save"></i> &nbsp; Save
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export type ImportErrorListProps = {
  errors: ImportError[]
}

export function ImportErrorList(props:ImportErrorListProps){
  //dont show if there are no errors
  if(props.errors.length === 0){return <></>}
  return (
    <>
      <Row className={"errorRow errorNotification"}>
        <Col sm={3} className={"errorHeader"}>File Validation Results</Col>
        <Col sm={9}>
          Please check the errors below.
          For formatting help, click the <span className={"fa fa-info-circle info_icon"}></span> icon on top of the page.<br/>  
          Hit <b>Submit</b> to continue the import process.
        </Col>
      </Row>
      <Row className={"errorRow errorHeader"}>
        <Col sm={3}>Row</Col>
        <Col sm={9}>Message</Col>
      </Row>
      {props.errors.map((error:ImportError)=>
        <Row key={error.row} className={"errorRow errorList"}>
          <Col sm={3}>{error.row}</Col>
          <Col sm={9}>{error.message}</Col>
        </Row>
      )}
      
    </>
  )
}


//####################
//formatting functions
//####################

export function formatFileContent(rawText: string):[ImportData[],ImportError[]] {
  // Create array with a Row for every filerow

  let rows = rawText.split(String.fromCharCode(13));
  // Remove the first element, if it is a title
  const hasHeader:boolean = rows[0].indexOf("SKU") !== -1
  if (hasHeader) {
    rows.shift();
  }
  // Remove the last element if it has no values
  if (
    rows[rows.length - 1].length < 1 ||
    rows[rows.length - 1] === "\n"
  ) {
    rows.pop();
  }
  // Create an array with a struct
  let result:ImportData[] = [];
  let skipped:ImportError[] = [];
  rows.forEach((row: string,index: number) => {
    const rowNumber = hasHeader ? index + 2 : index + 1 // +1 for 0-based index, +1 for header row
    try {
      // remove unnecessary input
      row = row.replace("\n", "");
      // Check if we still have a string left
      if (row.length && checkFormat(row)) {
        // Create the stuct to return per element
        let collection:ImportData = {
          sku: row.slice(0, row.indexOf(";")).trim(),
          name: row.slice(row.indexOf(";") + 1, row.lastIndexOf(";")).trim(),
          price: formatCurrency(row.slice(row.lastIndexOf(";") + 1, row.length))
        };
        //validate record
        let errorMessage:string[] = []
        if(!collection.sku.length){
          errorMessage.push("SKU is empty")
        }
        if(!collection.name.length){
          errorMessage.push("Name is empty")
        }
        if(isNaN(collection.price)){
          errorMessage.push('Invalid price')
        }
        if(!errorMessage.length){
          result.push(collection)
        }else{
          skipped.push({row:rowNumber,message:errorMessage.join(", ")})
        }
      }else{
        skipped.push({row:rowNumber,message:"Row has the wrong format."})
      }
    } catch (e) {
      skipped.push({
        row:rowNumber,
        message:e.message
      })
    }
  });
  return [result,skipped]
}

export function removeCurrencySymbols(price:string){
  return price.replace(/[^\d.,-]/g, '').trim()
}

export function formatCurrency(currency:string){
  var result = removeCurrencySymbols(currency)
  const decDelim = result.substr(result.length-3,1) //decimal delimiter
  let tsdDelim = decDelim === "," ? "." : "," //thousands delimiter
  if(decDelim === "," || decDelim === "."){
    //remove tsdDelim, set decDelim to "."
    result = result.replace(tsdDelim,"")
    result = result.replace(decDelim,".")
  }else{
    //remove all delims --> integer
    result = result.replace(",","")
    result = result.replace(".","")
  }
  return parseFloat(result)
}

export function checkFormat(row:any){
  let correctFormat:boolean = true
  // row has correct number of fields
  if (
    row.indexOf(";") === -1 ||
    row.lastIndexOf(";") === -1 ||
    row.indexOf(";") === row.lastIndexOf(";")
  ) {
    correctFormat = false
  }
  return correctFormat
}

export default ImportPrices;
