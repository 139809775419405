import React, { useReducer, useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { APIStore } from '../../models/store';

//custom components
import Property from './Property';
import { Relation } from "./Relation";

//css
import '../Layout/Layout.css';

//types
import { DetailAction, DetailProps, DetailState, RelationDefinition} from './types';
import DetailLayout from '../Layout/Layout';


export function Detail(props:DetailProps){
  
  //initialize variables
  const initialState:DetailState = {
    tab: 'properties',
    entry: {...props.entry},
    pageTitle: (props.entry as any)[(props.config.name_property as string)],
    relationData: {},
  };
  
  //create foreign key datasources 
  let relationDataSources:{[key:string]: DataSource} = {};
  props.config.relations.forEach((relDef:RelationDefinition)=>{
    if(relDef.pks !== undefined){
      relationDataSources[relDef.model] = new DataSource({
          store: new APIStore().create(relDef.model,relDef.pks),
          sort: "name"
      });
    }
  });


  //hooks
  const[state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (props.entry !== state.entry) {
      dispatch({type: "reload", data:props.entry});
    }
  }, [props.entry]); // eslint-disable-line

  useEffect(() => { 
    let newRelationData:{[key:string]:object}={};
    let promises:Promise<any>[] = [];
    Object.keys(relationDataSources).forEach((key:string)=>{
      const datasource = relationDataSources[key];
      const tmp = datasource.load()
        .then((data:any)=>{
          newRelationData[key] = data
        });
      promises.push(tmp);
    });
    Promise.all(promises)
      .then(()=>{
        dispatch({
          type:'relationDataLoaded',
          data: newRelationData,
        });
      });
  },[]);  // eslint-disable-line


  //prepare tab contents
  //TODO: avoid creating all elements, focus on selected tab instead
  const content:{[key:string]: any} = {
    properties: {
      component:( 
        <div className="detailbackdrop">
          <Property entry={state.entry} config={props.config} deDispatch={dispatch} fkData={props.fkData}/>
        </div>
      ),
      showSaveButton:true
    },
  };
  
  //pass down selected PK keys and values
  let selectedPkValues:string[] = [];
  props.config.primary_keys.forEach((pk:string)=> {
    selectedPkValues.push((state.entry as any)[pk])
  });

  let selectedPKs:{[key:string]:any};
  if(props.selectedPKs !== undefined){
    selectedPKs = props.selectedPKs
  }else{
    selectedPKs = {}
    props.config.primary_keys.forEach((curPK:any)=> {
      selectedPKs[curPK] = props.entry[curPK] !== undefined ? props.entry[curPK] : undefined
    })
  }
  
  props.config.relations.forEach((relDef:RelationDefinition) => {
    content[relDef.model] = {
      component: (
        <Relation 
          {...relDef}
          selectedPKs={selectedPKs} 
          data={state.relationData[relDef.model]}
        />
      ),
      showSaveButton:false  
    };
  });

  const backFunction = () => props.ovDispatch({type:'back'})
  const saveFunction = () => {
    dispatch({type:'save', props:{...props}})
    dispatch({type:'reload', data:props.entry})
  }
  const tabsActive = props.entry[props.config.primary_keys[0]] !== undefined
  const relationTabNav:JSX.Element[] = renderRelationTabNavs(props.config.relations,tabsActive);

  return(
    <DetailLayout 
        content={content} 
        pageTitle={state.pageTitle} 
        backFunction = {backFunction} 
        saveFunction = {saveFunction} 
    >
      {[
        <Tab key="properties" eventKey="properties" title="Properties"/>,
        ...relationTabNav,
        //<Tab key="history" eventKey="history" title="History"/>,
      ]}
    </DetailLayout>
  )
}

function reducer(state: DetailState, action:DetailAction|string) {
  let newState = {...state};
  newState.entry = {...state.entry};
  let key:string = '';
  let value:string = '';
  let type:string = '';

  if(typeof action === 'string'){
    type = action;
  }else if(typeof action === 'object'){
    type = action.type;
    if(typeof action.data === 'object'){
      key = Object.keys(action.data)[0];
      value = (action.data as any)[key];
    }else{
      value = action.data;
    }
  }

  switch(type){
      case 'changeTab':
        newState.tab = value;
        return newState;
      case 'valueChange':
        (newState.entry as any)[key] = value;
        return newState;
      case 'save':
        if(typeof action === 'object' && action.props !== undefined){
          action.props.ovDispatch({type:'save', data: state.entry});
          //change pageTitle only when saving
          newState.pageTitle = (state.entry as any)[(action.props.config.name_property as string)];
        }
        return newState;
      case 'relationDataLoaded':
        if(typeof(action) === 'object'){
          newState.relationData = action.data;
        }
        return newState;
      case 'reload':
        if(typeof action === 'object'){
          newState.entry = action.data;
        }
        return newState;
      default:
        return state;
  }
}

function renderRelationTabNavs(config:RelationDefinition[],active:boolean=true){
  let result:JSX.Element[] = [];
  config.forEach((relDef:RelationDefinition) => {
    let formattedTitle = relDef.lookup_model.split("_").join(" ").replace(/(\w)(\w*)/g,function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
    result.push(
      <Tab key={relDef.model} eventKey={relDef.model} title={formattedTitle} disabled={!active}/>
    );
  });
  return result;
}

export default Detail;


