import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys, setColumnProp } from "../../models/config";
import { AdminConfig } from "../../components/MasterDetail/types";

import { pricebook, art_pbk } from "../../models/config_all";
import { PricebookWorkflow } from '../PricebookWorkflow/PricebookWorkflow';
import { draftPbkStatusId } from '../../constants';

export function PriceBook(){
    let config = formatConfig(pricebook);
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'art_pbk':
                relConfig = art_pbk;
                break;
            default:
                relConfig = pricebook;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    config.columns = setColumnProp(config.columns,"pbs_id","default_value",draftPbkStatusId)

    return(
        <Overview 
            model="pricebook" 
            pk="pbk_id" 
            detailRender={{
                component:PricebookWorkflow,
                passProps: {
                    pbk_id: "state.selectedEntry.pbk_id",
                    dispatch: "dispatch"
                }
            }}
            config={config}
        />
    )
}

export default PriceBook;


