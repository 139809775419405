import React, { useEffect, useState } from 'react';
import DataSource from 'devextreme/data/data_source';
import { DataGrid } from 'devextreme-react';
import { Editing } from 'devextreme-react/data-grid';
import { DataLoader, handleErrors, APIStore } from '../../models/store';

//custom components
import Loading from '../Loading/Loading';
import { getColumnElements } from "../../models/config";

//css
import './Relation.css';

//types
import { RelationProps, RelationState } from './types';

//variable initialization
const initState:RelationState = {
    loaded:false,
    fkData: {},
}

export function Relation(props:RelationProps){
    const [state, setState] = useState(initState);

    useEffect(() => { 
        //TODO: optimization: load dependencies only when tab is active
        const dataLoader:DataLoader = new DataLoader();
        let newFkData:any = {};
        let promises:Promise<any>[] = Object.keys(props.relation_table.fks).map((key) => {
            return dataLoader.getDatafromAPI(props.relation_table.fks[key].model)
            .then(result => {
                newFkData[key]=result.data
            })
        });

        //join all loading promises
        Promise.all(promises)
        .then(()=> {
            setState({...state,fkData:newFkData,loaded:true})
        }).catch((e) => {handleErrors(e)});
        
        return () => {
            //clean up
        }
    },[props.model]); // eslint-disable-line

    let store = new APIStore()
    .setFilter(props.selectedPKs)
    .create(props.model,props.pks);
    const ds = new DataSource({
        store: store,
        sort: "name"
    });

    if(!state.loaded){
        return <Loading/>
    }else{
        return (
            <div id="gridContainer" className = "noOuterPadding">
                <DataGrid 
                    dataSource={ds}
                    onInitNewRow={(e:any) => {initNewRow(e,props)}}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnChooser={{enabled:false, mode:'select'}} 
                    //selection={{mode:'multiple'}}
                    onSelectionChanged={(e:any)=>console.log(e)}
                    loadPanel={{enabled:false}}>
                    <Editing
                        mode={'row'}
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true}
                        useIcons={true}/>
                    {
                        getColumnElements(props.relation_table.columns,state.fkData,props.relation_table.fks, props.selectedPKs)
                    }
                </DataGrid>
            </div>
        );
    }
}


function initNewRow(e:any,props:RelationProps){
    //initialize primary keys with values from ov-selection
    e.data = Object.assign(e.data, props.selectedPKs)
}

export default Relation;


