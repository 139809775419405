import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { prd_prd } from '../../models/config_all';

export function Prd_prd(props:any){
    //prd_prd.primary_keys = ["prd_id","base_prd_id"]
    let config = formatConfig(prd_prd,{},true);
    return(
        <Overview 
            model="prd_prd" 
            pk={["base_prd_id", "prd_id"]}
            config={config}
            {...props}
        />
    )
}

export default Prd_prd;


