import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { getApiUrl } from '../constants';
import { Ajax } from './ajax';
import ArrayStore from 'devextreme/data/array_store';

export const headers = {
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": "Bearer " + localStorage.getItem('JWT')
}

export class APIStore{
    model:string = '';
    baseUrl:string = getApiUrl();
    include?:string;
    filter?:object;

    setUrl(url:string){
        this.baseUrl = url || this.baseUrl;
        return this;
    }
    
    setInclude(include:string){
        this.include = include;
        return this;
    }

    setFilter(filter:object){
        this.filter = filter;
        return this;
    }

    public create(model:string, pk:string|string[]) {
        const url = this.baseUrl + model;
        let options = "";
        if(this.include !== undefined){
            options += options.length ? '&' : '?'
            options += "include="+this.include
        }
        if(this.filter !== undefined){
            options += options.length ? '&' : '?'
            options += 'filter='+btoa(JSON.stringify(this.filter))
        }

        var store = new CustomStore({
            key: pk,
            loadMode: "raw",
            load: () => {
                return new Ajax(url + options,{})
                .setMessages("").execute()
                .then(result=>result.data)
            },
            insert: (values) => {
                return new Ajax(url + options,{
                    method: "POST",
                    body: JSON.stringify(values) 
                }).setMessages("Record inserted!","Could not insert new record!").execute()
            },
            update: (key, values) => {
                let keys:string[] = [];
                let urlParams = key
                if(Array.isArray(pk)){
                    pk.forEach((pkItem) => {
                        keys.push(key[pkItem]);
                    });
                    urlParams = keys.join(',')
                }
                return new Ajax(url + '/' + urlParams,{
                    method: "PUT",
                    body: JSON.stringify(values) 
                }).setMessages("Record updated!","Could not update new record!").execute()
            },
            remove: (key) => {
                let keys:string[] = [];
                let urlParams = key
                if(Array.isArray(pk)){
                    pk.forEach((pkItem) => {
                        keys.push(key[pkItem]);
                    });
                    urlParams = keys.join(',')
                }
                return new Ajax(url + '/' + urlParams,{method: "DELETE"})
                .setMessages("Record deleted!","Could not delete new record!").execute()
            }
        });

        return store;
    }
} 

export type Filter = {
    key: string,
    operator: string,
    value: string
}

export type DataLoaderOptions={
    filter?:{[key:string]:string|number}|Filter[],
    page?:number,
    perPage?:number,
    include?:string,
    order?:string
}

export class DataLoader{

    public  async getDataSource(model:string){
        var dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: await this.getDatafromAPI(model)
            })
        });
        return dataSource;
    }

    public getDatafromAPI(model:string,options?:DataLoaderOptions){
        let url = model;
        if(options !== undefined){
            if(options.filter !== undefined){
                url += url.indexOf("?") === -1 ? '?' : '&'
                url += 'filter='+btoa(JSON.stringify(options.filter))
            }
            if(options.include !== undefined){
                url += url.indexOf("?") === -1 ? '?' : '&'
                url += 'include='+options.include
            }
            if(options.order !== undefined){
                url += url.indexOf("?") === -1 ? '?' : '&'
                url += 'order='+options.order
            }
            if(options.page !== undefined){
                url += url.indexOf("?") === -1 ? '?' : '&'
                url += 'page='+options.page
            }
            if(options.perPage !== undefined){
                url += url.indexOf("?") === -1 ? '?' : '&'
                url += 'perPage='+options.perPage
            }
        }

        return new Ajax(url,{}).setMessages("").execute()
    }
}


export function handleErrors(e:any){
    console.log(e)
    if('json' in e){
        console.log(e.json())
    }
    if(false){
        let location = window.location;
        location.assign(location.origin + '?logout=session')
    }
}
