import React, { useState, useEffect } from 'react';

import { DataGrid, CheckBox, Popup } from "devextreme-react";
import { Editing, Paging, Pager, FilterRow, Column, Lookup} from "devextreme-react/data-grid";
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import {CategoryValues, Product} from  '../../models/types';
import Loading from '../../components/Loading/Loading';

import { Ajax } from '../../models/ajax';

type GenerateProductsProps = {
    saveState: boolean,
    setSave: (b:boolean)=>void,
    prf_id:string
}

type GeneratedResult = {
    prd_id: string,
    pct_prd: CategoryValues[] | null,
    type: "update" | "new" | "delete",
    old: Product | null,
    new: Product | null
}

export function GenerateProducts(props:GenerateProductsProps) : JSX.Element {
    const [reload, setReload]:boolean | any = useState(true);
    const [data, setData]:GeneratedResult[] | any = useState(undefined);
    const [columns, setColumns]:any = useState(["Label",  "SKU", "Price" ]);
    const [popupVisible, setpopupVisible]:any = useState(false);
    const [dataGridInstance, setDataGridInstance]: any = useState(undefined);
    
    //Get Data from API and set in state
    useEffect(() => {
        new Ajax('generate/product/' + props.prf_id,{})
        .setMessages("")
        .execute().then(result=>{
            setData(result.data);
        })
    }, [reload,props.prf_id]);
    
    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && dataGridInstance !== undefined) {
            let saveData:GeneratedResult[] = [];
            let saveKeys = dataGridInstance.getSelectedRowKeys();
            let ignoreColumns:any = [];
            let dataGridColumns = dataGridInstance._controllers.columns._columns;
            let dataColumns:any = [];
            saveKeys.forEach ((key:string) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].prd_id === key) {
                        saveData.push(data[i]);
                    }
                }
            });
            dataGridColumns.forEach ((column:any) => {
                if (column.caption){
                    dataColumns.push(column.caption);
                }
            });
            dataColumns.forEach ((column:any) => {
                if (columns.indexOf(column)<0 && column!=="Type"){
                    ignoreColumns.push(column.toLowerCase());
                }
            });
            new Ajax('generate/product/',{
                method: "PATCH",
                body: JSON.stringify({aGenProducts: saveData, aColumns: ignoreColumns})
            }).setMessages("Successfully saved "+ saveData.length+" products!","Products could not be saved!")
            .execute().finally(()=>{
                props.setSave(false)
                setReload(!reload)
            })
        }
    }, [props.saveState]); // eslint-disable-line

    //set type Lookup for Type Column
    const genTypes = [
        {type: "new"},
        {type: "update"},
        {type: "delete"}
    ];
    
    //set DataStore for Datagrid
    const store = new ArrayStore({
        key: "prd_id", 
        data: data
    })

    const ds = new DataSource({
        store: store,
        sort: "type"
    });


    //Column Renderers
    const renderTypeColumn = (data:any) => {
        switch (data.value) {
            case "new":
                return <span className = "fa fa-plus typeIcon"></span>;
            case "update":
                return <span className = "fa fa-redo typeIcon"></span>;
            case "delete":
                return <span className = "fa fa-trash-alt typeIcon"></span>;
        }
    };  
    const renderLabelColumn = (data:any) => {
        switch(data.data.type) {
            case "new":
                return <span title={data.data.new.label}>{data.data.new.label}</span>;
            case "update":
                return (<><div title={data.data.new.label}>{data.data.new.label}{data.data.new.label===data.data.old.label?<></>:<><br/><span className = "oldValueFromUpdate">{data.data.old.label}</span></>}</div></>);
            case "delete":
                return <span title={data.data.old.label}>{data.data.old.label}</span>;
        }
    };
    const renderSkuColumn = (data:any) => {
        switch(data.data.type) {
            case "new":
                return data.data.new.sku;
            case "update":
        return (<><span>{data.data.new.sku}</span>{data.data.new.sku===data.data.old.sku?<></>:<><br/><span className = "oldValueFromUpdate">{data.data.old.sku}</span></>}</>);
            case "delete":
                return data.data.old.sku;
        }
    };
    const renderPriceColumn = (data:any) => {
        const priceNew = data.data.new === null ? "" : Number(data.data.new.price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        const priceOld = data.data.old === null ? "" : Number(data.data.old.price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        switch(data.data.type) {
            case "new":
                return priceNew;
            case "update":
        return (<>{priceNew}{priceNew===priceOld?<></>:<><br/><span className = "oldValueFromUpdate">{priceOld}</span></>}</>);
            case "delete":
                return priceOld;
        }
    };

    const onValueChanged = (name:string,e:any)=>{
        let newPick = columns;
        if(e.value){
            newPick.push(name);
        } else {
            newPick.splice(newPick.indexOf(name),1);
        }
        setColumns(newPick);
    }

    return (
        <>
            {data === undefined && <Loading textMessage={<span>Loading&#8230;</span>} show={true} />}
            {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
            <button className="btn-detail btn btn-primary column-selection" onClick = {()=> {setpopupVisible(!popupVisible)}}>Columns to update: {columns.join(', ')}</button>
            <Popup
                className={'popup'}
                visible={popupVisible}
                dragEnabled={false}
                onHiding={()=> {setpopupVisible(!popupVisible)}}
                closeOnOutsideClick={true}
                showTitle={true}
                title={'Columns'}
                width={300}
                height={'auto'}
            >
                <>
                    <CheckBox defaultValue={true} text={"Label"} onValueChanged={(e:any)=> onValueChanged('Label', e)}/><br/>
                    <CheckBox defaultValue={true} text={"SKU"} onValueChanged={(e:any)=> onValueChanged('SKU', e)}/><br/>
                    <CheckBox defaultValue={true} text={"Price"} onValueChanged={(e:any)=> onValueChanged('Price', e)}/>
                </>
            </Popup>
            <div id="gridContainer" className="noOuterPadding">
                <DataGrid 
                    dataSource={ds}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnChooser={{enabled:false, mode:'select'}} 
                    headerFilter = {{visible: true}}
                    loadPanel={{enabled:false}}
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    selection = {{
                        mode:"multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always",
                        deferred: false
                    }}
                    >
                    <Editing
                        mode={'row'}
                    />
                    
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                    <Column 
                        name={"type"}
                        alignment={"center"}
                        cellRender = {renderTypeColumn}
                        cssClass = "verticalAlignment"
                        dataField = {"type"}
                        showInColumnChooser={false}
                        width = {"150px"}
                    >
                        <Lookup
                            dataSource = {genTypes}
                            valueExpr = {"type"}
                            displayExpr = {"type"}
                        />
                    </Column>
                    <Column 
                        name={"label"}
                        alignment={"left"}
                        allowHeaderFiltering = {false}
                        caption={"Label"}
                        cellRender = {renderLabelColumn}
                        dataField = {"new.label"}
                        showInColumnChooser={false}
                        width = {"calc(100% - 450px)"}
                    />
                    <Column 
                        name={"sku"}
                        alignment={"left"}
                        allowHeaderFiltering = {false}
                        caption={"SKU"}
                        cellRender = {renderSkuColumn}
                        dataField = {"new.SKU"}
                        showInColumnChooser={false}
                        width = {"150px"}
                    />
                    <Column 
                        name={"price"}
                        alignment={"right"}
                        allowHeaderFiltering = {false}
                        caption={"Price"}
                        dataField = {"new.price"}
                        cellRender = {renderPriceColumn}
                        showInColumnChooser={false}
                        width = {"150px"}
                    />
                

                </DataGrid>
            </div>
        </>
    );
}

export default GenerateProducts;