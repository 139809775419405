import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys } from "../../models/config";

import { language, prd_lng } from '../../models/config_all';
import { AdminConfig } from '../../components/MasterDetail/types';

export function Language(){
    let config = formatConfig(language);
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'prd_lng':
                relConfig = prd_lng;
                break;
            default:
                relConfig = language;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    return(
        <Overview 
            model="language" 
            pk="lng_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default Language;


