import React, { useState } from 'react';

import './Main.css';
import { Switch, Route, Router } from 'react-router';
import Menu from '../Menu/Menu';

import { pricebooksRoutes, salesRoutes, RouteConfig } from './routes'
import ClientStyle from '../ClientStyle/ClientStyle';
import Header from '../Header/Header';
import { AppState } from '../../index';
import { Client } from '../../models/types';
import { defaultClient } from '../../constants';


type MainProps = {
  [key:string]: any,
  appState: AppState,
  handleLogout: (e:React.MouseEvent) => void,
}

export function Main(props:MainProps){
  const [clientContext,setClientContext] = useState<Client>(defaultClient)
  const routes = props.appState.application === "pricebooks" ? pricebooksRoutes : salesRoutes;
  
  return(
    <React.Fragment>
      <ClientStyle clientContext={props.appState.application !== "pricebooks" ? clientContext : undefined}/>
      <Menu 
        history={props.history} 
        location={props.location} 
        match={props.match} 
        app={props.appState.application}
        clientContext={clientContext}
      />
      <div id={'right-content'}>
        <Header app={props.appState.application} handleLogout={props.handleLogout}/>
        <Router history={props.history}>
            <Switch>
            {
              routes.map((route: RouteConfig) => {
                let componentProps = route.props !== undefined ? route.props : {}
                switch(route.key){
                  case 'partners':
                    componentProps.setClientContext = setClientContext
                    break;
                  case 'pricebook':
                    componentProps.clientContext = clientContext
                    break;
                }
                const componentChildren = route.children !== undefined ? route.children : null
                
                return <Route path={'/' + route.path} key={route.key} render={
                  (props) => React.createElement(route.component,{...componentProps,...props},{...componentChildren})
                }/>
              })
            }
            </Switch>
        </Router>
      </div>
    </React.Fragment>
  )
  
}

export default Main;


