import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { brand } from '../../models/config_all';

export function Brand(){
    let config = formatConfig(brand);
    return(
        <Overview 
            model="brand" 
            pk="brd_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default Brand;


