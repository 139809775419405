import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { product_type } from "../../models/config_all";

export function ProductType(){
    let config = formatConfig(product_type);
    return(
        <Overview 
            model="product_type" 
            pk="pdt_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default ProductType;


