import React, { useEffect, useState } from 'react';
import { DataGrid, SelectBox } from 'devextreme-react';
import { Paging, Pager, FilterRow, Column, Editing } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Row, Col } from 'react-bootstrap';
import { Pli_Aud, Audience, Pricelist} from '../../models/types';
import { Ajax } from '../../models/ajax';
import Loading from '../../components/Loading/Loading';
import { DataLoader } from '../../models/store';

import './PricebookWorkflow.css';

type PricebookVisibilityProps = {
    pbk_id:string
    saveState: boolean,
    setSave: (b:boolean)=>void,
    readOnly : boolean,
}

export function PricebookVisibility(props:PricebookVisibilityProps) : JSX.Element {
    const [reload, setReload] = useState<boolean>(true);
    const [loading, setLoading]= useState<boolean>(true);
    const [pricelists, setPricelists] = useState<Pricelist[]|undefined>(undefined);
    const [activePricelist, setActivePricelist]  = useState<Pricelist|undefined>(undefined);
    const [audiences, setAudiences] = useState<Audience[]>([]);
    const [pli_aud, setPli_aud] = useState<Pli_Aud[]>([]);
    const [dataGridInstance, setDataGridInstance] = useState<any>(undefined);

    const dataLoader:DataLoader = new DataLoader();

    //get Pricelists
    useEffect (() => {
        dataLoader.getDatafromAPI("pricelist", {filter: {pbk_id: props.pbk_id} ,order: "label"})
        .then(result=>{
            setPricelists(result.data)
            if(result.data.length){
                setActivePricelist(result.data[0].pli_id)
            }
            calculateLoadingState()
        })
    }, [props.pbk_id]); // eslint-disable-line

    //get audiences for client and audiences for active pricelist
    useEffect (() => {
        if (activePricelist !== undefined) {
            setLoading(true)
            new Ajax('audience?clt_id=' + localStorage.getItem("client"),{
            }).setMessages("").execute()
            .then(result=>setAudiences(result.data))
            .catch(()=>setAudiences([]))

            new Ajax('pli_aud?pli_id=' + activePricelist,{
            }).setMessages("").execute()
            .then(result=>setPli_aud(result.data))
            .catch(()=>setPli_aud([]))
            .finally(()=>calculateLoadingState())
        }
    }, [activePricelist]); // eslint-disable-line

    //set dataGrid selection, when pli_aud or audiences change
    useEffect (() => {var selection:string[] = [];
        if (pli_aud !== undefined) {
            pli_aud.forEach((pliaud:Pli_Aud) => {
                selection.push(pliaud.aud_id);
            });
        }
        if (dataGridInstance !== undefined) {
            dataGridInstance.selectRows(selection, false);
        }
    }, [pli_aud, audiences]); // eslint-disable-line

    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && dataGridInstance !== undefined) {
            let audData:any = [];
            let selectedRows = dataGridInstance.getSelectedRowKeys();
            selectedRows.forEach(
                (aud:any)=>{
                    audData.push(aud);
                });
            new Ajax('/pli_aud/save',{
                method: "POST",
                body: JSON.stringify({data: audData, pli_id: activePricelist})
            }).setMessages("Save successful!","Save failed!")
            .execute().finally(()=>props.setSave(false))
        }
    }, [props.saveState]); // eslint-disable-line

    const calculateLoadingState = () => {
        if(pricelists !== undefined){
            setLoading(false)
        }
    }

    const store = new ArrayStore({
        key: "aud_id", 
        data: audiences
    });
    const ds = new DataSource({
        store: store,
        sort: "name"
    });

    return (
        <div>
            <Loading textMessage={<span>Saving&#8230;</span>} show={props.saveState} />
            <Loading textMessage={<span>Loading&#8230;</span>} show={loading} />
            <Row className={"DropownContainer formfield"}>
                <Col xs sm = {"auto"} className={"DropdownDescription formfieldLabel"}>
                    <span className={"noMargin"}>Select price list:</span>
                </Col>
                <Col>
                    <SelectBox
                        value = {activePricelist}
                        displayExpr={'label'}
                        valueExpr={'pli_id'}
                        placeholder={'Select pricelist'}
                        dataSource={pricelists}
                        onValueChanged={(e:any) => {setActivePricelist(e.value)}}
                        height = {"30px"}
                    />
                </Col>
            </Row>
            
            <div id="gridContainer" className="noOuterPadding">
                <DataGrid 
                    dataSource={ds}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    onSelectionChanged={(e:any) => setReload(!reload)}
                    columnChooser={{enabled:false, mode:'select'}} 
                    loadPanel={{enabled:false}}
                    selection = {
                        {
                            mode:"multiple",
                            selectAllMode: "allPages",
                            showCheckBoxesMode: "always",
                            allowSelectAll: true
                        }
                    }
                    >
                    
                    <Editing
                        useIcons={true}
                        mode = {"row"}
                    />
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                    <Column 
                        dataField = {"name"}
                        caption = {"Audience Name"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        allowEditing={true}
                    />                    
                </DataGrid>
            </div>
        </div>
    );
}

export default PricebookVisibility;
