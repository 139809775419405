import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TreeView, Button } from 'devextreme-react';

import { ClientLogo } from '../ClientStyle/ClientStyle';

import './Menu.css'

import { menuStandard, menuConfig, menuSales, MenuObject } from './menuItems';
import { DataLoader, Filter, DataLoaderOptions } from '../../models/store';
import showToast from '../handleError';
import { Application, Client } from '../../models/types';

type MenuOption = {
  [key:string]: string | boolean | undefined,
  id:string,
  text:string,
  appString: string
}

type MenuProps = {
  [key:string]: any,
  history: any,
  location: any,
  match: any,
  app: Application,
  clientContext: Client,
}

export function Menu (props:MenuProps): JSX.Element{
  const [reload, setReload] = useState(-1);
  const [menuMode, setMenuMode] = useState(props.app === "pricebooks" ? "standard" : "sales" );
  const [menuItems, setMenuItems] = useState(props.app === "pricebooks" ? menuStandard : menuSales);
    const dataLoader:DataLoader = new DataLoader();
    useEffect(() => { 
      if(menuMode === "standard"){
        var promises:any[] = [];
        promises.push(dataLoader.getDatafromAPI("product_family", {order: "name",include:"brand"}));
        promises.push(dataLoader.getDatafromAPI("pricebook", {order: "name"}));

        Promise.all(promises)
        .then(result => {
          var newMenuItems:any[] = [...menuItems];
          newMenuItems = addMenuElements(newMenuItems,result[0].data,"product_family","prf_id")
          newMenuItems = addMenuElements(newMenuItems,result[1].data,"pricebook","pbk_id")
          
          setMenuItems(newMenuItems);
        })
      }
    },[menuMode]); // eslint-disable-line 

    useEffect(() => { 
      if(menuMode === "sales"){
        const clientFilter:Filter[] = props.clientContext.clt_id.length ? [{key:'clt_id',operator:"=",value:props.clientContext.clt_id}] : []

        const now = new Date().toUTCString();
        const startedFilter =     {key:'valid_from',operator:"<",value:now} 
        const endedFilter =       {key:'valid_to',  operator:"<",value:now} 
        const notEndedFilter =    {key:'valid_to',  operator:">",value:now} 

        let options:DataLoaderOptions = {
          order:"name",
          filter: clientFilter,
          page:1,
          perPage:5
        }
        
        var promises:Promise<any>[] = []

        options.filter = [...clientFilter,startedFilter,notEndedFilter]
        promises.push(dataLoader.getDatafromAPI("sales/pricebook", options))

        options.filter = [...clientFilter,endedFilter]
        promises.push(dataLoader.getDatafromAPI("sales/pricebook", options))

        Promise.all(promises)
        .then(result=>{
          var newMenuItems:any[] = [...menuItems];
          newMenuItems = addMenuElements(newMenuItems,result[0].data,"pricebook","pbk_id",props.app)
          newMenuItems = addMenuElements(newMenuItems,result[1].data,"archive","pbk_id",props.app,"pricebook")
          setMenuItems(newMenuItems);
        })
      }
    },[props.clientContext]); // eslint-disable-line

    useEffect(() => { 
      if (reload !== -1) {
        showToast("success", "Menu reloaded");
      }
    },[menuMode, reload]); // eslint-disable-line
    

    const toggleAdvancedMode = () => {
      if(menuMode === "standard"){
        setMenuItems(menuConfig)
        setMenuMode("config")
      }else{
        setMenuItems(menuStandard)
        setMenuMode("standard")
      }
    }

    const findMenuItemById = (id:string) => {
      let result = [-1,-1]
      menuItems.forEach((level1item,i) => {
        if(level1item.items !== undefined){
          level1item.items.forEach((item,j)=>{
            if(item.id === id){
              result = [i,j]
            }
          })
        }
      })
      return result
    }
    
    const addMenuElements = (menuItems:MenuObject[],newItems:any[],objectType:string,pk:string,app="pricebooks",path:string=objectType) => {
      //takes a menu object, the new items (from ajax request) and adds links to the menuItems object
      let [x,y] = findMenuItemById(objectType);
      var result:any[] = [...menuItems];
      if(objectType === "product_family"){
        result[x].items[y].items = buildProductFamilies(newItems,pk,path);
      }else{
        if(y !== -1){
          result[x].items[y].items = [];
          newItems.forEach((item:any) => {
            const newMenuObject = {
              id: `${y}_${item[pk]}`,
              text: app === "sales" ? item.label : item.name,
              level: 2,
              type: "directRecordLink",
              appString: app === "sales" ? "sales/" : "",
              objectType: objectType,
              path: path !== undefined ? path : objectType
            }
            if(result[x].items[y].items !== undefined){
              result[x].items[y].items.push(newMenuObject)
            }
          });
        }
      }
      return result;
    }

    const buildProductFamilies = (newItems:any[],pk:string,path:string) => {
      // sort items in object keys
      let oSort:any = {};
      newItems.forEach((item:any) => {

        // replace key words from brand because they are contained in the category now
        let replacedName = item.name;
        let toReplace = item.brand.name.split(" ");
        toReplace.forEach((word:string) => {
          replacedName = replacedName.replace(word,"");
        });
        // if its empty we replaced to much and should fall back
        replacedName = replacedName.trim().length ? replacedName.trim() : item.label; 

        const newPrfMenuObject = {
          id: item[pk],
          text: replacedName,
          level: 3,
          type: "directRecordLink",
          appString: "",
          objectType: "product_family",
          path: path !== undefined ? path : "product_family"
        }
        if(oSort[item.brd_id] === undefined){
          // create new brand if it doesnt exist
          oSort[item.brd_id] = {
            id: item.brand.brd_id,
            text: item.brand.name,
            level: 2,
            type: "directRecordLink",
            appString: "",
            objectType: "brand",
            path: path !== "brand",
            items:[]
          }
        }
        oSort[item.brd_id].items.push(newPrfMenuObject)
      })

      // convert items into array
      let result:any = [];
      for(let key in oSort){
        result.push(oSort[key]);
      }

      return result;
    }

    const modeButtonText = menuMode === "standard" ? "Advanced Mode" : "Workflow Mode";
    
    return (
      <>
        <input type="checkbox" id="menuSwitch"  name="menuSwitch"></input>
        <div id={'left-content'}>
          <ClientLogo clientContext={props.clientContext}/>
          <TreeView 
            items={menuItems} 
            itemComponent={MenuItem}
            height={"75%"}
            activeStateEnabled={true}
            focusStateEnabled={true}
            className = {"menuTreeView"}
          />
          {props.app === "pricebooks" &&
            <>
              <Button className={"menuButton"} onClick={() => {setReload(reload === 1 ? 0 : 1)}}>
                <span className={'fa fa-sync-alt menuButtonIcon'}></span>
                Refresh
              </Button>
              <Button className={"menuButton"} onClick={() => toggleAdvancedMode()}>
                <span className={'fa fa-exchange-alt menuButtonIcon'}></span>
                {modeButtonText}
              </Button>
            </>
          }
        </div>
      </>
    );
}

function MenuItem(data:{data:MenuOption,index:number}) {
  const item = data.data
  let appString = item.appString === undefined ? "" : item.appString
  if (item.type === "empty") {
    return <span className = {"menuSpacer"}></span>
  }
  var icon = <></>;
  if (item.icon !== undefined) {
    icon = (
        <span className = {"menuIcon " + item.icon as string}></span>
    );
  }
  
  var className:string = "menuLevels menuLevel"+item.level;
  var text:any = item.text;
  var title:string = "";

  if (item.text.length > 25) {
    title = item.text;
    text = item.text.substring(0, 12) + "..." + item.text.substring(item.text.length - 10);
  }

  let link_id = item.id
  if(item.id.split("_").length > 1){
    // take last part of id as actual link
    link_id = item.id.split("_")[item.id.split.length - 1]
  } 

  if(item.type === "category"){
    return <h2 title={title} className={"plain-category-item " + className}>{text}{icon}</h2>
  }else if(item.type === "directRecordLink"){
    let path = item.path !== undefined ? item.path : item.objectType
    return <Link to={'/' + appString + path + '/' + link_id}><h2 title={title} className={className}>{text}{icon}</h2></Link>
  }else{
    return <Link to={'/' + appString + link_id}><h2 className={className} title={title}>{text}{icon}</h2></Link>
  }
}

export default Menu;