import React, { useEffect, useState } from 'react';
import { DataGrid, ActionSheet } from 'devextreme-react';
import { Item } from 'devextreme-react/action-sheet';
import { Paging, Pager, FilterRow, Column } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import { DataLoader, Filter } from '../../models/store';
import { Pricebook, Client } from '../../models/types';
import ArrayStore from 'devextreme/data/array_store';

const dataLoader:DataLoader = new DataLoader();

type PricebooksProps = {
    [key:string]:any,
    clientContext: Client
}

export function Pricebooks (props:PricebooksProps): JSX.Element{
    const [pricebooks,setPricebooks] = useState<Pricebook[]>([])
    const [actionsheetTarget,setActionsheetTarget] = useState(undefined)
    const [activeElement,setActiveElement] = useState<string>("")

    useEffect(() => { 
        let options:{order:string,filter:Filter[]} = {
            order:"name",
            filter:[]
        }
        if(props.clientContext.clt_id.length > 0){
            options.filter.push({
                key:'clt_id', 
                operator:"=", 
                value:props.clientContext.clt_id
            })
        } 
        dataLoader.getDatafromAPI("sales/pricebook", options)
        .then((result)=>{
            setPricebooks([...pricebooks,...result.data])
        })
    },[]); // eslint-disable-line

    //set template options
    const formattedTitle = "Price Books"
    const pageOptions = {
        hideHeader: false,
        hideDetailPage: false
    }

    const store = new ArrayStore({
        key: "pbk_id",
        data: pricebooks,
    });

    const ds = new DataSource({
         store: store,
         sort: "name",
    });

    // icon to activate actionsheet 
    const OvActionSheetIcon = (item:any) => {
        const target = "actionSheetTarget_" + item.rowIndex;
        return (
            <i 
                id={target}
                onClick={() => {
                    setActionsheetTarget(item.cellElement)
                    setActiveElement(item.data.pbk_id)
                }}
                className={"fa fa-ellipsis-h fa-lg editColumnItem"}
                aria-hidden={"true"}
            ></i>
        )
    }

    return(
        //React.Fragment groups contents together without having to use another dom element (div etc.)
        <React.Fragment>
            <h4 className="overviewTitle">{formattedTitle}</h4>
            <div id="gridContainer" className={pageOptions.hideHeader?"noOuterPadding":""}>
                <DataGrid dataSource={ds} 
                    //other parameters are standard widget configuration, options could be included in props.config if necessary
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    loadPanel={{enabled:false}}
                    >
                    
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>
                    
                    <Column  
                        visible = {1}
                        dataType={'string'}
                        dataField={'label'}
                        caption={'Name'}
                        allowEditing={false}
                        showInColumnChooser={true}
                        width = {'40%'}
                        key = {'label'}>
                    </Column>
                    <Column  
                        visible = {1}
                        dataType={'date'}
                        dataField={'valid_from'}
                        caption={'Valid From'}
                        allowEditing={false}
                        showInColumnChooser={true}
                        width = {'25%'}
                        key = {'valid_from'}>
                    </Column>                    
                    <Column  
                        visible = {1}
                        dataType={'date'}
                        dataField={'valid_to'}
                        caption={'Valid To'}
                        allowEditing={false}
                        showInColumnChooser={true}
                        width = {'25%'}
                        key = {'valid_to'}>
                    </Column>

                    <Column 
                        cellRender={!pageOptions.hideDetailPage ? OvActionSheetIcon : undefined}
                        alignment={"center"}
                        showInColumnChooser={false}
                        width = {'10%'}
                    />

                </DataGrid>
                {/* Use single actionsheet for all rows */}
                { !pageOptions.hideDetailPage &&
                    <ActionSheet 
                        target={actionsheetTarget}
                        onOptionChanged={(e:any) => {
                            if(e.name === "visible"){
                                setActionsheetTarget(undefined)
                            }
                        }}
                        visible={actionsheetTarget !== undefined}
                        className={"actionSheet"}
                        usePopover={true}
                        showTitle={false}
                        // submit action and row data
                        onItemClick={(e:any) => {
                            switch(e.itemData.type){
                                case 'view':
                                    if(activeElement.length){
                                        props.history.push('/sales/pricebook/' + activeElement)
                                    }
                                    break;
                                default:
                            }
                        }}
                        showCancelButton={true}
                    >
                        <Item text={"View"} icon={"fas fa-eye"} type={"view"} />
                        {/* <Item text={"open in SmartSeller"} icon={"fas fa-tag"} type={"smartSeller"} /> */}
                    </ActionSheet>
                }
            </div>
        </React.Fragment>
    )

}

export default Pricebooks;