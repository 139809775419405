import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { RelationPrd, RelationPrf } from "./Relations"

type ListBoxesSelectorProps = {
    dispatch: (type:any)=>void,
    data: RelationPrf[],
    type: "base"|"target"
}

type ProductListBoxProps = {
    type: "base"|"target",
    selected: boolean,
    data: RelationPrf[]
}

type ProductSelectBoxListProps = {
    prf:RelationPrf, 
    selected:boolean, 
    type:"base"|"target"
}

export function ListBoxesSelector (props:ListBoxesSelectorProps): JSX.Element{
    return(
        <Row>
            <Col sm={5}>
                <ProductListBox type={props.type} selected={false} data={props.data}/>
            </Col>
            <Col className={"relation-button-column"} sm={2}>
                <Button variant={"primary"} onClick={()=>
                    props.dispatch({
                        type:"select",
                        data:{
                            elem: document.getElementById(props.type+"-products-unselected") as HTMLSelectElement, 
                            type: props.type,
                            selectState: true
                        }
                    })
                }>
                    &gt;&gt;
                </Button>
                <br/>
                <br/>
                <Button variant={"primary"} onClick={()=>
                    props.dispatch({
                        type:"select",
                        data:{
                            elem: document.getElementById(props.type+"-products-selected") as HTMLSelectElement, 
                            type: props.type, 
                            selectState: false
                        }
                    })
                }>
                    &lt;&lt;
                </Button>
            </Col>
            <Col sm={5}>
                <ProductListBox type={props.type} selected={true} data={props.data}/>
            </Col>
        </Row>
    )
}

export function ProductListBox (props:ProductListBoxProps): JSX.Element{
    return(
        <select id={props.type+"-products-"+(props.selected ? "selected" : "unselected")} className={"relation-selection-boxes"} size={15} multiple={true}>  
            {
                props.data.map((prf:RelationPrf) => 
                    <ProductSelectBoxList key={props.type+"-products-"+(props.selected ? "selected" : "unselected")+"-"+prf.prf_id} prf={prf} selected={props.selected} type={props.type}/>
                )  
            }
        </select> 
    )
};

export function ProductSelectBoxList (props:ProductSelectBoxListProps): JSX.Element{
    return (<React.Fragment key={props.prf.prf_id}>
        {((props.prf.selected > 0) === props.selected || (props.prf.selected < props.prf.products.length) === !props.selected) &&
            <option 
                key={props.prf.prf_id+"_"+props.type+"_"+(props.selected?"selected":"unselected")} 
                value={props.prf.prf_id}
                className={"relation-select-family"} 
                data-prf={props.prf.prf_id}
                data-prd={null}
                data-type={props.prf.type}>
                    {props.prf.name} ({props.selected ? props.prf.selected : props.prf.products.length - props.prf.selected})
            </option>
        }
        {props.prf.products.map((prd:RelationPrd)=>{
            if(props.selected === prd.selected){
                return (
                    <option 
                        key={props.prf.prf_id+"_"+prd.prd_id+"_"+props.type+"_"+(props.selected?"selected":"unselected")} 
                        id={props.prf.prf_id+"_"+prd.prd_id+"_"+props.type+"_"+(props.selected?"selected":"unselected")} 
                        value={prd.prd_id} 
                        className={"relation-select-product"} 
                        data-prf={props.prf.prf_id}
                        data-prd={prd.prd_id}
                        data-type={props.prf.type}
                    >
                        {prd.name}
                    </option>
                )
            }
        })}
    </React.Fragment>)
};

export default ProductSelectBoxList;
