import { Application } from "./models/types";

export const redirect = (page:"login"|"main" ,app:Application) => {
    let location = window.location;
    let target = "/"
    if (app === "sales"){
        target += "sales"
    }
    if(page === "main"){
        if (app === "sales"){
            target += "/"
        }
        target += "main"
    }
    if(location.pathname !== target){
        location.assign(target)
    }
}