import React, { useEffect, useState } from "react"
import Loading from "../../../components/Loading/Loading";
import { Pricelist } from "../../../models/types_generated";
import { ArticleDetail } from "./ArticleDetail"
import { SmartSellerAction } from "./SmartSellerMain";

type RelatedArticlesOptions = {
    navigation: any,
    navigate: (a:string,b:string,c:any,d:any) => void,
    pli_id: string,
    art_id?: string,
    data?: any,
    dispatch: (action:SmartSellerAction) => void,
    navigateTo:(a:number) => void,
    pricelists?: Pricelist[]
}

export function RelatedArticles (props: RelatedArticlesOptions): JSX.Element {
    const lastNavElement = [...props.navigation].pop();
    const [data,setData] = useState<any>(undefined);
    
    useEffect (() => {
        if(lastNavElement !== undefined){
            setData(lastNavElement.data);

            // asynchronous loading per step
            /*
                // if(lastNavElement.data.length === 0 && Object.keys(lastNavElement.data).length === 0){
                //     new Ajax("sales/smartseller/"+props.art_id+"/options/related",{method:"post",body:JSON.stringify({navigation:lastNavElement.navParams})}).setMessages("").execute()
                //     .then(result=>{
                //         setData(result.data);
                //     })
                // }else{
                //     setData(lastNavElement.data);
                // }
            */
        }
    }, [props.navigation]); // eslint-disable-line

    //takes any navigation element, displays their headers, navigates and passes down its child data to the next level on click
    const renderListElement = ((entry:any,index:number)=>{
        let listProps = {
            key: "key_" + index,
            text: entry.name,
            count: entry.count !== undefined ? <div className="numberCircle"><span>{entry.count}</span></div> : "",
            params: {
                type: entry.type,
                category: entry.name,
                data: entry.content !== undefined ? entry.content : [],
                navParams: entry.navParams || {}
            }
        }

        // articles are displayed differently
        if(entry.type === "article" && entry.id === props.art_id){
            return  <li 
                className="unclickableBox"
                key={listProps.key} 
                onClick={()=>false && props.navigate(listProps.params.type,listProps.params.category,listProps.params.data,listProps.params.navParams)}
            >
            <span className="articleName">{listProps.text} (current selection)</span></li>
        }

        // if its not an article and has children, (like upgrades), iterate over data's children
        if(entry.children !== undefined && entry.children.children !== undefined){
            listProps.params.data = entry.children ;
        }

        return (
            <li 
                className={listProps.count==="0"?"unclickableBox":"clickableBox"}
                key={listProps.key} 
                onClick={()=>{
                props.navigate(
                    listProps.params.type,
                    listProps.params.category,
                    listProps.params.data,
                    listProps.params.navParams
                )}}
            >
                <div><span className="articleName">{listProps.text}</span>{listProps.count}<br className="clear-all" /></div>
                {entry.description?<><hr/>
                <p dangerouslySetInnerHTML={{__html: entry.description }}/></>:<></>}
            </li>
        )
    })

    if(props.navigation.length && data !== undefined){
        if(lastNavElement.type === "article"){
            //when last stage reached, render the article detail (like the one on the left side)
            if(data.art_id !== undefined){
                return (
                    <div className="article">
                        <h4>New Article</h4>
                        <hr className="clear-all"/>
                        
                        <ArticleDetail 
                            art_id={data.art_id}  
                            pli_id={props.pli_id}
                            dispatch={props.dispatch}
                            navigateTo={props.navigateTo}
                            pricelists={props.pricelists}
                        />
                    </div>
                )
            }
        }else{
            //if there is navigation, render a list view with the options available
            let listItems:any[] = [];
            if(data.articles !== undefined){
                listItems = listItems.concat(data.articles);
            }
            if(data.children !== undefined){
                listItems = listItems.concat(data.children);
            }

            return (
                <>
                {props.navigation.length
                    ? <h4>{props.navigation[props.navigation.length-1].category}</h4>
                    : <h4 className="defaultHeadline">Start</h4>
                }
                <ul className="articleBox">
                    <p dangerouslySetInnerHTML={{__html: data.description }}/>

                    {listItems.map((entry:any,index:number)=>renderListElement(entry,index))}
                </ul>
                </>
            )
        } 

    }else if(props.data !== undefined){
        //default: Start Page: Navigation array is empty
        return (
            <>
            {props.navigation.length?<h4>{props.navigation[props.navigation.length-1].category}</h4>:<h4 className="defaultHeadline">Start</h4>}
            <ul className="articleBox">
                {props.data.upgrades !== undefined && props.data.upgrades.count !== 0 && 
                    <li 
                        className={props.data.upgrades.count<=0?"unclickableBox":"clickableBox"} 
                        onClick={()=>
                            props.data.upgrades.count > 0 
                            && props.navigate("upgrade","Upgrades",props.data.upgrades,{})
                        }
                    >
                        <div>
                            <span className="articleName">Upgrades</span>
                                <div className="numberCircle"> 
                                    <span> 
                                        { props.data.upgrades.count < 0 && 
                                            <Loading type={"inline"} size={"10"} color={"white"} marginTop= {"-10px"}/>
                                        }
                                        {props.data.upgrades.count >= 0 && 
                                            props.data.upgrades.count
                                        } 
                                    </span> 
                                </div>
                            <br className="clear-all" />
                        </div>
                        {/* <hr/> */}
                        {/* <p>{props.data.upgrades.description}</p> */}
                    </li>
                }
    
                {props.data.maintenance_types === undefined &&
                    <LoadingItemsPlaceholder label="Maintenance Options"/>
                }
                {props.data.maintenance_types !== undefined && props.data.maintenance_types.map((mtt:any)=>{
                        if(mtt.count > 0){
                            return (
                                <li className={mtt.count===0?"unclickableBox":"clickableBox"} onClick={()=>mtt.count > 0 && props.navigate(mtt.type,mtt.name,mtt,{})} key={mtt.id}>
                                    <div><span className="articleName">{mtt.name}</span><div className="numberCircle"><span>{mtt.count}</span></div><br className="clear-all" /></div>
                                    {mtt.description?<><hr/>
                                        <p dangerouslySetInnerHTML={{__html: mtt.description}}/></>
                                        :<></>
                                    }
                                </li>
                            )
                        }else{
                            return(<></>)
                        }
                    })
                }

                {/* todo: type für items*/}
                {props.data.related === undefined &&
                    <LoadingItemsPlaceholder label="Related Articles"/>
                }   
                {props.data.related !== undefined && props.data.related.map((item:any)=>{
                    return (
                        <li 
                            className={item.count===0?"unclickableBox":"clickableBox"} 
                            onClick={()=>item.count > 0 && props.navigate(item.type,item.name,item,item.navParams)}
                            key={"related_" + item.id}
                        >
                            <div><span className="articleName">{item.name}</span>
                                <div className="numberCircle"><span>{item.count}</span></div>
                                <br className="clear-all" />
                            </div>
                            {item.description?<><hr/>
                            <p dangerouslySetInnerHTML={{__html: item.description}}/></>
                            :<></>}
                        </li>
                    )
                })} 

            </ul>
            </>
        )
    }

    //default: displayed when loading or no elements found
    if( props.data !== undefined 
        && props.data.upgrades !== undefined && props.data.upgrades.count === 0 
        && props.data.maintenance_types !== undefined && props.data.maintenance_types.length === 0 
        && props.data.related !== undefined && props.data.related.length === 0
    ){
        return <span>No Data available!</span>
    }else{
        return <span>Loading articles. Please Wait...</span>;
    }
}

export function LoadingItemsPlaceholder (props: {"label": string}): JSX.Element {
    return (
        <li className={"unclickableBox"}>
            <div>
                <span className="articleName">{props.label || "..."}</span>
                    <div className="numberCircle"> 
                        <span> 
                            <Loading type={"inline"} size={"10"} color={"white"} marginTop= {"-10px"}/>
                        </span> 
                    </div>
                <br className="clear-all" />
            </div>
        </li>
    )
}

export default RelatedArticles;