import React, { useEffect, useState } from 'react';
import { DataGrid, SelectBox } from 'devextreme-react';
import { Paging, Pager, FilterRow, Column, Editing } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Row, Col } from 'react-bootstrap';
import { Article, Pricelist, Application } from '../../models/types';
import { Ajax } from '../../models/ajax';
import Loading from '../../components/Loading/Loading';
import { DataLoader } from '../../models/store';

import './PricebookWorkflow.css';
import { BulkEdit } from '../../components/BulkEdit/BulkEdit';

type PricelistArticleProps = {
    pbk_id:string
    saveState: boolean,
    setSave: (b:boolean)=>void,
    readOnly : boolean,
    app?: Application
}

export function PricelistArticles(props:PricelistArticleProps) : JSX.Element {
    const [reload,setReload]:boolean | any = useState(true);
    const [loading, setLoading]:Article[] | any = useState(true);
    const [pricelists, setPricelists]:Pricelist[] | any = useState(undefined);
    const [activePricelist, setActivePricelist]:string | any = useState(undefined);
    const [articles, setArticles]:Article[] | any = useState([]);
    const [dataGridInstance, setDataGridInstance]: any = useState(undefined);

    const dataLoader:DataLoader = new DataLoader();
    const salesExtension = props.app === "sales" ? "sales/" : ""

    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && dataGridInstance !== undefined) {
            new Ajax(salesExtension + 'pricelist/' + activePricelist + '/articles',{
                method: "PATCH",
                body: JSON.stringify({articles: articles, pli_id: activePricelist})
            }).setMessages("Successfully updated pricelist discounts!","Could not edit pricebook articles!")
            .execute().finally(()=>{
                props.setSave(false);
            })
        }
    }, [props.saveState]); // eslint-disable-line

    //get Pricelists
    useEffect (() => {
        dataLoader.getDatafromAPI(salesExtension + "pricelist", {
            filter: {pbk_id: props.pbk_id},
            order: "label"
        })
        .then(result=>{
            setPricelists(result.data)
            if(result.data.length){
                setActivePricelist(result.data[0].pli_id)
            }
            calculateLoadingState()
        })
    }, [props.pbk_id]); // eslint-disable-line

    //get art_pbk by pricebook with prices from pricelist
    useEffect (() => {
        if (activePricelist !== undefined) {
            setLoading(true)
            new Ajax(salesExtension + 'pricelist/' + activePricelist + '/articles?includeFactor=true',{
            }).setMessages("").execute()
            .then(result=>setArticles(result.data))
            .catch(()=>setArticles([]))
            .finally(()=>calculateLoadingState())
        }
    }, [activePricelist]); //eslint-disable-line

    const calculateLoadingState = () => {
        if(articles !== undefined || pricelists !== undefined){
            setLoading(false)
        }
    }

    const store = new ArrayStore({
        key: "art_id", 
        data: articles
    });
    const ds = new DataSource({
        store: store,
        sort: "label"
    });


    //get base pricelist name if exists
    let basePliElem = <></>
    let base_pricelist_name = ""
    if(pricelists !== undefined){
        //find active pricelist
        pricelists.forEach((pli:Pricelist)=>{
            if(pli.pli_id === activePricelist){
                //find active pricelist's base pli
                pricelists.forEach((basePli:Pricelist)=>{
                    if(pli.base_pli_id === basePli.pli_id){
                        base_pricelist_name = basePli.label
                        basePliElem = (
                            <Col xs sm = {3}>
                                Base Price List: {base_pricelist_name}
                            </Col>
                        )
                    }
                })
            }
        })
    }

    return (
        <div>
            <Loading textMessage={<span>Saving&#8230;</span>} show={props.saveState} />
            <Loading textMessage={<span>Loading&#8230;</span>} show={loading} />
            <Row className={"DropownContainer formfield"}>
                <Col xs sm = {"auto"} className={"DropdownDescription formfieldLabel"}>
                    <span className={"noMargin"}>Select price list:</span>
                </Col>
                <Col>
                    <SelectBox
                        value = {activePricelist}
                        displayExpr={'label'}
                        valueExpr={'pli_id'}
                        placeholder={'Select pricelist'}
                        dataSource={pricelists}
                        onValueChanged={(e:any) => {setActivePricelist(e.value)}}
                        height = {"30px"}
                    />
                </Col>
                {basePliElem}
            </Row>
            {!props.readOnly && <BulkEdit
                dataGridInstance = {dataGridInstance}
                data = {articles}
                setData = {setArticles}
                editColumn = {"discount"}
                dsKey = {"art_id"}
                options = {{
                    categories:["calculate","reset","set"],
                    fields:['discount'],
                    mode:'percent',
                    resetSource:'factor',
                    operators: ["+","-"]
                }}
            />}
            <div id="gridContainer" className="noOuterPadding">
                <DataGrid 
                    dataSource={ds}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    onSelectionChanged={(e:any) => setReload(!reload)}
                    columnChooser={{enabled:false, mode:'select'}} 
                    loadPanel={{enabled:false}}
                    selection = {props.app !== "sales" ?
                        {
                            mode:"multiple",
                            selectAllMode: "allPages",
                            showCheckBoxesMode: "always",
                            allowSelectAll: true
                        }:{}
                    }
                    >
                    {props.app !== "sales" && 
                        <Editing
                            useIcons={true}
                            mode = {"cell"}
                            allowUpdating= {true}
                            allowDeleting= {false} 
                        />
                    }
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                    <Column 
                        dataField = {"label"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        allowEditing={false}
                        width={"60%"}
                    />
                    <Column 
                        caption={"SKU"}
                        dataField = {"sku"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        allowEditing={false}
                        width={"13%"}
                    />
                    <Column 
                        dataField = {"price"}
                        alignment={"right"}
                        showInColumnChooser={false}
						format={"#,##0.00"}
                        allowEditing={false}
                        width={"10%"}
                    />
                    <Column 
                        dataField = {"discount"}
                        caption = {"Discount"}
                        alignment={"right"}
                        showInColumnChooser={false}
                        format={"#,##0.0%"}
                        allowEditing={!props.readOnly}
                        width={"7%"}
                    />
                    <Column 
                        calculateCellValue={(e:any)=> calculatePrice(e.price,e.discount) }
                        caption={"Final price"}
                        alignment={"right"}
                        showInColumnChooser={false}
						format={"#,##0.00"}
                        allowEditing={false}
                        width={"10%"}
                    />
                    
                </DataGrid>
            </div>
        </div>
    );
}

const calculatePrice = (price:number, discount:number) => {
    let result = price
    if(typeof discount === 'number'){
        result = price*(1-discount)
    }
    return result
}

export default PricelistArticles;
