import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys } from "../../models/config";
import { AdminConfig } from '../../components/MasterDetail/types';

import { article, art_pbk } from '../../models/config_all';

export function Article(props:any){
    const defaultVisibleColumns = ["active","label","price","sku"]
    const visibleColumns = props.visibleColumns !== undefined ? props.visibleColumns : defaultVisibleColumns

    let config = formatConfig(article,{visibleColumns: visibleColumns});
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'art_pbk':
                relConfig = art_pbk;
                break;
            default:
                relConfig = article;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    return(
        <Overview 
            model="article" 
            pk="art_id" 
            config={config}
            {...props}
        />
    )
}

export default Article;


