import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { product_family } from '../../models/config_all';
import { ProductWorkflow } from '../ProductWorkflow/ProductWorkflow';

export function ProductFamily(props:any){
    let config = formatConfig(product_family);
    
    return(
        <Overview 
            model="product_family" 
            pk="prf_id" 
            detailRender={{
                component:ProductWorkflow,
                passProps: {
                    prf_id: "state.selectedEntry.prf_id",
                    dispatch: "dispatch"
                }
            }}
            config={config}
            {...props}
        />
    )
}

export default ProductFamily;


