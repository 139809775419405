import React from 'react';


export function Start (): JSX.Element{
    return (
        <></>
    )

}

export default Start;