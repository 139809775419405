import axios from 'axios';
import showToast from '../components/handleError';
import { getApiUrl } from "../constants";
import { redirect } from '../globalFunctions';

export const getAxiosInstance = () => {
        
    let axiosInstance = axios.create({
        baseURL: getApiUrl(),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + localStorage.getItem('JWT'),
        },
        withCredentials: true,
    });


    // Add a request interceptor
    axiosInstance.interceptors.request.use(function (config) {
        console.log(config)
        if(config.params?.filter !== undefined){
            config.params.filter =btoa(config.params.filter);
        }
        // Do something before request is sent
        return config;
    }, function (error) {
        // Do something with request error
        // return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(function (response) {
        // 2xx statuses will come through here

        return response;
    }, function(error){
        // any non 2xx statuses come through here
        console.log(error)
        console.log(error.response)

        if (error.response.data.messages.error.length) {
            if (error.response.data.msgType === "warning") {
                showToast("warning", error.response.data.message);
            }
            else {
                showToast("error", error.response.data.message.length ? error.response.data.message : error.response.data.messages.error);
            }
        }

        if (error.response.status === 401) {
            localStorage.setItem('loggedIn', "false");
            localStorage.setItem('msg', "Session");
            const app = window.location.pathname.indexOf("sales") !== -1 ? "sales" : "pricebooks";
            redirect("login", app);
        }

        Promise.reject(error);
        return error;
    });

    return axiosInstance;

};



export default getAxiosInstance;

