import React, { useState, useEffect } from 'react';
import { DataLoader } from '../../models/store';
import { Client } from '../../models/types';
import { ClientLogo } from '../../components/ClientStyle/ClientStyle';
import './style.css'
import { Container, Row, Col } from 'react-bootstrap';
import { defaultClient } from '../../constants';

type PartnerProps = {
    setClientContext: (client:Client) => void
}

export function Partners (props: PartnerProps): JSX.Element{
    const dataLoader = new DataLoader()
    
    const [partners,setPartners] = useState<Client[]>([])
    const [assignedPartners,setAssignedPartners] = useState<Client[]>([])

    useEffect(()=>{
        dataLoader.getDatafromAPI("sales/partner", {})
        .then((result)=>{
            setPartners([...partners,...result.data.otherPartners])
            setAssignedPartners([...assignedPartners,...result.data.myPartners])
        })
    },[]) // eslint-disable-line

    const yourPartners = assignedPartners.map((partner:Client) => {return renderPartner(partner,props.setClientContext)})
    yourPartners.unshift(renderPartner(defaultClient,props.setClientContext))
    const otherPartners = partners.map((partner:Client) => {return renderPartner(partner,undefined)})
    
    return (
        <div id="detailContainer">
            <Container fluid={true}>
                <Row>
                    <Col>
                        <h1>Your Partners</h1>
                        <hr/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={"partner-list"}>
                            {yourPartners}
                        </div>
                    </Col>
                </Row>
                <Row className="spacer" style={{height:"6rem"}}>
                    <Col className="no-padding">
                        &nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Other Partners</h1>
                        <hr/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={"partner-list"}>
                            {otherPartners}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

function renderPartner(client:Client, callbackFunc?:(client:Client)=>void) {
    let logoWithCaption = (<>
        <ClientLogo clientContext={client}/>
        <br/>
        <span>{client.clt_id.length ? client.name : "All Partners"}</span>
    </>)

    if(callbackFunc !== undefined){
        logoWithCaption = (
            <a href="sales/partners" onClick={(e) => {
                e.preventDefault();
                callbackFunc(client)
            }}>
                {logoWithCaption}
            </a>
        )
    }

    return (
        <div className={"partner-element"} key={client.name}>
            {logoWithCaption}
        </div>
    )
}

export default Partners;