import React, { useState, useEffect } from "react";
import DetailLayout from "../../components/Layout/Layout";
import { Tab } from "react-bootstrap";
import GenerateProducts from "./GenerateProducts";
import ProductCategory from "./ProductCategory";
import GenerateUpgrades from "./GenerateUpgrades";
import GenerateArticles from "./GenerateArticles";
import { ProductFamily } from "../../models/types";

import "./Generation.css";
import Article from "../Overviews/Article";
import Maintenance from "../Overviews/Maintenance";
import Prd_prd from "../Overviews/Prd_prd";
import Products from "../Overviews/Products";
import ProductFamilyProperty from "./ProductFamilyProperty";
import {
  productCategoryInfo,
  prfPropertyInfo,
  generateProductsInfo,
  productInfo,
  generateUpgradesInfo,
  upgradeInfo,
  generateArticlesInfo,
  importPricesInfo
} from "../../models/InfoTexts";
import { Ajax } from "../../models/ajax";
import ImportPrices from "./importPrices/ImportPrices";

const familyTemplate = {
  base_price: null,
  brd_id: "",
  clt_id: localStorage.getItem("client"),
  description: null,
  label: "",
  name: "",
  prf_id: "",
  prt_id: "",
  sku_label: null
};

export function ProductWorkflow(props: any): JSX.Element {
  const [family, setFamily]: ProductFamily | any = useState(familyTemplate);
  const [saveButton, setSaveButton]: boolean | any = useState(false);

  const denyWorkflow = family.prf_id === undefined || family.prf_id === "";

  let prf_id: string = "";
  if (props.match !== undefined && props.match.params.id !== undefined)
    prf_id = props.match.params.id;
  else if (family.prf_id !== "") prf_id = family.prf_id;
  else if (props.prf_id !== undefined) prf_id = props.prf_id;

  //API call
  useEffect(() => {
    if (prf_id !== "") {
      new Ajax("product_family/" + prf_id, {})
        .setMessages("")
        .execute()
        .then(result => setFamily(result.data));
    } else {
      setFamily(familyTemplate);
    }
  }, [prf_id]);

  const backFunction = props.dispatch === undefined ? undefined : () => { props.dispatch({ type: "back" }); };
  const doSave: (b: boolean) => void = (b: boolean) => { setSaveButton(b); };
  
  const content = {
      productProperty: {
          shortHelpText: prfPropertyInfo.short,
          fullHelpText: prfPropertyInfo.long, 
          component: (
              <ProductFamilyProperty
                  setPrf={setFamily}
                  prf={family} 
                  setSave = {doSave} 
                  saveState = {saveButton}
                  isNew = {denyWorkflow}
              />
          )
      },
      productCategory: {
          shortHelpText: productCategoryInfo.short,
          fullHelpText: productCategoryInfo.long, 
          component: (
              <ProductCategory 
                  prf_id = {prf_id}
                  setSave = {doSave} 
                  saveState = {saveButton}
              />
          )
      },
      generateProducts: {
          shortHelpText: generateProductsInfo.short,
          fullHelpText: generateProductsInfo.long, 
          component: (
              <GenerateProducts  
                  setSave = {doSave} 
                  saveState = {saveButton} 
                  prf_id = {prf_id as string}
              />
          )
      },
      products: {
          shortHelpText: productInfo.short,
          fullHelpText: productInfo.long, 
          component: (
              <Products 
                  filter={["prf_id", "=", prf_id ]} 
                  selectedPKs={{prf_id:prf_id}}
                  pageOptions={{hideHeader:true,hideDetailPage:true}}
                  editorOptions={{mode:"form",allowAdding:true,allowUpdating:true,allowDeleting:true}} 
              />
          )
      },
      generateUpgrades: {
          shortHelpText: generateUpgradesInfo.short,
          fullHelpText: generateUpgradesInfo.long, 
          component: (
              <GenerateUpgrades 
                  setSave = {doSave} 
                  saveState = {saveButton} 
                  prf_id = {prf_id as string}
              />
          )
      },
      upgrades: {
          shortHelpText: upgradeInfo.short,
          fullHelpText: upgradeInfo.long, 
          component: (
              <Prd_prd // eslint-disable-line
                  filter={["product.prf_id", "=", prf_id ]} 
                  include={'product'} 
                  pageOptions={{hideHeader:true,hideDetailPage:true}}
                  editorOptions={{mode:"form",allowAdding:true,allowUpdating:true,allowDeleting:true}} 
              />
          )
      },
      maintenances: {
          // shortHelpText: "Edit upgrades",
          // fullHelpText: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum", 
          component: (
              <Maintenance 
                  filter={["prf_id", "=", prf_id ]} 
                  selectedPKs={{prf_id:prf_id}}
                  pageOptions={{hideHeader:true,hideDetailPage:true}}
                  editorOptions={{mode:"form",allowAdding:true,allowUpdating:true,allowDeleting:true}} 
              />
          )
      },
      generateArticles: {
          shortHelpText: generateArticlesInfo.short,
          fullHelpText: generateArticlesInfo.long, 
          component: (
              <GenerateArticles 
                  setSave = {doSave} 
                  saveState = {saveButton} 
                  prf_id = {prf_id as string}
              />
          )
      },
      importPrices: {
        shortHelpText: importPricesInfo.short,
        fullHelpText: importPricesInfo.long, 
        component: (
            <ImportPrices 
                prf_id = {prf_id as string}
            />
        )
      },
      articles: {
          // shortHelpText: "Edit articles",
          // fullHelpText: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum", 
          component: (
              <Article 
                  filter={["prf_id", "=", prf_id ]} 
                  selectedPKs={{prf_id:prf_id}}
                  pageOptions={{hideHeader:true,hideDetailPage:true}}
                  editorOptions={{mode:"form",allowAdding:true,allowUpdating:true,allowDeleting:true}}
                  bulkEdit={{
                      editColumn: "price",
                      dsKey: "art_id",
                  }} 
              />
          )
      }
  };

  return (
    <DetailLayout
      content={content}
      pageTitle={family.name}
      backFunction={backFunction}
      setSave={doSave}
      saveState={saveButton}
      isWorkflow={true}
    >
      <Tab eventKey="productProperty" title="Properties" />
      <Tab
        eventKey="productCategory"
        title="Categories"
        disabled={denyWorkflow}
      />
      <Tab
        eventKey="generateProducts"
        title={<span className="fa fa-cog"></span>}
        disabled={denyWorkflow}
      />
      <Tab eventKey="products" title="Products" disabled={denyWorkflow} />
      <Tab
        eventKey="generateUpgrades"
        title={<span className="fa fa-cog"></span>}
        disabled={denyWorkflow}
      />
      <Tab eventKey="upgrades" title="Upgrades" disabled={denyWorkflow} />
      <Tab
        eventKey="maintenances"
        title="Maintenances"
        disabled={denyWorkflow}
      />
      <Tab
        eventKey="generateArticles"
        title={<span className="fa fa-cog"></span>}
        disabled={denyWorkflow}
      />
      <Tab
        eventKey="importPrices"
        title="Import Prices"
        disabled={denyWorkflow}
      />
      <Tab eventKey="articles" title="Articles" disabled={denyWorkflow} />
    </DetailLayout>
  );
}

export default ProductWorkflow;