import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SelectBox, NumberBox, Button } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import showToast from '../handleError';

import './BulkEdit.css';
import { SelectionCounter } from '../SelectionCounter/SelectionCounter';

export type BulkEditOptions = {
    fields?: string[],
    exceptOperators?: string[],
    operators?: string[],
    categories?: string[],
    mode?: "total"|"percent",
    editColumn?: string,
    resetSource?: string
}

type BulkEditProps = {
    dataGridInstance:any,
    data?: any[],
    setData?: (value:any) => void,
    editColumn: string,
    dsKey: string,
    options?: BulkEditOptions
}

export type Operator = {
    id: string,
    text: string,
    category: string,
    field?: string
}

export function BulkEdit(props:BulkEditProps) : JSX.Element {

    const initialOperator = {id:undefined, text:undefined, category:undefined};

    const [usedOperator, setUsedOperator]: object | any = useState(initialOperator);
    const [calcValue, setCalcValue]: number | undefined | any = useState(undefined);
    const [placeholder, setPlaceholder]: string | any = useState(undefined);
    const [numIsValid, setNumIsValid]: boolean | any = useState(true);

    let operators:Operator[] = selectOperators({...props.options,editColumn:props.editColumn});

    const optionsDataSource = new DataSource({
        store: operators,
        key: 'id',
        group: 'category'
    });

    const datagridEdit = props.setData === undefined
    const columnName = props.editColumn !== undefined ? props.editColumn : "price" 

    const applyPreview = () => {
        // get selected rows
        let saveKeys:string[] = props.dataGridInstance.getSelectedRowKeys();

        //get Values from datagrid
        let values = props.dataGridInstance.getSelectedRowsData().map((row:any)=>{
            let updatedRow:any = {...row}
            const rowIndex = props.dataGridInstance.getRowIndexByKey(row[props.dsKey])
            updatedRow[columnName] = props.dataGridInstance.cellValue(rowIndex,columnName)
            return updatedRow
        })

        let newData = calcNewValues(
            values, 
            saveKeys, 
            usedOperator, 
            calcValue, 
            {pk:props.dsKey,editColumn:props.editColumn,...props.options}
        );

        //set new cell values for data grid
        newData.forEach((row:any)=>{
            const changeIndex = props.dataGridInstance.getRowIndexByKey(row[props.dsKey])
            props.dataGridInstance.cellValue(changeIndex,columnName,row[columnName])
        })
    }

    const applyChanges = async () => {
        const datasource = props.dataGridInstance.getDataSource();

        if(props.dataGridInstance !== undefined
            && usedOperator !== undefined
            && props.dataGridInstance.getSelectedRowKeys().length > 0
        ){
                
            let saveKeys:string[] = props.dataGridInstance.getSelectedRowKeys();
            let values:any[] = [];
            if(props.data !== undefined){
                values = [...props.data];
            }else{
                await datasource.store().load().done((allData:any[])=>{
                    values = allData;
                })
                values = values.filter((item:any)=>{
                    return saveKeys.indexOf(item[datasource.key()]) > -1
                })
            }
            
            let newData = calcNewValues(
                values, 
                saveKeys, 
                usedOperator, 
                calcValue, 
                {pk:props.dsKey,editColumn:props.editColumn,...props.options}
            );
            
            if(props.setData !== undefined){
                props.setData(newData);
            }else{
                const store = datasource.store();
                newData.forEach((item:any) =>{
                    store.update(item[store.key()],item);
                });
                datasource.reload();
            }

            setCalcValue(undefined); 
            showToast("success", saveKeys.length + " article(s) affected.");
            discardChanges();
        }
    }

    //functions to use with datagridEdit mode
    const discardChanges = () => {
        props.dataGridInstance.cancelEditData()
    }


    let numberFieldEnabled:boolean = true;
    if(usedOperator.category === 'reset' || usedOperator.category === undefined){
        numberFieldEnabled = false
    }

    const updateOperator = (operator_id:string) => {
        let operator:Operator = {id: operator_id, text:operator_id, category:''}
        operators.forEach((item:Operator)=> {
            if(item['id'] === operator_id){
                operator = item;
            }
        })

        switch (operator.id) {
            case '+':
            case '-':
                setPlaceholder("100");
                break;
            case '×':
            case '÷':
                setPlaceholder("0.2");
                break;
            case '+ %':
            case '- %':
                setPlaceholder("20");
                break;
            case 'round ≈':
            case 'round up ≃':
            case 'round off ≂':
                setPlaceholder("0.1");
                break;
            default:
                setPlaceholder("20");
        }
        setCalcValue(undefined);
        setNumIsValid(true);
        setUsedOperator(operator);
    }

    var validateCalcValue = (value:string) => {
        var ok:boolean = true;
        var replacement:number = 0;
        if (usedOperator.id.includes("round")) {
            var onlyOneOne:boolean = false;
            for (let char of value) {
                if ((char !== '0' && char !== '1' && char !== '.') || (onlyOneOne && char === '1')) {
                    ok = false;
                    replacement = 0.01;
                    break;
                } else if (char === '1') {
                    onlyOneOne = true;
                }
            }
        } else {
            for (let char of value) {
                if (!(char >= '0' && char <= '9') && char !== '.') {
                    ok = false;
                    replacement = 1;
                    break;
                }
            }
        }
        if (ok) {
            setCalcValue(Number(value));
            setNumIsValid(true);
        } else {
            setCalcValue(replacement);
            setNumIsValid(false);
        }
    }

    // only show when dataGridInstance is set
    if(props.dataGridInstance === undefined){
        return <></>
    }
    
    return (
        <Row className = {"detailbackdrop noMargin formfield"}>
            <Col xs sm = {2}>
                <span className="formfieldLabel">Choose Operator</span>
                <SelectBox
                    value = {usedOperator.id}
                    placeholder={'Select operator'}
                    dataSource={optionsDataSource}
                    grouped={true}
                    displayExpr={'text'}
                    valueExpr={'id'}
                    onValueChanged={(e:any) => updateOperator(e.value)}
                    height = {"30px"}
                />

            </Col>

            <Col xs sm = {2} style={!numberFieldEnabled ? {display:'none'} : {}}>
                <span className="formfieldLabel">Value</span>
                    <NumberBox
                        onInput = {(e:any) => validateCalcValue(e.event.target.value)}
                        height = {"30px"}
                        placeholder = {placeholder}
                        value = {calcValue}
                        isValid = {numIsValid}
                    />
            </Col>
            
            <Col xs sm = {2}>
                <div style={{height: 27}}></div>
                {datagridEdit &&
                    <Button
                    className = {"btn-primary"}
                    text={"Preview"}
                    onClick={() => applyPreview()}
                    height = {"30px"}
                    width = {"120px"}
                    disabled = {usedOperator.category !== 'reset' && (usedOperator.id === undefined || calcValue === undefined)}>
                        <span className = 'fa fa-check'></span> Preview
                    </Button>
                }

                {!datagridEdit &&
                    <Button
                    className = {"btn-primary"}
                    text={"Apply"}
                    onClick={applyChanges}
                    height = {"30px"}
                    width = {"120px"}
                    disabled = {usedOperator.category !== 'reset' && (usedOperator.id === undefined || calcValue === undefined)}>
                        <span className = 'fa fa-check'></span> Apply
                    </Button>
                }
            </Col>

             {/* Column Padding if Number field is missing */}
             <Col xs sm = {2} style={numberFieldEnabled ? {display:'none'} : {}}></Col>

            <Col xs sm = {1}>
                <div style={{height: 33}}></div>
                <SelectionCounter 
                    instance={props.dataGridInstance} 
                    data={props.data||[]}
                />
            </Col>

            <Col xs sm = {4}></Col>
            { !datagridEdit && <>
                {/* <Col xs sm = {1}>
                    <span className="formfieldLabel">&nbsp;</span>
                    <Button
                        className = {"btn-primary"}
                        text={"Discard"}
                        onClick={props.dataGridInstance.getDataSource().reload()}
                        height = {"30px"}
                        width = {"120px"}
                        disabled = {false}>
                        <span className = 'fa fa-ban'></span> Undo
                    </Button>
                </Col> */}
                </>
            }
            { datagridEdit && <>
                <Col xs sm = {1}>
                    <span className="formfieldLabel">&nbsp;</span>
                    <Button
                        className = {"btn-primary"}
                        text={"Save"}
                        onClick={applyChanges}
                        height = {"30px"}
                        width = {"120px"}
                        disabled = {false}>
                        <span className = 'fa fa-save'></span> Save
                    </Button>
                </Col>
                </>    
            }
        </Row>
    );
}

export const calcNewValues = (values:any, changes:any, operator:Operator, calcValue?:number, options?:any) => {
    let calcOptions = {
        pk: options.pk || "art_id",
        editColumn: options.editColumn || "price"
    }

    let result:any[] = [];

    values.forEach((origEntry:any) => {
        const entry = {...origEntry}
        changes.forEach((key:string) => {
            if(entry[calcOptions.pk] === key) {
                if(operator.category === 'reset'){
                    if(operator.field !== undefined && entry[operator.field] !== undefined){ //column to reset exists
                        entry[operator.field] = entry[options.resetSource][operator.field]
                    }else if(operator.id === 'reset_all_fields'){
                        Object.keys(entry).forEach((field:string)=>{
                            if(entry[field] !== undefined && entry.article[field] !== undefined){
                                entry[field] = entry.article[field];
                            }
                        })
                    }
                }else if(operator.category === 'set'){
                    if(calcValue !== undefined){
                        let changeValue = calcValue;
                        if(options.mode === 'percent' && typeof calcValue === 'number'){
                            changeValue = calcValue/100 
                        }
                        entry[calcOptions.editColumn] = changeValue
                    }
                }else{
                    if(calcValue !== undefined){
                        let changeValue;
                        switch (operator.id) {
                            case '+':
                                changeValue = options.mode === 'percent' ? calcValue/100 : calcValue
                                entry[calcOptions.editColumn] += changeValue;
                                break;
                            case '-':
                                changeValue = options.mode === 'percent' ? calcValue/100 : calcValue
                                entry[calcOptions.editColumn] -= changeValue;
                                break;
                            case '×':
                                entry[calcOptions.editColumn] *= calcValue;
                            break;
                            case '÷':
                                entry[calcOptions.editColumn] /= calcValue;
                            break;
                            case '+ %':
                                entry[calcOptions.editColumn] = entry[calcOptions.editColumn] + (entry[calcOptions.editColumn] * (calcValue/100));
                            break;
                            case '- %':
                                entry[calcOptions.editColumn] = entry[calcOptions.editColumn] - (entry[calcOptions.editColumn] * (calcValue/100));
                            break;
                            case 'round ≈':
                                entry[calcOptions.editColumn] = Math.round((entry[calcOptions.editColumn] / calcValue))*calcValue
                            break;
                            case 'round up ≃':
                                if (entry[calcOptions.editColumn] % calcValue !== 0) {
                                    entry[calcOptions.editColumn] = entry[calcOptions.editColumn] + (calcValue - (entry[calcOptions.editColumn] % calcValue));
                                }
                            break;
                            case 'round off ≂':
                                entry[calcOptions.editColumn] = entry[calcOptions.editColumn] - (entry[calcOptions.editColumn] % calcValue);
                            break;
                        }
                    }
                }
                if(options.mode === 'percent'){
                    entry[calcOptions.editColumn] = Math.round(entry[calcOptions.editColumn]*1000)/1000;
                }else{
                    entry[calcOptions.editColumn] = Math.round(entry[calcOptions.editColumn]*100)/100;
                }
            }
        });
        result.push(entry);
    });
    return result;
}


export const allOperators:Operator[] = [
    {id:'+',text:'+',category:"calculate"},
    {id:'-',text:'-',category:"calculate"},
    {id:'×',text:'×',category:"calculate"},
    {id:'÷',text:'÷',category:"calculate"},
    {id:'+ %',text:'+ %',category:"calculate"},
    {id:'- %',text:'- %',category:"calculate"},
    {id:'round ≈',text:'round ≈',category:"calculate"},
    {id:'round up ≃',text:'round up ≃',category:"calculate"},
    {id:'round off ≂',text:'round off ≂',category:"calculate"},
];

export const selectOperators = (options?:BulkEditOptions) => {
    //set default values
    let operatorOptions = options || {categories: ["calculate"]}

    let operators:Operator[] = [];
    const pushOperator = (item:Operator) => {
        //push if there is no exception for this field
        if( !(operatorOptions.exceptOperators !== undefined && 
            operatorOptions.exceptOperators.includes(item.id)) ){
            operators.push(item)
        }
    }

    allOperators.forEach(item=>{
        //if array of operators is provided, only use these
        if(operatorOptions.operators !== undefined){
            if(operatorOptions.operators.includes(item.id)){
                pushOperator(item);
            }
        }else{
            //if array of operator categories is provided, only use these
            if(operatorOptions.categories !== undefined){
                if(operatorOptions.categories.includes(item.category)){
                    pushOperator(item);
                }
            }else{
                //if nothing is provided, use all operators
                pushOperator(item);
            }
        }
    })

    //set operator
    if(operatorOptions.categories === undefined 
        || operatorOptions.categories.includes('set')){
        if(operatorOptions.editColumn !== undefined){
            const setOperator:Operator = {
                id: 'set_'+operatorOptions.editColumn,
                text: 'set '+operatorOptions.editColumn,
                category: 'set'
            }
            pushOperator(setOperator)
        }
    }
    
    //add reset operators
    if(operatorOptions.fields !== undefined && operatorOptions.resetSource !== undefined){
        if(operatorOptions.categories === undefined || operatorOptions.categories.includes('reset')){
            //reset operators
            operatorOptions.fields.forEach(item=>{
                const resetOperator:Operator = {
                    id: 'reset_'+item,
                    text: 'reset ' + item,
                    category: 'reset',
                    field: item
                }
                pushOperator(resetOperator)
            })
            
            if(operatorOptions.fields.length > 1){
                pushOperator({
                    id:'reset_all_fields',
                    text:'reset all fields',
                    category:"reset"
                })
            }
        }
    }
    

    return operators;
}

export default BulkEdit;