import React, { useState, useEffect } from 'react';
import { Button } from 'devextreme-react';
import { Ajax } from '../../models/ajax';

import CsvDownloader from 'react-csv-downloader';
// import ReactExport from 'react-data-export';
import Loading from '../../components/Loading/Loading';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

type ExportPricebookProps = {
    active?:boolean,
    format?:"Excel"|"CSV",
    fileName:string,
    pbk_id:string
}

type ExcelPricebookProps = ExportPricebookProps & {
    valid_from:Date,
    valid_to:Date
}

type XlsData = {
    meta:{[key:string]:any};
    content:{[key:string]:any},
}

export function ExcelPricebook(props:ExcelPricebookProps){
    const format = props.format||"Excel"
    const initialState:XlsData = {meta:{},content:{}}

    const [xlsData, setXlsData] = useState(initialState); 
    const [btnDisabled, setBtnDisabled] = useState(true); 

    useEffect(()=>{
        new Ajax('pricebook/'+props.pbk_id+'/export/'+format+'/',{
            method: "POST",
            body: JSON.stringify({name: fileName})
        }).setMessages("").execute()
        .then(result => 
            {
                if(result.status === 200){
                    setXlsData(result.data);
                    setBtnDisabled(false);
                }
            }
        )
    },[props.pbk_id]) // eslint-disable-line

    let fileName:string = props.fileName;
    if(fileName === ""){
        if(xlsData.meta.pricebook !== undefined){
            fileName = xlsData.meta.pricebook
        }else{
            fileName = "Download"
        }
    }

    const downloadButton = (
        <Button
            className="btn-detail btn btn-primary btn-share"
            text={"Download Excel"}
            disabled={btnDisabled}
        />
    )

    return (
        <>
            { btnDisabled && 
                <span>
                    exporting...
                    <Loading type="inline" textMessage="" size="16"/>
                </span>
            }
            {/* { !btnDisabled && 
                <ExcelFile element={downloadButton} filename={fileName}>
                    <ExcelSheet dataSet={xlsData.content} name="Prices"/>
                </ExcelFile>
            } */}
        </>
    );
}

    
    

export function CsvPricebook(props:ExportPricebookProps & {pli_id:string}){

    const [csvData, setCsvData] = useState<{datas:any[],columns:any[]}>({columns:[],datas:[]});
    const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

    useEffect(()=>{
        setBtnDisabled(true);
        new Ajax('pricebook/'+props.pbk_id+'/export/csv/',{
            method: "POST",
            body: JSON.stringify({name: props.fileName, pli_id: props.pli_id})
        },true).setMessages("").execute()
        .then(result => {
                if(result.status === 200){
                    setCsvData(result.data);
                    setBtnDisabled(false);
                }
            }
        )
    },[props.pbk_id,props.pli_id]) // eslint-disable-line


    return (
        <>
            { !btnDisabled && 
                <CsvDownloader filename = {props.fileName} {...csvData} separator="&#9;" noHeader={true} disabled={btnDisabled}>
                    <Button
                    className="btn-detail btn btn-primary btn-share"
                    text={"Download CSV"}
                    // onClick={e=>setDownload(true)}
                    disabled={btnDisabled}
                    />
                </CsvDownloader>
            }
            { btnDisabled && 
                <span>
                    exporting...
                    <Loading type="inline" textMessage="" size="16"/>
                </span>
            }
        </>
    )
    
} 

export default ExcelPricebook;