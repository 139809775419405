import React, { useState } from "react";
import { Column, Export } from 'devextreme-react/data-grid';
import { Container, Row, Col } from "react-bootstrap";
import PieChart, { CommonSeriesSettings, Series, Label, Connector, Size, Tooltip } from 'devextreme-react/pie-chart';
import { Button, DataGrid } from "devextreme-react";
import "./ImportPrices.css";
import DevExpress from "devextreme/bundles/dx.all";
import { Legend, Title } from "devextreme-react/chart";
import { ImportSaveResult } from "./types";

type SaveResultReportProps = {
  saveResult:ImportSaveResult,
  setSaveResult:(a?:ImportSaveResult) => void
}

export function SaveResultReport(props:SaveResultReportProps): JSX.Element{
  const [dataLabel,setDataLabel] = useState<string>("Skipped")
  const [gridData,setGridData] = useState<any[]>(props.saveResult.articles.skipped)

  const ds = [{
    status: "Error",
    count: props.saveResult.stats.error,
    item: props.saveResult.articles.error
  },
  {
    status: "Updated",
    count: props.saveResult.stats.updated,
    item: props.saveResult.articles.updated
  },
  {
    status: "Skipped",
    count: props.saveResult.stats.skipped,
    item: props.saveResult.articles.skipped
  },
  {
    status: "Identical",
    count: props.saveResult.stats.identical,
    item: props.saveResult.articles.identical
  },
  {
    status: "No Match",
    count: props.saveResult.stats.noMatch,
    item: props.saveResult.articles.noMatch
  }
  ]

  return (
    <Container fluid={true}>
      <Row>
        <Col sm={3}>
          <Button 
              text={"Back"}
              onClick={() => {
                props.setSaveResult(undefined)
              }}
          >
            <i className="fas fa-chevron-left"></i>
            &nbsp; Back
          </Button>
        </Col>
        <Col sm={6}>
          <PieChart
            dataSource={ds}
            type={"donut"}
            onPointClick={(e:any)=>{
              setDataLabel(e.target.data.status)
              setGridData(e.target.data.item)
            }}
            onLegendClick={(e:any)=>{
              setDataLabel(e.points[0].data.status)
              setGridData(e.points[0].data.item)
            }}
            customizePoint={(pointInfo:any) => {
              let result:DevExpress.viz.dxChartSeriesTypesCommonSeriesPoint = {
                border:undefined, 
                color: undefined, 
                hoverStyle:undefined, 
                selectionStyle:undefined
              }
              switch(pointInfo.index){
                case 0:
                  result.color = "red"
                  break;
                case 1:
                  result.color = "#00bc00"
                  break;
                case 2:
                  result.color = "orange"
                  break;
                case 3:
                  result.color = "green"
                  break;
                case 4:
                  result.color = "grey"
                  break;
              }
              return result
            }}
          >
            <CommonSeriesSettings
              hoverMode={"none"}
            />
            <Title
              text={"Import Summary"}
              horizontalAlignment={"center"}
              subtitle={{text:"select a segment to show articles"}}
            />
            <Legend 
              customizeText={(e:any)=>e.pointName+' (' + ds[e.pointIndex].count + ')'}
              verticalAlignment={"top"}
              horizontalAlignment={"right"}
              orientation={"vertical"}
            >
            </Legend>
            <Series argumentField={"status"} valueField={"count"}>
              <Label
                visible={true}
                position={"column"}
                customizeText={(arg:any) => { return `${arg.argumentText}: ${arg.valueText} (${arg.percentText})`; }}
              >
              <Connector
                color={"black"}
                visible={true}
                width={0.5}
              />
              </Label>
            </Series>
            <Tooltip
              enabled={true}
              customizeTooltip={(arg:any) => `${arg.item}`}
            />
            <Size
              height={300}
              width={600}
            />
          </PieChart>
        </Col>
        <Col sm={3}></Col>
      </Row>

      <Row>
        <Col sm={12}>
          <h1>{dataLabel}</h1>
          <DataGrid dataSource={gridData}>
            <Export enabled={true} fileName={dataLabel} />
            <Column
              caption={"SKU"}
              alignment={"left"}
              dataField={"sSKU"}
              width={"12%"}
            />
            <Column
              caption={"Label"}
              alignment={"left"}
              dataField={"sSearchWord"}
              width={"80%"}
            />
            <Column
              caption={"Price"}
              alignment={"right"}
              dataField={"nPrice"}
              format={"#,##0.00"}
              width={"8%"}
            />
          </DataGrid>
        </Col>
      </Row>
    </Container>
  )
}

export default SaveResultReport;
