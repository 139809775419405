import { showToast } from "../components/handleError";
import { getApiUrl } from "../constants";
import { redirect } from "../globalFunctions";

export class Ajax{
    url:string;
    fetchOptions:{
        method:string;
        headers:Headers | string[][] | Record<string, string> | undefined;
        body?:any
    };
    debug:boolean;
    messages:{
        success:string,
        error:string,
    };
    result:any;

    constructor(url:string,options:any,debug?:boolean){
        if(url.indexOf("://") === -1){
            //relative url
            this.url = getApiUrl() + url;
        }else{
            //absolute url
            this.url = url;
        }
        this.fetchOptions = {
            ...options,
            method: options.method || "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + localStorage.getItem('JWT'),
                ...options.headers,
            },
            credentials:"include",
        }
        this.messages = {
            success:"Success!",
            error:"Error!",
        }
        this.debug = debug || false
        return this;
    }

    setDebug = (debug:boolean) => {
        this.debug = debug
    }

    setMessages = (success:string,error?:string) => {
        this.messages.success = success
        this.messages.error = error !== undefined ? error : this.messages.error
        return this;
    }

    public execute() {
        const promise = fetch(this.url, this.fetchOptions)
            .then(response => {
                if (this.debug) {
                    console.log(response);
                }
                return response.json();
            })
            .then((result) => {
                if (this.debug) {
                    console.log(result);
                }
                if (result.status < 200 || result.status > 299) {
                    throw (result);
                }
                else {
                    this.result = result;
                    if (this.messages.success.length) {
                        showToast("success", this.messages.success);
                    }
                }
                return result;
            })
            .catch((e) => {
                if (this.debug) {
                    console.log(e);
                    if (e.detail !== undefined) {
                        console.log(e.detail);
                    }
                }
                if (this.messages.error.length) {
                    console.log(e);
                    if (e.status === 401) {
                        localStorage.setItem('loggedIn', "false");
                        localStorage.setItem('msg', "Session");
                        const app = window.location.pathname.indexOf("sales") !== -1 ? "sales" : "pricebooks";
                        redirect("login", app);
                    }
                    else if (e.msgType === "warning") {
                        showToast("warning", e.message);
                    }
                    else {
                        showToast("error", e.message.length ? e.message : this.messages.error);
                    }
                }
                return e;
            });
        return promise;
    };

    
}

export default Ajax;
