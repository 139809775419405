import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys, deleteRelation } from "../../models/config";
import { AdminConfig } from "../../components/MasterDetail/types";

import { unit, uni_uni } from "../../models/config_all";

export function Unit(){
    let config = formatConfig(unit);
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'uni_uni':
                relConfig = uni_uni;
                break;
            default:
                relConfig = unit;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    //delete unwanted RLs
    config.relations = deleteRelation(config.relations,"uni_uni")

    return(
        <Overview 
            model="unit" 
            pk="uni_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default Unit;


