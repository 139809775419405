import React, { useReducer } from 'react';
import { useEffect } from "react";
import Property from '../../components/MasterDetail/Property';
import { formatConfig } from '../../models/config';
import { product_family } from '../../models/config_all';
import Loading from '../../components/Loading/Loading';
import { ProductFamily } from '../../models/types';
import { APIStore } from '../../models/store';
import { showToast } from "../../components/handleError";

type ProductFamilyPropertyProps = {
    setPrf: (prf: ProductFamily) => void,
    prf: ProductFamily,
    setSave: (b:boolean) => void,
    saveState: boolean,
    isNew: boolean
}

export function ProductFamilyProperty(props:ProductFamilyPropertyProps) : JSX.Element {
    const[state, dispatch] = useReducer(reducer, undefined);

    useEffect (() => dispatch({type:'init',data:props.prf}),[props.prf])
    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && props.setSave !== undefined) {
            const store = new APIStore().create("product_family", "prf_id");
            let promise:Promise<any>
            if (props.prf.prf_id !== undefined && props.prf.prf_id !== null && props.prf.prf_id !== "") {
                promise = store.update(props.prf.prf_id, state);
            } else {
                promise = store.insert(state);
            }
            promise.then((result) => {
                if (result.status >= 200 && result.status <= 299) {
                    props.setPrf(result.data);
                    if (props.isNew) {
                        showToast("info", "Refresh menu to show the new product family.")
                    }
                }
                props.setSave(false);
            }).catch((e)=>props.setSave(false));
        }
    }, [props.saveState]); // eslint-disable-line

    const config = formatConfig(product_family);

    return (
        <div className="detailbackdrop">
            {state === undefined && <Loading textMessage={<span>Loading&#8230;</span>} show={true} />}
            {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
            {state !== undefined && <Property 
                entry={state} 
                config={config} 
                deDispatch={dispatch}
            />}
        </div>
    )
}

function reducer(state: any, action:any) {
    let newState = {...state};
    switch(action.type){
        case 'init':
            newState = action.data;
            break;
        case 'valueChange':
            newState = Object.assign(newState,action.data)
            break;
        default:
            return state;
    }
    return newState
}

export default ProductFamilyProperty;