import React, { useState, useReducer, useEffect } from 'react';
import { DataLoader, DataLoaderOptions, handleErrors } from '../../models/store';
import Loading from '../../components/Loading/Loading';
import 'react-toastify/dist/ReactToastify.css';
import { Ajax } from '../../models/ajax';

import './ProductCategory.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const dynamicSort = (property:string,sortorder:"asc"|"desc") => {
    let sortFactor = sortorder === 'desc' ? -1 : 1;
    return function (a:any,b:any) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortFactor;
    }
}

type ProductCategoryState = {
    data:any[], // ToDo: Korrekte Typen verwenden
    addCategory:boolean,
    addValue:number,
    prf_id?:string
} 

const reducer = (state:any, action:any) => {
    let newState:ProductCategoryState = {...state};
    switch(action.type){
        case 'init':
            let stateData = [...action.data];
            //sort by level desc, value.position desc
            // ToDo: API sortieren lassen
            stateData.sort(dynamicSort("level","desc"));
            stateData.forEach((item) => {
                item.pct_values.sort(dynamicSort("position","desc"))
            });
            newState.data = stateData;

            break;
        case 'move':
            // move Item up or down in list
            let direction = action.moveUp ? -1 : 1;
            if(action.valIndex!=null && action.catIndex!=null) {
                let tempItem = newState.data[action.catIndex].pct_values[action.valIndex];
                newState.data[action.catIndex].pct_values[action.valIndex] = newState.data[action.catIndex].pct_values[action.valIndex+direction];
                newState.data[action.catIndex].pct_values[action.valIndex+direction] = tempItem;
            } else if(action.catIndex!=null) {
                let tempItem = newState.data[action.catIndex];
                newState.data[action.catIndex] = newState.data[action.catIndex+direction]
                newState.data[action.catIndex+direction] = tempItem
            }
            break;
        case 'remove':
            // move Item up or down in list
            if(action.valIndex!=null && action.catIndex!=null) {
                newState.data[action.catIndex].pct_values.splice(action.valIndex,1);
            } else if(action.catIndex!=null) {
                newState.data.splice(action.catIndex,1);
            }
            break;
        case 'order':
            // re-order elements after drag'n'drop
            if (action.e.itemData.level !== undefined){ //element is category
                newState.data.splice(action.e.fromIndex,1); //delete Element at from index
                newState.data.splice(action.e.toIndex,0,action.e.itemData);//add element at to index
            }else{ //element is value
                newState.data.forEach(item => {
                    //find matching category
                    if (item.pct_values[action.e.fromIndex] === action.e.itemData){
                        item.pct_values.splice(action.e.fromIndex,1); //delete Element at from index
                        item.pct_values.splice(action.e.toIndex,0,action.e.itemData); //add element at to index
                    }
                })
            }
            break;
        case 'addItem':
            // add new item to categories or values
            if(action.catIndex !== null) {
                // add new value item
                let newPcvItem = {
                    value: null,
                    factor: 1, 
                    sku_label: null, 
                    description: null, 
                    position:newState.data[action.catIndex].pct_values.length+1, 
                    pct_id:action.fields.pct_id, 
                    pcv_id:null}
                newState.data[action.catIndex].pct_values.push(newPcvItem);
            } else {
                // add new category item
                let newPctItem = {
                    base_pcv_id: null, 
                    level:newState.data.length+1, 
                    name: null, 
                    pct_id:null, 
                    clt_id:null,
                    prf_id: newState.prf_id,
                    pct_values: []
                }
                newState.data.push(newPctItem);
            }
            break;
        case 'changeValue':
            // change field values in category or value
            if(action.pcv_index !== null) {
                newState.data[action.index].pct_values[action.pcv_index][action.field] = action.value;
            } else {
                newState.data[action.index][action.field] = action.value;
            }
            break;
        default:
        }
        newState.data = assignPositionsByIndex(newState.data);
    return newState;
}

const assignPositionsByIndex = (sortedArray:any[]) => {
    let newArray = [...sortedArray]
    //set levels to go from arraylength downto 1
    for(let iCat=0;iCat<newArray.length;iCat++){
        var curCat = newArray[iCat];
        curCat.level = newArray.length - iCat;
        //set positions to go from arraylength down to 1
        for(let iVal=0;iVal<curCat.pct_values.length;iVal++){
            var curVal = curCat.pct_values[iVal];
            curVal.position = curCat.pct_values.length - iVal
        }
    }
    return newArray;
}

const toogleDescriptionField = (parentIndex:number,index:number,prefix:string) => {
    let descriptionField = document.getElementById(prefix+"_"+parentIndex+"_"+index);
    let iconUp = document.getElementById(prefix+"_"+parentIndex+"_"+index+"_up");
    let iconDown = document.getElementById(prefix+"_"+parentIndex+"_"+index+"_down");
    if(descriptionField !== null){
        if(descriptionField.style.display === "none"){
            descriptionField.style.display = "block";
            if(iconUp !== null && iconDown !== null){
                iconUp.style.display = "block";
                iconDown.style.display = "none";
            }
        }else{
            descriptionField.style.display = "none";
            if(iconUp !== null && iconDown !== null){
                iconUp.style.display = "none";
                iconDown.style.display = "block";
            }
        }
    }
}


type ProductCategoryProps = {
    prf_id?:string,
    saveState: boolean,
    setSave: (b:boolean)=>void,
}

export default function ProductCategory(props:ProductCategoryProps)
{
    const initialState:ProductCategoryState = {
        data:[],
        addCategory:false,
        addValue:-1,
        prf_id:props.prf_id
    };
    
    const [state, dispatch] = useReducer(reducer, initialState);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const dataLoader:DataLoader = new DataLoader();
        let options:DataLoaderOptions = {
            include:'pct_values'
        }
        if(props.prf_id !== undefined){
            options['filter'] = {"prf_id": props.prf_id}
        }
        dataLoader.getDatafromAPI('product_category',options)
        .then(result => {
            dispatch({type:'init',data:[...result.data]})
            setLoaded(true);
        })
        .catch((e:any) => {
            handleErrors(e)
        })
    },[props.prf_id])

    //save if flag is set
    useEffect (() => {
        if(props.saveState === true) {
            new Ajax('/product_category/save',{
                method: "POST",
                body: JSON.stringify({data: state.data, prf_id: props.prf_id})
            }).setMessages("Save successful!","Save failed!")
            .execute().finally(()=>props.setSave(false))
        }
    }, [props.saveState]); // eslint-disable-line
    
    // create category items
    const categories=state.data.map(function callback(category:any, index:number){
        let reactKey = 'category_' + index;
        return(
            <Category
                category={category}
                index={index}
                count={state.data.length}
                key={reactKey}
                dispatch={dispatch}
                addValue={state.addValue}
            ></Category>
        );
    });

    return (
        <>
            {!loaded && <Loading textMessage={<span>Loading&#8230;</span>} show={true} />}
            {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />} 
            <form className="workflowForm">
                {categories}
                <CategoryForm addCategory={state.addCategory} dispatch={dispatch}/>
            </form>
        </>
    );
}

function Category (props:any) {
    const nameFieldIsValid:boolean = (
        props.category.name !== "" &&
        props.category.name !== undefined &&
        props.category.name !== null
    );
    // check if button are in first or last row
    const disableUp = props.index === 0;
    const disableDown = props.index === props.count - 1;
    const classNameUp = disableUp ? 'action_button up disabled' : 'action_button up';
    const classNameDown = disableDown ? 'action_button down disabled' : 'action_button down';
    // highlight placeholder if save wasn't successful
    let className = (nameFieldIsValid) ? "workflow_input" : "workflow_input invalid";
    const valueFormName = "insert_value_" + props.index;
    // get values
    const values = props.category.pct_values.map(function callback(value:any, index:number){
        let reactKey = 'category_value_' + index;
        return(
            <Value
                value={value}
                index={index}
                count={props.category.pct_values.length}
                parentIndex={props.index}
                key={reactKey}
                dispatch={props.dispatch}
            />
        );
    });

    let moveUp = (e:any) => {
        e.preventDefault();
        move(true);
    }

    let moveDown = (e:any) => {
        e.preventDefault();
        move(false);
    }
    
    let move = (moveUp:boolean) => {
        props.dispatch({
            type:'move',
            catIndex:props.index,
            valIndex:null,
            moveUp:moveUp
        });
    };

    let deleteSubmit = (e:any) => {
        e.preventDefault();
        props.dispatch({
            type:'remove',
            catIndex:props.index,
            valIndex:null
        });
    }

    const name = isNullOrUndefined(props.category.name);
    const description = isNullOrUndefined(props.category.description);
    return(
        <>
        <CategoryHeader />
        <Row className={"category"}>
            <Col lg={6}>
                <input
                    type="text"
                    placeholder="Name"
                    className={className}
                    value={name}
                    onChange={e => {props.dispatch({
                        type:'changeValue',
                        index:props.index,
                        pcv_index:null,
                        value:e.target.value,
                        field:'name'})}}
                />
                <button
                    id={"cat_toggle_"+props.parentIndex+"_"+props.index}
                    onClick={e =>{
                        e.preventDefault();
                        toogleDescriptionField(props.parentIndex,props.index,"cat");
                    }}
                    disabled={false}
                    className={"description"}
                >
                    <i id={"cat_"+props.parentIndex+"_"+props.index+"_down"} className="fas fa-angle-double-down description"></i>
                    <i id={"cat_"+props.parentIndex+"_"+props.index+"_up"} style={{display:"none"}} className="fas fa-angle-double-up description"></i>
                </button>
                <br/>
                <textarea
                    placeholder="Description"
                    id={"cat_"+props.parentIndex+"_"+props.index}
                    className={className}
                    style={{display:"none"}}
                    value={description}
                    onChange={e => props.dispatch({
                        type:'changeValue',
                        index:props.index,
                        pcv_index:null,
                        value:e.target.value,
                        field:'description'})}
                ></textarea>
            </Col>
            <Col lg={6}>
                <Row>
                    <Col lg={7}></Col>
                    <Col lg={1} className={"bootstrap_col_center"}>
                        <button
                            onClick={moveUp}
                            disabled={disableUp}
                            className={classNameUp}
                        >
                            <i className="fas fa-angle-up"></i>
                        </button>
                    </Col>
                    <Col lg={1} className={"bootstrap_col_center"}>
                        <button
                            onClick={moveDown}
                            disabled={disableDown}
                            className={classNameDown}
                        >
                            <i className="fas fa-angle-down"></i>
                        </button>
                    </Col>           
                    <Col lg={1}></Col>     
                    <Col lg={1} className={"bootstrap_col_center"}>
                        <button
                            onClick={deleteSubmit}
                            className="action_button delete"
                            >
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </Col> 
                    <Col lg={1}></Col>                     
                </Row>
            </Col>
        </Row>
        {(props.category.pct_values.length > 0) ? <ValueHeaderRow /> : ""}
        {values}
        <ValueForm
            addValue={props.addValue === props.index}
            dispatch={props.dispatch}
            formName={valueFormName}
            pct_id={props.category.pct_id}
            index={props.index}
        />
        </>
    );
}

function CategoryForm (props:any) {
    let onSubmit = (e:any) => {
        e.preventDefault();
        let fields = {};
        props.dispatch({
            type: 'addItem',
            fields: fields,
            catIndex:null
        });
    };
    return(
        <div className="row category_insert">
            <div className="col-lg-12">
                <button
                    className="action_button insert"
                    onClick={onSubmit}
                    >
                    <i className="fas fa-plus-circle"></i>
                </button>
                <span className="addButtonText">Add new category</span>
            </div>
        </div>
    );
}

function CategoryHeader() {
    return(
        <div className="row header_row">
            <div className="col-lg-6">
                <span className="workflowHeader">Category</span>
            </div>
            <div className="col-lg-6">
                <div className="row">
                    <div className="col-lg-7"></div>
                    <div className="col-lg-1 bootstrap_col_center">
                        <span className="workflowHeader mid">Up</span>
                    </div>
                    <div className="col-lg-1 bootstrap_col_center">
                        <span className="workflowHeader mid">Down</span>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-1 bootstrap_col_center">
                        <span className="workflowHeader mid">Delete</span>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
        </div>
    );
}

function Value (props:any) {
    const disableUp = props.index === 0;
    const disableDown = props.index === props.count - 1;
    const classNameUp = disableUp ? 'action_button up disabled' : 'action_button up';
    const classNameDown = disableDown ? 'action_button down disabled' : 'action_button down';
    let className = "workflow_input";
    let value = isNullOrUndefined(props.value.value);
    let description = isNullOrUndefined(props.value.description);
    let factor = isNullOrUndefined(props.value.factor);
    factor = factor === '' ? 1 : Number(factor);
    let skuLabel = isNullOrUndefined(props.value.sku_label);

    let moveUp = (e:any) => {
        e.preventDefault();
        move(true);
    }

    let moveDown = (e:any) => {
        e.preventDefault();
        move(false);
    }
    
    let move = (moveUp:boolean) => {
        props.dispatch({
            type:'move',
            catIndex:props.parentIndex,
            valIndex:props.index,
            moveUp:moveUp
        });
    };

    let deleteSubmit = (e:any) => {
        e.preventDefault();
        props.dispatch({
            type:'remove',
            catIndex:props.parentIndex,
            valIndex:props.index
        });
    }
    return (
        <Row className={"category_value"}>
            <Col lg={1}></Col>
            <Col lg={7}>
                <Row>
                    <Col lg={8}>
                        <input
                            type="text"
                            data-icon={"down"}
                            placeholder="Value"
                            className={className}
                            value={value}
                            onChange={e => props.dispatch({
                                type:'changeValue',
                                index:props.parentIndex,
                                pcv_index:props.index,
                                value:e.target.value,
                                field:'value'})}
                        />
                        <button
                            id={"toggle_"+props.parentIndex+"_"+props.index}
                            onClick={e =>{
                                e.preventDefault();
                                toogleDescriptionField(props.parentIndex,props.index,"val");
                            }}
                            disabled={false}
                            className={"description"}
                        >
                            <i id={"val_"+props.parentIndex+"_"+props.index+"_down"} className="fas fa-angle-double-down description"></i>
                            <i id={"val_"+props.parentIndex+"_"+props.index+"_up"} style={{display:"none"}} className="fas fa-angle-double-up description"></i>
                        </button>
                        <br/>
                        <textarea
                            placeholder="Description"
                            id={"val_"+props.parentIndex+"_"+props.index}
                            className={className}
                            style={{display:"none"}}
                            value={description}
                            onChange={e => props.dispatch({
                                type:'changeValue',
                                index:props.parentIndex,
                                pcv_index:props.index,
                                value:e.target.value,
                                field:'description'})}
                        ></textarea>
                    </Col>
                    <Col lg={2}>
                        <input
                            type="number"
                            step="0.01"
                            required={true}
                            placeholder="Factor"
                            className={className}
                            value={factor}
                            onChange={e => props.dispatch({
                                type:'changeValue',
                                index:props.parentIndex,
                                pcv_index:props.index,
                                value:e.target.value,
                                field:'factor'})}
                        />
                    </Col>
                    <Col lg={2}>
                        <input
                            type="text"
                            placeholder="SKU label"
                            className={className}
                            value={skuLabel}
                            onChange={e => props.dispatch({
                                type:'changeValue',
                                index:props.parentIndex,
                                pcv_index:props.index,
                                value:e.target.value,
                                field:'sku_label'})}
                        />
                    </Col>
                </Row>
            </Col>
            <Col lg={4}>
                <Row>
                    <Col lg={2}></Col>
                    <Col lg={1} className={"bootstrap_col_center"}>
                        <button
                            onClick={moveUp}
                            disabled={disableUp}
                            className={classNameUp}
                        >
                            <i className="fas fa-angle-up"></i>
                        </button>
                    </Col>
                    <Col lg={1} className={"bootstrap_col_center"}>
                        <button
                            onClick={moveDown}
                            disabled={disableDown}
                            className={classNameDown}
                        >
                            <i className="fas fa-angle-down"></i>
                        </button>
                    </Col>               
                    <Col lg={1}></Col> 
                    <Col lg={1} className={"bootstrap_col_center"}>
                        <button
                            onClick={deleteSubmit}
                            className="action_button delete"
                        >
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </Col>
                    <Col lg={6}></Col>
                </Row>
            </Col>
        </Row>
    );
}

function ValueForm(props:any) {
    let onSubmit = (e:any) => {
        e.preventDefault();
        let fields = {
            pct_id:props.pct_id
        };
        props.dispatch({
            type: 'addItem',
            fields: fields,
            catIndex: props.index
        });
    };
    return (
        <Row className={"value_insert"}>
            <Col lg={1}></Col> 
            <Col lg={11}>
                <button
                    className="action_button insert"
                    onClick={onSubmit}
                    >
                    <i className="fas fa-plus-circle"></i>
                </button>
                <span className="addButtonText">Add new category value</span>
            </Col> 
        </Row>
    );
}

function ValueHeaderRow() {
    return(
        <Row className={"header_row"}>
            <Col lg={1}></Col> 
            <Col lg={7}>
                <Row>
                    <Col lg={8}>
                        <span className="workflowHeader">Value</span>
                    </Col>
                    <Col lg={2}>
                        <span className="workflowHeader">Factor</span>
                    </Col>
                    <Col lg={2}>
                        <span className="workflowHeader">SKU-Label</span>
                    </Col>
                </Row>
            </Col> 
            <Col lg={4}>
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-1 bootstrap_col_center">
                        <span className="workflowHeader mid">Up</span>
                    </div>
                    <div className="col-lg-1 bootstrap_col_center">
                        <span className="workflowHeader mid">Down</span>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-1 bootstrap_col_center">
                        <span className="workflowHeader mid">Delete</span>
                    </div>
                    <div className="col-lg-6"></div>
                </div>
            </Col>
        </Row>
    );
}

function isNullOrUndefined(value:any) {
    return value === null ? '' : value;
}