import React, { useState, useEffect } from 'react';
import './InfoRow.css'

export type InfoRowProps = {
    [key:string]: string|JSX.Element|boolean|undefined
    header: string|JSX.Element,
    content?: string|JSX.Element,
    extendHelpText: boolean
}

export function InfoRow(props:InfoRowProps) : JSX.Element {
    const [defaultChecked,setDefaultChecked]: boolean|any = useState(undefined)

    useEffect(()=>{
        setDefaultChecked(props.extendHelpText)
    },[props.extendHelpText])


    let content;
    if(typeof props.content === "string") {
        content = <p id="info_row_content">{props.content}</p>
    } else {
        content = <div id="info_row_content">{props.content}</div>;
    }

    let header;
    if(content !== undefined) {
        header = <>
            <input defaultChecked={defaultChecked} type="checkbox" name="info_row_switch" id="info_row_switch"></input>
            <label htmlFor="info_row_switch">
                <h1 id="info_row_header">
                    <span className = "fa fa-angle-right info_row_switch_icon"></span>
                    <span id="info_row_header_text">
                        {props.header}
                    </span>
                    <span className = "fa fa-info-circle info_icon"></span>
                </h1>
            </label>
            {content}
        </>
    } else {
        header = <>
            <h1 id="info_row_header">
                <span id="info_row_header_text">
                    {props.header}
                </span>
            </h1>
        </>
    }

    return(
        <div className="row">
            <div className="col-lg-12">
                {header}
            </div>
        </div>
    )
}

export default InfoRow;
