import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { EventKey } from 'react-bootstrap/esm/types';
import InfoRow from '../InfoRow/InfoRow';

//css
import './Layout.css';

type DetailContent = {
    [key:string]: any,
    component: JSX.Element,
    shortHelpText?: JSX.Element|string,
    fullHelpText?: JSX.Element|string,
    extendHelpText?: boolean,
    showSaveButton?: boolean
}

type DetailLayoutProps = {
    pageTitle?:string,
    backFunction?: () => void,
    saveFunction?: () => void,
    setSave?: any,
    saveState?: boolean,
    isWorkflow?: boolean,
    content: {
        [key:string]:DetailContent
    },
    children?: JSX.Element|JSX.Element[]
}

export function DetailLayout(props:DetailLayoutProps) : JSX.Element {
    let initialState = "properties";
    let children: JSX.Element[] = [];
    if(props.children !== undefined){
        if(!Array.isArray(props.children)){
            children = [props.children];
        }
        else {
            children = props.children;
        }
        initialState = children[0].props.eventKey
    }
    const [activeTab, setActiveTab] = useState<string|null>(initialState);
    const [tabPosition, setTabPosition]:"first" | "last" | "" | any = useState("");

    let activeContent:DetailContent = {
        component: (
            <div className="detailbackdrop">
                <h1>Nothing here :(</h1>
                <h3>Try again later!</h3>
            </div>
        )
    };
    
    if(props.content !== undefined && props.content[activeTab as string] !== undefined && props.content[activeTab as string].component !== undefined){
        activeContent = props.content[activeTab as string]
    }

    //Get all Contentvariables
    //set backButton if backFunction is defined
    let backButton:JSX.Element = <></>;
    if(props.backFunction !== undefined) {
        backButton = (
            <Button className="btn-detail" 
                variant="primary" 
                onClick={props.backFunction}>
                <span className = 'fa fa-angle-double-left'></span> Back
            </Button>
        );
    }


    //set saveButton if saveFunction is defined
    let saveButton:JSX.Element = <></>;
    if( activeContent.showSaveButton !== false && (props.saveFunction !== undefined || activeContent.component.props.setSave !== undefined)) {
        saveButton = (
            <Button className="btn-detail"
                variant="primary"
                onClick={() => {
                    if(activeContent.component.props.setSave !== undefined){
                        props.setSave(true)
                    }else if(props.saveFunction !== undefined){
                        props.saveFunction()
                    }
                }}>
                <span className = 'fa fa-save'></span> Save
            </Button>
        );
    }

    //set infoIcon if fullHelptext is defined
    let infoRow = <></>;
    if (activeContent.shortHelpText !== undefined) {
        infoRow = (
            <InfoRow
                header={activeContent.shortHelpText}
                content={activeContent.fullHelpText}
                extendHelpText={activeContent.extendHelpText || false}
            />
        )
    }


   let tabs:JSX.Element[] = [];
   for (let i = 0; i < children.length; i++) {
       if (i > 0) {
           if(props.isWorkflow){
            //Set arrows between Tabs
                tabs.push(<Tab key = {i} disabled = {true} title = {<span className = 'fa fa-angle-right'></span>} />);
            }
        }
        tabs.push(children[i]);
    }


    // Lifecycle Functions

    //update tabPosition if tab changes and trigger navArrow Load
    useEffect ( () => {
        let tab:number = getActiveTabIdx();
        if (tab === 0) {
            setTabPosition("first");
        } else if (tab === children.length-1) {
            setTabPosition("last");
        } else if (tabPosition !== "") {
            setTabPosition("");
        }
    }, [activeTab]); // eslint-disable-line


    //Functions
    //returns index of active tab in tabs array
    const getActiveTabIdx:() => number = () => {
        let curTab:number = -1;
        for (let i:number = 0; i < children.length; i++) {
            if(activeTab === children[i].props.eventKey) {
                curTab = i;
            }
        }
        return curTab;
    }
    
    //on Click switch tab to given direction
    let switchTab = (target: "previous" | "next") => {
        let curTab:number = getActiveTabIdx();
        switch (target) {
            case "previous":
                    let prevTab:string = children[curTab-1].props.eventKey;
                    setActiveTab(prevTab);
            break;
            case "next":
                    let nextTab:string = children[curTab+1].props.eventKey;
                    setActiveTab(nextTab);
            break;
        }
    }


    //Renderer
    return (
        <div id="detailContainer">
            <Container fluid={true}>
                <Row>
                    <Col className="col-sm-8 col-lg-8 no-padding">
                        <h4 className="pageTitle">{props.pageTitle||"Empty Title"}</h4>
                    </Col>
                    <Col className="col-sm-4 col-lg-4 buttonWrapper">
                        {saveButton}
                        {backButton}
                    </Col>
                </Row>
                <Row className="spacer">
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <Col sm = "auto" className = "detail-tabs nav-tabs arrowCol">
                        {getNavArrow("left", props, tabPosition, switchTab, activeContent)}
                    </Col>
                    <Col className="no-padding alignCenter">
                        <Tabs
                            variant = "tabs"
                            className="detail-tabs"
                            id="controlled-tabs"
                            activeKey={activeTab as EventKey|undefined}
                            onSelect={(eventKey:string|null) => {setActiveTab(eventKey)}}
                        >
                            {/* accept Tab-Elements as children */}
                            {tabs}
                        </Tabs>
                    </Col>
                    <Col sm = "auto" className = "detail-tabs nav-tabs arrowCol">
                        {getNavArrow("right", props, tabPosition, switchTab, activeContent)}
                    </Col>
                </Row>
                <Row className="spacer">
                    <Col className="no-padding">
                        {infoRow}
                        <div>
                            {activeContent.component}
                        </div>
                    </Col>
                </Row>
                <Row className="spacer">
                    <Col className="no-padding">
                        &nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col className="col-sm-12 col-lg-12 no-padding alignRight">
                        {saveButton}
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

//returns arrow and choose if enabled or disabled
export function getNavArrow(direction:"left" | "right", props:any, tabPosition:string, switchTab:any, activeContent:any) {
    if(!props.isWorkflow){
        return <></>;
    }
    let abledClass:string;
    let faClass:string;
    let onClick: (e:any) => void = () => {};

    if (direction === "left") {
        faClass = "fa fa-arrow-left";
        if (tabPosition !== "first") {
            abledClass = "navArrow left";
            onClick = () => switchTab("previous");
        } else {
            abledClass = "navArrowDisabled";
        }

    } else {
        faClass = "fa fa-arrow-right";
        if (tabPosition !== "last" && !activeContent.component.props.isNew && props.children.length>1) {
            abledClass = "navArrow right";
            onClick = () => switchTab("next");
        } else {
            abledClass = "navArrowDisabled";
        }
    }
    return (
        <div className="arrowWrapper" onClick={onClick}>
            <h4 className={abledClass}>
                <span className={faClass}></span>
            </h4>
        </div>
    );
}
export default DetailLayout;