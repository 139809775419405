import React, { useState, useEffect } from 'react';
import { DataGrid } from 'devextreme-react';
import { Paging, Pager, FilterRow, Column } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

import { Article, art_pbk } from '../../models/types'; // eslint-disable-line
import Loading from '../../components/Loading/Loading';
import { Ajax } from '../../models/ajax';

type PricebookArticleProps = {
    saveState: boolean,
    setSave: (b:boolean)=>void,
    pbk_id:string,
    pbk_factor: number
}


export function PricebookArticle(props:PricebookArticleProps) : JSX.Element {
    const [reload]:boolean | any = useState(true);
    const [articles, setArticles]:Article[] | any = useState(undefined);
    const [art_pbk, setArt_pbk]:art_pbk[] | any = useState(undefined);
    const [dataGridInstance, setDataGridInstance]: any = useState(undefined);

    //get all art_pbk data
    useEffect(() => {
        let filterString = btoa('{"pbk_id": "' + props.pbk_id + '"}');
        new Ajax('art_pbk?filter='+filterString,{
        }).setMessages("").execute()
        .then(result=>setArt_pbk(result.data))
    }, [reload, props.pbk_id]);

    //get all article data
    useEffect(() => {
        new Ajax('article',{
        }).setMessages("").execute()
        .then(result => setArticles(result.data));
    }, [reload, props.pbk_id, props.pbk_factor]);
    

    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && dataGridInstance !== undefined) {
            let saveData:art_pbk[] = [];
            let saveKeys:string[] = dataGridInstance.getSelectedRowKeys();
            saveKeys.forEach ((art_id:string) => {
                for (let i = 0; i < articles.length; i++) {
                    if (articles[i].art_id === art_id) {
                        saveData.push({
                            price: articles[i].price,
                            pbk_id: props.pbk_id,
                            art_id: articles[i].art_id,
                            name: articles[i].name,
                            label: articles[i].label,
                            sku: articles[i].sku
                        });
                    }
                }
            });
            new Ajax('pricebook/save/'+props.pbk_id,{
                method: "PATCH",
                body: JSON.stringify({art_pbk: saveData})
            }).setMessages("Successfully changed pricebook articles!","Could not change pricebook articles!")
            .execute().finally(()=>props.setSave(false))
        }
    }, [props.saveState]); // eslint-disable-line

    //set preselection of datagrid for every article which are already in use
    useEffect (() => {var selection:string[] = [];
        if (art_pbk !== undefined) {
            art_pbk.forEach((art:art_pbk) => {
                selection.push(art.art_id);
            });
        }
        if (dataGridInstance !== undefined) {
            dataGridInstance.selectRows(selection, false);
        }
    }, [art_pbk, articles]); // eslint-disable-line
    
    const store = new ArrayStore({
        key: "art_id", 
        data: articles
    });
    const ds = new DataSource({
        store: store,
        sort: "label"
    });

    return (
        <>
            <Loading textMessage={<span>Loading&#8230;</span>} show={(articles === undefined || art_pbk === undefined)} />
            {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
            <div id="gridContainer" className="noOuterPadding">
                {articles === undefined && <Loading textMessage={<span>Loading&#8230;</span>} show={true} />}
                {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
                <DataGrid 
                    dataSource={ds}
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={false}
                    columnChooser={{enabled:false, mode:'select'}} 
                    loadPanel={{enabled:false}}
                    selection = {{
                        mode:"multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always",
                        allowSelectAll: true
                    }}
                    >
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                    <Column 
                        dataField = {"label"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        width={"70%"}
                    />
                    <Column 
                        caption={"SKU"}
                        dataField = {"sku"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        width={"15%"}
                    />
                    <Column 
                        dataField = {"price"}
                        alignment={"right"}
                        showInColumnChooser={false}
                        dataType={"number"}
                        format={"#,##0.00"}
                        width={"15%"}
                    />
                    
                </DataGrid>
            </div>
        </>
    );
}

export default PricebookArticle;
