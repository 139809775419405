import React from "react";
import { 
            OverviewConfig, 
            AdminConfig, 
            AdminConfigChildren, 
            AdminConfigColumns, 
            RelationDefinition, 
            ColumnDefinition, 
            FkConfig 
        } from "../components/MasterDetail/types";
import { Lookup, Column } from 'devextreme-react/data-grid';
import { clientKey, clientModel } from "../constants";

type formatConfigOptions = {
    visibleColumns?:string[],
    selectedPKs?:object,
}

export function formatConfig(adminConfig:AdminConfig,columnOptions:formatConfigOptions={},includePKs=false){
    let configGen:OverviewConfig = {
        relations:      formatRelations(adminConfig.aChildren),
        columns:        formatColumns(adminConfig,columnOptions,includePKs),
        // columns:        formatColumns(adminConfig.columns,adminConfig.adminConfig.primary_keys,includePKs,columnOptions),
        fks:            formatForeignKeys(adminConfig.columns),
        primary_keys:   adminConfig.primary_keys,
        table:          adminConfig.table,
        name_property:  adminConfig.name_property,
        show_client:    adminConfig.show_mandant,
        filter_client:  adminConfig.filter_mandant,
        client_key:     adminConfig.sMandantKey,
        client_table:   adminConfig.sMandantTable
    };
    return configGen;
}

export function formatRelations(relations:AdminConfigChildren[]){
    let result:RelationDefinition[] = [];
    relations.forEach((relation:AdminConfigChildren) => {
        const relationElement = {
            description_short:  relation.description_short,
            model:              relation.name,
            relation_table:     {
                model:      relation.name,
                columns:    [],
                fks:        {}
            },
            lookup_model:       relation.relation_name,
            role_name:          relation.role_name,
            pks:            [relation.name.split('_')[0]+'_id',relation.name.split('_')[1]+'_id'],
        }
        result.push(relationElement);
    });
    return result;
}


export function formatColumns(
        adminConfig:AdminConfig,
        columnOptions:formatConfigOptions,
        includePKs:boolean=false,
    ){
    let result:ColumnDefinition[] = [];
    adminConfig.columns.forEach((column:AdminConfigColumns) => {
        //search for column name in pk-array; add only if column is not a pk
        if(includePKs || adminConfig.primary_keys.findIndex((e) => e === column.name) === -1){
            const columnElement:ColumnDefinition = {
                dataField:          column.name,
                is_empty_allowed:   column.is_empty_allowed,
                is_required_field:  column.is_required_field,
                description_short:  column.description_short,
                is_foreign_key:     column.is_foreign_key,
                is_read_only:       column.is_read_only,
                key_description:    column.key_description,
                default_visible:    columnOptions.visibleColumns !== undefined ? columnOptions.visibleColumns.indexOf(column.name) !== -1 : true,
                default_value:      column.default_value_app !== null && column.default_value_app.length > 0 ? column.default_value_app : null,
                type:               'string',
            }

            if(column.data_type.indexOf("FLOAT") !== -1 
                || column.data_type.indexOf("DECIMAL") !== -1 
                || column.data_type.indexOf("INTEGER") !== -1 
                || column.physical_data_type.indexOf('DECIMAL') !== -1 
                || column.physical_data_type.indexOf('INT') !== -1){
                columnElement.type = 'number'
                if(column.physical_data_type.indexOf('INT') === -1 || column.data_type.indexOf("INTEGER") === -1){
                    columnElement.format = '#,##0.00'
                }
            }

            if(column.data_type.indexOf("DATE") > -1){
                columnElement.type = 'date'
            }
            if(column.data_type.indexOf("DATETIME") > -1 || column.data_type.indexOf("TIMESTAMP") > -1){
                columnElement.type = 'datetime'
            }

            if(column.physical_data_type.indexOf('TINYINT(1)') !== -1){
                columnElement.type = 'boolean'
            }
            result.push(columnElement)
        }
    })
    return result
}

export function formatForeignKeys(columnConfig:AdminConfigColumns[]){
    let result:FkConfig = {};
    columnConfig.forEach((column:AdminConfigColumns) => {
        if(column.is_foreign_key && column.key_table !== clientModel){
            result[column.name] = {
                model:column.key_table||'',
                valueExpr:column.key_value||'',
                displayExpr:column.key_description||'',
            }
        }
    })
    return result
}

export function getColumnElements(
    columns:ColumnDefinition[], 
    fkData:{[key:string]:object[]}, 
    fkConfig:FkConfig, 
    selectedPKs:object={})
    {
    const columnElements:JSX.Element[] = [];
    columns.forEach((colDef:ColumnDefinition) => {
        //if the column's key appears in fkConfig, look up the values
        let lookup = null;
        if(fkConfig[colDef.dataField] !== undefined){
            lookup = (
                <Lookup 
                    dataSource={fkData[colDef.dataField]}
                    valueExpr={fkConfig[colDef.dataField].valueExpr} 
                    displayExpr={fkConfig[colDef.dataField].displayExpr}
                    allowClearing={colDef.is_empty_allowed === 1}
                />
            )
        }
        
        if(Object.keys(selectedPKs).indexOf(colDef.dataField) !== -1 || colDef.dataField === clientKey){//datafield is the selected PK (e.g. in Relation)
            colDef.visible = false;  
            colDef.showInColumnChooser = false;
        }else{
            colDef.allowEditing = true;
            colDef.visible = colDef.default_visible;  
            colDef.showInColumnChooser = true;
        }

        let columnElement = (
            <Column  
                visible = {colDef.visible}
                dataType={colDef.type}
                dataField={colDef.dataField}
                caption={colDef.description_short}
                allowEditing={!colDef.is_read_only}
                showInColumnChooser={colDef.showInColumnChooser}
                format = {colDef.format}
                width = {colDef.type === 'string' && colDef.dataField !== 'sku' ? '30%' : undefined}
                key = {colDef.dataField}>
                {lookup}
            </Column>
        )
        columnElements.push(columnElement);
    });
    return columnElements;
}

export function deleteRelation(config:RelationDefinition[],name:string){
    const result:RelationDefinition[] = []

    config.forEach((curRel) => {
        if(curRel.model !== name){
            result.push(curRel)
        }
    })

    return result;
}

export function setColumnProp(columns:ColumnDefinition[],field:string,property:string,value:any){
    var result = [...columns]
    result.forEach((item)=>{
        if(item.dataField === field){
            item[property] = value
        }
    })
    return result
}