import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys, deleteRelation } from "../../models/config";
import { AdminConfig } from '../../components/MasterDetail/types';

import { product, prd_siz, prd_plt, prd_prd, prd_lng, prp_prd, pct_prd } from '../../models/config_all';

export function Products(props:any){
    const defaultVisibleColumns = ["prf_id","label","price","stt_id","sku"]
    const visibleColumns = props.visibleColumns !== undefined ? props.visibleColumns : defaultVisibleColumns

    let config = formatConfig(product,{visibleColumns: visibleColumns});
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'pct_prd':
                relConfig = pct_prd;
                break;
            case 'prd_siz':
                relConfig = prd_siz;
                break;
            case 'prd_plt':
                relConfig = prd_plt
                break;
            case 'prd_prd':
                relConfig = prd_prd
                break;
            case 'prd_lng':
                relConfig = prd_lng
                break;
            case 'prp_prd':
                relConfig = prp_prd
                break;
            default:
                relConfig = product;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    //delete unwanted RLs
    // config.relations = deleteRelation(config.relations,"pct_prd")
    config.relations = deleteRelation(config.relations,"prp_prd")

    return(
        <Overview 
            model="product" 
            pk="prd_id" 
            config={config}
            {...props}
        />
    )
}

export default Products;


