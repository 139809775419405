import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { partner_tier } from '../../models/config_all';

export function PartnerTier(){
    let config = formatConfig(partner_tier);
    return(
        <Overview 
            model="partner_tier" 
            pk="pnt_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default PartnerTier;


