import React from "react";
import { toast } from "react-toastify";

export function showToast(type:"error"|"success"|"warning"|"info",text?:string,options?:object){
    if(type === "success"){
        const toastText = text !== undefined ? text : "Success!"
        toast.success(<span className="toastSuccess">{toastText}</span>, {
            position: "top-center",
            autoClose: 3000,
            ...options
        });
    } else if (type === "warning"){
        const toastText = text !== undefined ? text : "Warning!"
        toast.warn(<span className="toastSuccess">{toastText}</span>, {
            position: "top-center",
            autoClose: 5000,
            ...options
        });
    } else if (type === "error"){
        const toastText = text !== undefined ? text : "Error!"
        toast.error(<span className="toastError">{toastText}</span>, {
            position: "top-center",
            autoClose: 7000,
            ...options
        });
    } else if (type === "info"){
        const toastText = text !== undefined ? text : ""
        toast(<span className="toastSuccess">{toastText}</span>, {
            position: "top-center",
            autoClose: 5000,
            ...options
        });
    }
}

export default showToast;