import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { TextBox, SelectBox } from 'devextreme-react'
import { DataLoader } from '../../../models/store';
import schema from './schema.png';
import { Article, Pricebook } from '../../../models/types';
import { SmartSellerAction } from './SmartSellerMain';

const dataLoader:DataLoader = new DataLoader();

type PricebookOption = Pricebook & { count: number }
type ArticleOption = Article & { pricebook: Pricebook }
type SmartSellerSearchProps = {
    dispatch: (action:SmartSellerAction) => void,
    test?: {
        focusSearch: boolean,
        skuSearch: string,
        nameSearch: string,
        pbkSearch: string,
    }
}

export function SmartSellerSearch (props: SmartSellerSearchProps): JSX.Element{
    const [allResults,setAllResults] = useState<ArticleOption[]|undefined>(undefined)
    const [focusSearch,setFocusSearch] = useState<boolean>(props.test !== undefined ? props.test.focusSearch : false)
    const [skuSearch,setSkuSearch] = useState<string>(props.test !== undefined ? props.test.skuSearch : "")
    const [nameSearch,setNameSearch] = useState<string>(props.test !== undefined ? props.test.nameSearch : "")
    const [pbkSearch,setPbkSearch] = useState<string>(props.test !== undefined ? props.test.pbkSearch : "")
    
    useEffect(()=>{
        if(allResults === undefined){
            dataLoader.getDatafromAPI("sales/article",{
                include:"pricebook"
            })
            .then((result:any)=>{
                setAllResults(result.data)
            })
        }
    },[]) // eslint-disable-line

    const maxResults = 7;

    const selectItem = (item:ArticleOption) => {
        if(item.pricebook !== undefined){
            props.dispatch({type:"setArtID",data:{art_id:item.art_id}})
            props.dispatch({type:"setPbkID",data:{pbk_id:item.pricebook.pbk_id}})
        }
    }

    let filteredResults:ArticleOption[] = []
    let filteredPricebooks:PricebookOption[] = []
    if(allResults !== undefined){
        let searchHasLen = (skuSearch + nameSearch + pbkSearch).length > 0

        // (allResults:Article[],filterValues:{skuSearch:string,nameSearch:string,pbkSearch:string})
        const filterValues = {
            sku: skuSearch,
            name: nameSearch,
            pbk: pbkSearch
        }
        filteredResults = (focusSearch || searchHasLen) ? filterResults(allResults,filterValues) : []
        //get matching price books
        allResults.forEach((entry)=>{
            if(entry.pricebook !== undefined){
                let match = false
                filteredPricebooks.forEach(pbk=>{
                    if(pbk.pbk_id === entry.pricebook.pbk_id){
                        match = true
                        pbk.count++
                    }
                })
                if(!match){
                    filteredPricebooks.push({...entry.pricebook,count:1})
                }
            }
        })
        //add default option to dropdown
        filteredPricebooks.unshift({label:"All Price Books",pbk_id:"",count:allResults.length} as PricebookOption)
    }

    return (
        <Container fluid={true}>
            <h4 className="pageTitle smartseller center">How to boost your sales in 3 easy steps!</h4>
            <Row>
                <Col className="col-sm-3 col-lg-3 no-padding"></Col>

                <Col className="col-sm-6 col-lg-6 no-padding">
                    <span className={"search-label"}>Find Articles</span>
                    <TextBox 
                        value={skuSearch}
                        placeholder={"SKU"}
                        onValueChanged={(e:any)=>{setSkuSearch(e.value)}}
                        valueChangeEvent={"input change keyup"}
                        onFocusIn={(e:any)=>setFocusSearch(true)}
                        elementAttr={{"data-testid":"skuSearchBox"}}
                        inputAttr={{"data-testid":"skuSearchInput"}}
                    />
                    <TextBox 
                        value={nameSearch}
                        placeholder={"Name"}
                        onValueChanged={(e:any)=>{setNameSearch(e.value)}}
                        valueChangeEvent={"input change"}
                        onFocusIn={(e:any)=>setFocusSearch(true)}
                        elementAttr={{"data-testid":"nameSearchBox"}}
                        inputAttr={{"data-testid":"nameSearchInput"}}
                    />
                    <SelectBox
                        className={"pricebookSelect"}
                        dataSource={filteredPricebooks}
                        defaultValue={""}
                        displayExpr={"label"}
                        valueExpr={"pbk_id"}
                        onFocusIn={(e:any)=>setFocusSearch(true)}
                        onValueChanged={(e:any)=>setPbkSearch(e.value)}
                        itemRender={(data:PricebookOption) => <span>{ data.label } ({ data.count })</span>}
                        elementAttr={{"data-testid":"pbkSearchBox"}}
                    />
                    {allResults !== undefined &&
                        <input data-testid={"option-count"} value={allResults !== undefined ? allResults.length : 0} type="hidden"/>
                    }
                </Col>

                <Col className="col-sm-3 col-lg-3 no-padding"></Col>
            </Row>
            {focusSearch && 
                <SearchResultsList
                    results = {filteredResults}
                    maxResults = {maxResults}        
                    selectItem={selectItem}
                />
            }
            <Row className="schema">
                <img src={schema} alt={"schema"}/>
            </Row>
            <Row className="explanation">
                <Col sm={4} lg={4}>
                    <h4>1. Select the current product!</h4>
                    <p>Your customer asks for a renewal quote?</p>
                    <p>Instead of scrolling through confusing price lists you can easily type in the SKU or product name to <b>save time.</b>  </p>
                    <p>With SmartSeller you can now start the <b>professional sales process</b>.</p>
                   </Col>

                <Col sm={4} lg={4}>
                    <h4>2. Check out your options!</h4>
                    <p>After selecting the product you will see all matching options: renewals, upgrades or cross selling options.</p>
                    <p>It <b>prevents</b> you from making <b>wrong choices</b> in the sales process.</p>
                    <p>For each option you choose you will see benefits and qualifying questions to <b>support your sales call</b>.</p>
                </Col>

                <Col sm={4} lg={4}>
                    <h4>3. Create the perfect offer!</h4>
                    <p>After having made your choices you will get the new specific product for your client. Instead of an ordinary  renewal quote you can show your <b>competency</b> and help to optimize the customer product usage.</p>
                    <p>That delights the customer and <b>boosts your sales</b>! </p>
                </Col>
            </Row>
        </Container>
    )
}

type SearchResultsListProps = {
    results: ArticleOption[],
    maxResults: number,
    selectItem:(art:ArticleOption)=>void
}

export function SearchResultsList(props:SearchResultsListProps){
    const [showAll,setShowAll] = useState<boolean>(false)

    return(
        <Row data-testid={"searchResultList"}>
            <Col className="col-sm-3 col-lg-3 no-padding"></Col>
            <Col className="col-sm-6 col-lg-6 no-padding search-result-container">
                <span className={"search-label"}>Matches: {props.results.length}</span>
                {props.results.map((article,index) => {
                    if(showAll || index < props.maxResults){
                        return (
                            <SearchResultItem 
                                article={article} 
                                key={article.art_id+'_'+article.pricebook.pbk_id} 
                                selectItem={props.selectItem}
                            />
                        )
                    }else{
                        return undefined;
                    }
                }).filter((item)=>item !== undefined)}
                {/* display "show more/less" buttons */}
                {props.results.length > props.maxResults &&
                    <Row>
                        {!showAll &&
                            <Col 
                                onClick={()=>setShowAll(true)} 
                                className={"showAllOrLess"}>
                                    show all results
                            </Col>
                        }
                        {showAll &&
                            <Col 
                                onClick={()=>setShowAll(false)} 
                                className={"showAllOrLess"}>
                                    show less results
                            </Col>
                        }
                    </Row>
                }
            </Col>
            <Col className="col-sm-3 col-lg-3 no-padding"></Col>
        </Row>
    )
}

type SearchResultItemProps = {
    article:ArticleOption,
    selectItem:(art:ArticleOption)=>void
}

export function SearchResultItem(props:SearchResultItemProps){
    return(
        <Row 
            data-testid={"searchResultItem"}
            className="search-result-row" 
            onClick={()=>props.selectItem(props.article)}
        >
            <Col>
                <Row>
                    <Col sm={1} className="search-result-sku">
                        <span className={"search-result-label"}>SKU:</span>
                    </Col>
                    <Col sm={5} className="search-result-sku">
                        {props.article.sku}
                    </Col>
                    <Col sm={2} className="search-result-pbk">
                        <span className={"search-result-label"}>Price Book:</span>
                    </Col>
                    <Col sm={4} className="search-result-pbk">
                        {props.article.pricebook ? props.article.pricebook.label : ""}
                    </Col>
                </Row>
                <Row>
                    <Col sm={1} className="search-result-article">
                        <span className={"search-result-label"}>Name:</span>
                    </Col>
                    <Col sm={11} className="search-result-article">
                        {props.article.label}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export function filterResults(allResults:ArticleOption[],filterValues:{sku:string,name:string,pbk:string}){
    return allResults.filter((entry:ArticleOption)=>{
        let matchSku:boolean = checkFilterCondition(entry.sku,filterValues.sku)
        let matchPbk:boolean = checkFilterCondition(entry.pricebook.pbk_id,filterValues.pbk)
        let matchName:boolean = checkFilterCondition(entry.label,filterValues.name)
        return matchSku && matchName && matchPbk ? entry : undefined;
    })
}

export function checkFilterCondition(entry:string,search:string){
    //search for every word in a whitespace separated list, return true if all match
    let match:boolean = true
    search.split(" ").forEach((conditionPart:string)=>{
        if(conditionPart.length > 1 && conditionPart.substr(0,1) === "-"){
            //check excluded condition
            if(entry.toLowerCase().indexOf(conditionPart.substr(1).toLowerCase()) !== -1){
                match = false;
            }
        }else{
            //check included condition
            if(entry.toLowerCase().indexOf(conditionPart.toLowerCase()) === -1){
                match = false;
            }
        }
    })
    return match;
}


