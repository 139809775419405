import React, { useState, useEffect } from 'react';

import PricelistArticles from '../PricebookWorkflow/PricelistArticles';
import { Container, Row, Col } from 'react-bootstrap';
import { Pricebook } from '../../models/types';
import { Ajax } from '../../models/ajax';
import Loading from '../../components/Loading/Loading';

type PricebookDetailProps = {
    [key:string]: any,
    pbk_id:string,
    readOnly : boolean
}


export function PricebookDetail(props:PricebookDetailProps) : JSX.Element {
    const [pricebook,setPricebook] = useState<Pricebook|undefined>(undefined)

    useEffect(()=>{
        if(pricebook === undefined || pricebook.pbk_id !== props.match.params.id) {
            new Ajax('sales/pricebook/'+props.match.params.id,{})
            .setMessages("").execute()
            .then(result=>{
                setPricebook(result.data)
            });
        }
    },[props.match.params.id]) // eslint-disable-line

    return(
        <div id="detailContainer">
            <Loading textMessage={<span>Loading&#8230;</span>} show={pricebook === undefined} />
            <Container fluid={true}>
                <Row>
                    <Col className="col-sm-8 col-lg-8 no-padding">
                        <h4 className="pageTitle">{
                            pricebook !== undefined ? pricebook.label : ""
                        }</h4>
                    </Col>
                </Row>
                <Row className="spacer">
                    <Col>&nbsp;</Col>
                </Row>
                <Row>
                    <PricelistArticles 
                        pbk_id={pricebook !== undefined ? pricebook.pbk_id : ""} 
                        saveState={false} 
                        setSave={()=>undefined}
                        readOnly={true}
                        app={"sales"}
                    />
                </Row>
            </Container>
        </div>
    );
}

export default PricebookDetail;
