import React, { useState, useEffect } from 'react'
import { DataLoader } from '../../../models/store';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ArticleDetail } from './ArticleDetail';
import RelatedArticles from './RelatedArticles';
import Breadcrumb from './Breadcrumb';
import { Pricelist } from '../../../models/types_generated';
import { SelectBox } from 'devextreme-react';
import { SmartSellerPin } from './SmartSellerPins';
import { SmartSellerAction } from './SmartSellerMain';

const dataLoader:DataLoader = new DataLoader();

type SmartSellerDetailProps = {
    [key:string]:any,
    art_id: string,
    pbk_id: string,
    dispatch: (action:SmartSellerAction) => void,
    pinned: SmartSellerPin[],
    navigation?: any
}

export function SmartSellerDetail (props: SmartSellerDetailProps): JSX.Element{
    const [navigation,setNavigation] = useState<any>(props.navigation || [])
    // const [relatedObjects,setRelatedObjects] = useState<object|undefined>({
    //     upgrades: {children:[], count:-1},
    //     maintenance_types: [],
    //     related: []
    // })
    const [upgrades,setUpgrades] = useState<object|undefined>({
        children:[], 
        count:-1
    })
    const [maintenance_types,setMaintenance_types] = useState<object|undefined>(undefined)
    
    const [related,setRelated] = useState<object|undefined>(undefined)
   
    const [pricelists,setPricelists] = useState<Pricelist[]|undefined>(undefined)
    const [activePricelist,setActivePricelist] = useState<string>("")

    //load pricelists
    useEffect (() => {
        dataLoader.getDatafromAPI("sales/pricelist",{
            filter: {pbk_id: props.pbk_id},
            order: "label"
        })
        .then(result=>{
            setPricelists(result.data)
            if(result.data.length){
                //set first entry by default
                setActivePricelist(result.data[0].pli_id)
                props.dispatch({type:"setPliID",data:{pli_id: result.data[0].pli_id}})
            }
        })
    }, [props.pbk_id]); // eslint-disable-line

    useEffect (() => {
        setActivePricelist(props.pli_id)
    }, [props.pli_id]); // eslint-disable-line

    //load related articles
    useEffect(()=>{
        if(activePricelist !== undefined){
            dataLoader.getDatafromAPI("sales/smartseller/"+props.art_id+"/options/upgrades").then(result=>{
                setUpgrades(result.data);
            });
            dataLoader.getDatafromAPI("sales/smartseller/"+props.art_id+"/options/maintenance_types").then(result=>{
                setMaintenance_types(result.data);
            });
            dataLoader.getDatafromAPI("sales/smartseller/"+props.art_id+"/options/related_articles").then(result=>{
                setRelated(result.data);
            });

        }
    },[props.art_id]) // eslint-disable-line


    const navigateForward = (type:string,category:string,data:any) => {
        const newNav = [...navigation,{type:type,category:category,data:data}]
        setNavigation(newNav)
    }

    const navigateBack = () => {
        let newNav = [...navigation]
        newNav.pop()
        setNavigation(newNav)
    }

    const navigateToIndex = (index:number) => {
        let newNav = [...navigation]
        newNav.splice(index,newNav.length-index)
        setNavigation(newNav)
    }

    return (<>
        <Container id="SSDetail" fluid={true}>
            <Row>
                <Col sm={4} lg={4}>
                    <h1>Smartseller</h1>
                </Col>
                <Col sm={8} lg={8}>
                    Price list
                    <SelectBox
                        className={"pricebookSelect"}
                        elementAttr={{"data-testid":'wubbalubbadubdub'}}
                        placeholder={'Select pricelist'}
                        dataSource={pricelists}
                        displayExpr={"label"}
                        valueExpr={"pli_id"}
                        value={activePricelist}
                        onValueChanged={(e:any) => {
                            setActivePricelist(e.value)
                            props.dispatch({type:"setPli_id",data:{pli_id:e.value}})
                        }}
                    />
                </Col>
            </Row>
            <Row className="spacer">
                <Col className="no-padding">&nbsp;<hr/></Col>
            </Row>
            <Row>
                <Col sm={4} lg={4} className="no-padding-left">
                    <Button 
                        className="backButton" 
                        onClick={()=>{
                            props.dispatch({type:"goBack"});
                        }}
                    >
                        Back to the article selection
                    </Button>
                    <Row>
                    <div className="article">
                        <h4>Current Article</h4>
                        <hr className="clear-all"/>
                        <ArticleDetail 
                            art_id={props.art_id} 
                            pli_id={activePricelist}
                            dispatch={props.dispatch}
                            pricelists={pricelists}
                        />
                    </div>
                    </Row>
                </Col>
                <Col sm={8} lg={8} className="no-padding-right">
                    <Row>
                    {navigation.length?<>
                        <Button className="breadcrumbButton backButton" onClick={navigateBack}><i className="fas fa-chevron-left"></i></Button>
                    
                        <Breadcrumb 
                            navigation={navigation}
                            navigateBack={navigateBack}
                            navigateTo={navigateToIndex}
                        /><hr/></>:<></>
                    }
                    
                    </Row>
                    {/* <Row>
                    {navigation.length?<h4>{navigation[navigation.length-1].category}</h4>:<h4 className="defaultHeadline">Start</h4>}
                    </Row> */}
                    <Row>
                        <RelatedArticles 
                            navigation={navigation}
                            navigate={navigateForward}
                            pli_id={activePricelist}
                            art_id={props.art_id}
                            data={{
                                upgrades,
                                maintenance_types,
                                related
                            }}
                            dispatch={props.dispatch}
                            navigateTo={navigateToIndex}
                            pricelists={pricelists}
                        />
                    </Row>
                </Col>
            </Row>
        </Container>
   </>)
}


export default SmartSellerDetail