import React, { useEffect, useReducer } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import showToast from '../../components/handleError';
import { alphabetSortName } from '../../constants';
import getAxiosInstance from '../../models/http';
import { Prd_prd } from '../../models/types';
import { PrdPrd, ProductRelationType } from '../../models/types_generated';
import { ListBoxesSelector } from './ProductSelectBox';
import "./relations.css";
import { RelationTable } from './RelationTable';

export type RelationPrd = {
    prd_id: string,
    prf_id: string,
    name: string, 
    selected: boolean,
    type: "product"
}

export type RelationPrf = {
    prd_id: null,
    prf_id: string,
    name: string, 
    selected: number,
    type: "product_family",
    products: RelationPrd[],
}

export type RelationsState = {
    base: RelationPrf[],
    target: RelationPrf[],
    prt: string,
    relationType: ProductRelationType[],
    relations: Prd_prd[],
    reload: boolean
}

type RelationsAction = {
    type: string,
    data: any
}

function reduceStateToSelection(state:RelationsState){
    var baseProducts = state.base.flatMap((baseElem) =>
        baseElem.products.map((product)=> product.selected ? product.prd_id : undefined )
    ).filter(x=>x!==undefined);

    var targetProducts = state.target.flatMap((targetElem) =>
        targetElem.products.map((product)=> product.selected ? product.prd_id : undefined )
    ).filter(x=>x!==undefined)

    return [baseProducts,targetProducts];
}

function reducer(state:RelationsState,action:RelationsAction){
    let newState = {...state};
    switch(action.type){
        case 'select':
            for(let i=0;i<action.data.elem.options.length;i++){
                let option: HTMLOptionElement = action.data.elem.options[i];
                if(option.selected){
                    // iterate over either base or target elements
                    newState[action.data.type as "base"|"target"].forEach((prf:RelationPrf)=>{
                        if(option.dataset.prf === prf.prf_id){
                            if(option.dataset.prd === undefined){
                                // if element is family, set all product's "selected" to true
                                prf.products.forEach((prd:RelationPrd)=>{
                                    prd.selected = action.data.selectState;
                                })
                            }else{
                                // if element is found, set "selected" to true
                                prf.products.forEach((prd:RelationPrd)=>{
                                    if(option.dataset.prd === prd.prd_id){
                                        prd.selected = action.data.selectState;
                                    }
                                })
                            }
                            prf.selected = prf.products.filter(elem=>elem.selected).length;
                        }
                    })
                }
            }
            newState.reload = true;
            break;

        case 'setPrt':
            newState.prt = action.data;
            break;

        case 'loadRelations':
            newState.relations = action.data;
            newState.reload = false;
            break;

        case 'loadProducts':
            action.data.forEach((product:any) => {
                let prd:RelationPrd = {
                    prd_id: product.prd_id,
                    name: product.name,
                    selected: false,
                    prf_id: product.prf_id,
                    type: "product"
                };

                let prf:RelationPrf = {
                    prd_id: null,
                    name: product.product_family.name,
                    prf_id: product.prf_id,
                    selected: 0,
                    type: "product_family",
                    products: []
                };

                // check if prf has already been processed
                let index = newState.base.findIndex((element:any)=>{
                    return element.prf_id === product.prf_id
                })

                if(index === -1){
                    newState.base.push({...prf});
                    newState.target.push({...prf});
                }

                index = newState.base.findIndex((element:any)=>{
                    return element.prf_id === product.prf_id
                })

                newState.base[index].products = [...newState.base[index].products,{...prd}];
                newState.target[index].products = [...newState.target[index].products,{...prd}];

            });

            // sort entries by name on both levels
            newState.base.sort(alphabetSortName);
            newState.target.sort(alphabetSortName);

            newState.base.forEach((prf:RelationPrf) =>{
                prf.products.sort(alphabetSortName);
            })
            newState.target.forEach((prf:RelationPrf) =>{
                prf.products.sort(alphabetSortName);
            })
            break;

        case 'loadRelationTypes':
            newState.relationType = action.data;
            newState.prt = action.data[0].prt_id;
            break;

        case 'createRelations':
            let elem = document.getElementById("prt_dropdown") as HTMLSelectElement;
            
            //reduce to one level, only extract product IDs
            let [base,target] = reduceStateToSelection(newState);

            getAxiosInstance().post("product_relation_type/"+elem.value,{base: base, target: target}).then((response)=>{
                console.log("created",response.data)
                let resultText = "created!";
                
                showToast("success", resultText);
            });
            newState.reload = true;
            break;

        default:
    }

    return newState;
}

export function Relations (props:any): JSX.Element{
    const [state, dispatch]:RelationsState | any = useReducer(reducer, {base:[],target:[],prt:"",relationType:[],relations:[],reload:false});

    //API call
    useEffect(() => {
        const axiosInstance = getAxiosInstance();
        axiosInstance.get("product?include=product_family")
        .then(function(response:any) {
            dispatch({type:"loadProducts",data:response.data.data})
        });

        axiosInstance.get("product_relation_type")
        .then(function(response:any) {
            dispatch({type:"loadRelationTypes",data:response.data.data})
        });
    }, []);


    useEffect(()=>{
        if(state.reload){
            let [baseSelection,targetSelection] = reduceStateToSelection(state);

            let filter = [];

            if(baseSelection.length > 0){
                if(baseSelection.length > 1){
                    filter.push({key: "prd_prd.base_prd_id", operator: "in", value: "("+baseSelection.map((e)=>"'"+e+"'").join(",")+")"});
                }else{
                    filter.push({key: "prd_prd.base_prd_id", operator: "=", value: baseSelection[0]});
                }
            }
            if(targetSelection.length > 0){
                if(targetSelection.length > 1){
                    filter.push({key: "prd_prd.prd_id", operator: "in", value: "("+targetSelection.map((e)=>"'"+e+"'").join(",")+")"});
                }else{
                    filter.push({key: "prd_prd.prd_id", operator: "=", value: targetSelection[0]});
                }
            }

            if(filter.length > 0){
                getAxiosInstance().get("prd_prd/",{params:{filter:filter,filterOperator:"OR",include:"product,base_product,product_relation_type"}}).then((response)=>{
                    // response.data.data.forEach((elem:PrdPrd)=>{
                    //     elem.prt_id = (elem.product_relation_type as ProductRelationType).prt_id;
                    // })
                    dispatch({type:"loadRelations",data:response.data.data})
                });
            }else{
                dispatch({type:"loadRelations",data:[]});
            }
        }
    },[state.reload]);

    if (state.base === undefined || state.target === undefined || state.relationType === undefined) {
        return <p>Loading...</p>
    }else{
        return (
            <Row className={"page"}>
                <Col sm={6}>
                    <Row>
                        <Col sm={12}>
                        <h1>Base Product</h1>
                        </Col>
                    </Row>
                    <ListBoxesSelector dispatch={dispatch} data={state.base} type={"base"}/>
                    <Row>
                        <Col sm={12}>
                            <h1>Target Product</h1>
                        </Col>
                    </Row>
                    <ListBoxesSelector dispatch={dispatch} data={state.target} type={"target"}/>
                </Col>
                <Col sm={6}>
                    <Row>
                        <Col sm={12}>
                            <h1>Product Relation Type</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {/*Dropdown PRT*/}
                            <select id={"prt_dropdown"} onChange={(e) => dispatch({type:"setPrt",data:e.target.value}) }>
                                {state.relationType.map((prt:any)=>
                                    <option key={prt.prt_id} value={prt.prt_id}>{prt.name}</option>
                                )}
                            </select>
                        </Col>
                        <Col sm={3}>
                            {/*Button*/}
                            <Button variant={"primary"} onClick={()=>dispatch({type:"createRelations"})}>Create Relations</Button>
                        </Col>
                        <Col sm={3}></Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <RelationTable relations={state.relations} prt={state.prt}/>
                         </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}

export default Relations;