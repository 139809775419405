import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { EventKey } from 'react-bootstrap/esm/types';



type DetailTabContent = {
  [key:string]: JSX.Element | undefined,
  property:JSX.Element,
  history?:JSX.Element
}

export function Dashboard (){
  const [tab, setTab] = useState<string|null>('property')

  let content:DetailTabContent = {
    'history': <p>This is history</p>,
    'property': <p>This is property</p>
  };


  return (
    <div>
      This is the Dashboard
      <Tabs
        id="controlled-tab-example"
        activeKey={tab as EventKey}
        onSelect={(key:string|null) => setTab( key )}
      >
        <Tab eventKey="property" title="Property">
        </Tab>
        <Tab eventKey="history" title="History">
        </Tab>
      </Tabs>
      {content[tab as string]}
    </div>
  );
}

export default Dashboard;


