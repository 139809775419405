import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { pricelist  } from '../../models/config_all';

export function PriceList(props:any){
    let config = formatConfig(pricelist);
    return(
        <Overview 
            model="pricelist" 
            pk="pli_id" 
            config={config}
            {...props}
        />
    )
}

export default PriceList;


