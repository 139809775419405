import React, { useState } from 'react'

import './SmartSeller.css'
import { Button, Col, Container, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'
import { Article } from '../../../models/types'
import { SmartSellerAction } from './SmartSellerMain';
import { Pricelist } from '../../../models/types_generated';

export type SmartSellerPinsProps = {
    pins: SmartSellerPin[]
    art_id?: string,
    pbk_id?: string,
    pli_id?: string,
    dispatch: (action:SmartSellerAction) => void
}

export function SmartSellerPins(props:SmartSellerPinsProps): JSX.Element{
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <h1>Pinned Articles</h1>
            <ListGroup>
                {props.pins.map((elem:SmartSellerPin)=>
                    <PinnedArticle 
                        {...elem}
                        // article = {elem} 
                        key={elem.article.art_id+elem.pricelist.pli_id}
                        dispatch = {props.dispatch}
                        active = {props.art_id === elem.article.art_id && props.pli_id === elem.pricelist.pli_id}
                    />
                )}
                {props.pins.length === 0 &&
                    <ListGroupItem>no entry</ListGroupItem>
                }
                {props.pins.length > 0 &&
                    <ListGroupItem
                        onClick={(e)=>props.dispatch({type:"clearPins",data:{}})}
                        className={"unpin"}
                    >
                        <i className="fas fa-trash-alt"></i>&nbsp;Clear All Pins
                    </ListGroupItem>
                }
            </ListGroup>
        </>
    )
    
    return (
        <>
            <Button variant="primary" onClick={handleShow}>Shopping Cart</Button>

            <Modal show={show} onHide={handleClose} animation={false} className="pinnedList">
                 
                <Modal.Header translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined}>
                    <Modal.Title>Pinned Articles</Modal.Title>
                </Modal.Header> 

                <Modal.Body>
                    <ListGroup>
                        {props.pins.map((elem:SmartSellerPin)=>
                            <PinnedArticle 
                            {...elem}
                            // article = {elem} 
                            key={elem.article.art_id+elem.pricelist.pli_id}
                                dispatch = {props.dispatch}
                                active = {props.art_id === elem.article.art_id && props.pli_id === elem.pricelist.pli_id}
                                />
                                )}
                        {props.pins.length === 0 &&
                            <ListGroupItem>no entry</ListGroupItem>
                        }
                        {props.pins.length > 0 &&
                            <ListGroupItem
                            onClick={(e)=>props.dispatch({type:"clearPins",data:{}})}
                            className={"unpin"}
                            >
                                <i className="fas fa-trash-alt"></i>&nbsp;Clear All Pins
                            </ListGroupItem>
                        }
                    </ListGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export type SmartSellerPin = {
    article: Article,
    pricelist: Pricelist
}

type PinnedArticleProps = {
    article:any,
    pricelist: Pricelist,
    dispatch:(action:SmartSellerAction) => void,
    active: boolean
}

export default function PinnedArticle(props:PinnedArticleProps): JSX.Element{
    let classString:string = "pinned-item" + (props.active ? " active": "");
    let margin = (props.article.list_price - props.article.price.value);
    return(<>
        <ListGroupItem className={classString}>
            <Container>
                <Row>
                    <Col xs={7} className="title border">{props.article.product.label}</Col>
                    <Col xs={5} className="sku border">{props.article.sku}</Col>
                </Row>
                <Row>
                    <Col xs={7} className="description border"> {props.article.label.replace(props.article.product.label,"")} </Col>
                    <Col xs={5} className="border">
                        <Row className="right-col">
                            <Col xs={12} className="pbk">{props.pricelist?.pricebook?.label}</Col>
                            <Col xs={12} className="pli">{props.pricelist.label}</Col>
                            <Col xs={12} >
                                <Row>
                                    <Col xs={4}>Price: </Col>
                                    <Col xs={8} className="price">{props.article.price.currency.symbol} {props.article.price.value.toFixed(2)}</Col>
                                </Row>
                            </Col>
                            <Col xs={12} className="margin">
                                <Row>
                                    <Col xs={4}>Margin: </Col>
                                    <Col xs={8} className="price">{props.article.price.currency.symbol} {margin.toFixed(2)}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7} 
                        className="select border"
                        onClick={(e:any)=>{
                            // props.dispatch({type:"setArtID",data:{art_id:props.article.art_id}})
                            props.dispatch({type:"selectPin",data:{article:props.article,pricelist:props.pricelist}})
                        }}
                    >
                        { props.active &&
                            <><i className="fas fa-check-circle"></i>&nbsp;Selected</>
                        }
                        { !props.active &&
                            <><i className="far fa-circle"></i>&nbsp;Select</>
                        }
                    </Col>
                    <Col xs={5} 
                        className="unpin border"
                        onClick={(e:any)=>{
                            props.dispatch({type:"removePin",data:{...props}})
                        }}
                    >
                        <i className="fas fa-trash-alt"></i>&nbsp;Delete
                    </Col>
                </Row>
            </Container>
        </ListGroupItem>
    </>);
}