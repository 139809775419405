import React from 'react';
import './Loading.css'

type LoadingProps = {
    [key:string]: any,
    textMessage?: any,
    show?: boolean,
    type?: "overlay"|"box"|"inline",
    size?: number | string
}

export function Loading(props:LoadingProps) : JSX.Element {
    let type = props.type !== undefined ? props.type : "overlay";
    let overlayClass = "loading";
    if(!props.show) {
        overlayClass += " hide";
    }

    let spinner = (
        <div id="loading_box">
            <div id="loading_image_container">
                <i className="fas fa-spinner fa-pulse" id="loading_animation"></i>
            </div>
            {props.textMessage !== undefined && 
                <p id="loading_text">{props.textMessage !== undefined ? props.textMessage : <span>Loading&#8230;</span>}</p>
            }
        </div>
    )

    if(type === "box"){
        return spinner;
    }else if(type === "inline"){
        return <InlineSpinner {...props}/>
    }else{
        // type "overlay" and others
        return(
            <div className={overlayClass} id="loading_overlay">
                {spinner}
            </div>
        )
    }
}

//TODO: hier rausnehmen und mit in Loading packen (aufrufe anpassen)
export function InlineSpinner(props:any){
    const spinnerStyle = {
        
        // color: props.color !== undefined ? props.color : "var(--ci-color)",
        ...props,
        fontSize: props.size !== undefined ? props.size+"px" : "96px"
    }

    return <i style={spinnerStyle} className="fas fa-spinner fa-pulse spinner-inline" id="loading_animation"></i>
}

export default Loading;

