import React from 'react';
import logo_heureka from './logo-heureka.png';
import logo_erwin from './erwin-logo.png';
import { Client } from '../../models/types';

type ClientStyleProps = {
    clientContext?: Client
}


export function ClientStyle(props:ClientStyleProps){
    if(props.clientContext !== undefined){
        //get styles from selected client (Sales App)
        const stylePath = "/styles/" + props.clientContext.css_file
        return (
            <>
                <link rel="stylesheet" type="text/css" href="/styles/base.css" ></link>
                <link rel="stylesheet" type="text/css" href="/styles/appacus.css" ></link>
                {props.clientContext.css_file !== null && props.clientContext.css_file.length && 
                    <link rel="stylesheet" type="text/css" href={stylePath} ></link>
                }
            </>
        );
    }else{
        //get styles by localstorage client (Price Books App)
        let stylePath = '';
        // get path to additional styles according to client ID
        switch (localStorage.getItem('client')) {
            case '2D429639-F578-42E4-99CFBE7AE97A3827': //Test Client
                stylePath = '/styles/heureka.css';
                break;
            case '6c4e329a-945c-11e8-b0d0-000c29f2e6d2': //Heureka Tests
                stylePath = '/styles/erwin.css';
                break;
            default:
                stylePath = '/styles/appacus.css';
            }
        const hasClientStyle = stylePath.length > 0;
        return (
            <>
                <link rel="stylesheet" type="text/css" href="/styles/base.css" ></link>
                {hasClientStyle && <link rel="stylesheet" type="text/css" href={stylePath} ></link>}
            </>
        );
    }
}

export function ClientLogo(props:ClientStyleProps){
    let logo = require("./logo_appaki.svg")
    let text = "All Partners"
    
    if(props.clientContext !== undefined){
        text = props.clientContext.name.length ? props.clientContext.name : text
        try{
            logo = require("./" + props.clientContext.logo_file)
        } catch(err){
            //no logo found; use default
        }
    }else{
        switch (localStorage.getItem('client')) {
            case '2D429639-F578-42E4-99CFBE7AE97A3827': //Test Client
                logo = logo_heureka;
                break;
            case '6c4e329a-945c-11e8-b0d0-000c29f2e6d2': //Heureka Tests
                logo = logo_erwin;
                break;
            default:
                logo = logo_heureka;
        }
    
    }
    return <img src={logo} id="client-logo" alt={text} title={text}/>
}

export default ClientStyle;


