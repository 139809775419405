import React from "react"

//#######################################################################################
//   B R E A D C R U M B  (top right)
//#######################################################################################

type BreadcrumbProps  = {
    navigation: any,
    navigateTo: (a:number) => void,
    navigateBack: () => void
}

function Breadcrumb (props: BreadcrumbProps): JSX.Element {
    const navArray = [{category:"Start"},...props.navigation]
    navArray.pop() //delete last element

    const navNodes = navArray.map((item:any,index:number)=>{
        //add delimiter if is not last element
        const delimiter = index !== navArray.length - 1 ? <span> &gt; </span> : <></>
        return (
            <React.Fragment key={"breadcrumbFragment_"+index}>
                <a href="go" key={"breadcrumb_"+index} onClick={(e)=>{e.preventDefault();props.navigateTo(index)}}>{item.category}</a>
                {delimiter}
            </React.Fragment> 
        )
    })

    return <div className="breadcrumbBox">{navNodes}</div>
}
export default Breadcrumb;