import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { TextBox, Button, Tooltip, SelectBox } from 'devextreme-react';

//custom components
import { getApiUrl } from '../../constants'

//css
import './Login.css';
import showToast from '../../components/handleError';
import { Application } from '../../models/types';
import { AppState } from '../../index';
import { ClaimBox } from '../../components/Header/Header';

type LoginError = {
    element:string,
    message:string
}

type LoginProps = {
    [key:string]:any
    appState: AppState,
    handleLogin: (app:Application) => void
};

type Client = {
    id: string,
    name:string
}

type LoginState = {
    [key:string]: string | boolean | LoginError[] | Client[] ,
    username: string,
    password: string,
    errors: LoginError[],
    loggingIn: boolean,
    // loggedIn: boolean,
    clients: Client[],
    client: string
};

export class Login extends Component<LoginProps, LoginState> {
    constructor(props:LoginProps){
        super(props);
        this.state = {
            username: "",
            password: "",
            errors:[],
            loggingIn: false,
            // loggedIn: false,
            clients:[],
            client:""
        }
    }

    makeLoginRequest = () => {
        //encapsulated for testing purposes
        return fetch(getApiUrl() + 'login',{
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify({
                "user": this.state.username,
                "password": this.state.password,
                "client": this.state.client,
                "app": this.props.location.pathname==="/sales"?"sales":"pricebooks",
            }),
            credentials:"include",
        })
        .then(response => {return response.json()})
        ;
    };

    login(){
        this.setState({...this.state, loggingIn:true});
        setTimeout(()=>{
            if(!this.props.appState.loggedIn && this.state.clients.length<1 && this.state.loggingIn){
                showToast("error", "Request Timeout. Please try again.");
                this.setState({...this.state, loggingIn:false});
            }
        }, 30000);
        return this.makeLoginRequest()
        .then(result => {
            if(this.state.loggingIn){
                if(result.status === 200){
                    localStorage.setItem('JWT', result.data.token);
                    localStorage.setItem('loggedIn', "true");
                    localStorage.setItem('loggedInApplication', this.props.appState.application);
                    if(this.props.appState.application === "pricebooks"){
                        localStorage.setItem('client', result.data.client);
                    }
                    this.setState({...this.state, loggingIn:false})
                    this.props.handleLogin(this.props.appState.application)
                }else if(result.status === 300){
                    this.setState({...this.state, clients:result.data, loggingIn:false});
                    showToast("info", "Please choose a client to login as");
                }else if(result.status > 499){
                    this.setState({...this.state, loggingIn:false});
                    showToast("error", "An error has occurred. Please try again later.");
                }else{
                    showToast("error", result.message);
                    this.setState({...this.state, errors:result.meta.errors, loggingIn:false});
                }
            }
        })
        .catch((e) => {
            this.setState({...this.state, loggingIn:false});
            showToast("error", "An error has occurred. Please try again later.");  
            console.log(e)
        });
    }

    handleChange(e:any){
        this.setState({[e.event.target.name]: e.event.target.value})
    }

    renderTooltips(){
        let tooltips:JSX.Element[] = [];
        this.state.errors.forEach(error => {
            tooltips.push(
                <Tooltip 
                    target={'#' + error.element}
                    position={'right'}
                    key={error.element}
                    className="login-tooltip"
                    visible={true}
                    width={'120px'}
                >
                    {error.message}
                </Tooltip>
            )
        });
        return tooltips;
    }

    checkClients(client:string){
        let found=false;
        this.state.clients.forEach(clt=> {
            if(clt.id === client){
                found=true; 
            }
        });
        return found;
    }

    render() {

        //redirect to start page if login is persisted
        if(this.props.appState.loggedIn){
            if(this.props.appState.application === this.props.appState.loggedInApplication){
                if(this.props.appState.application === "sales"){
                    return <Redirect push to="/sales/main" />
                }else{
                    return <Redirect push to="/main" />
                }

            }
        }

        let form:JSX.Element= (
            //form for Login
            <form onSubmit={(e:any) => {e.preventDefault();this.login()}}>
                <TextBox 
                    id="user"
                    name={"username"}
                    value={this.state.username}
                    onChange={(e:any) => this.handleChange(e)}
                    placeholder={"username"}
                    height={'36px'}
                    />
                <br/>
                <TextBox 
                    id="password"
                    name={"password"}
                    value={this.state.password}
                    onChange={(e:any) => this.handleChange(e)}
                    placeholder={"password"}
                    height={'36px'}
                    mode={'password'}
                    />
                <br/>
                <Button
                    width={"100%"}
                    text={this.state.loggingIn?("Logging in"):("Login")}
                    className={"login-btn"}
                    useSubmitBehavior={true}
                    type="default"
                    height={'36px'}
                    disabled={this.state.loggingIn}
                    />  
            </form>
        );

        var url_string = window.location.href;
        var url = new URL(url_string);
        var logout = url.searchParams.get("logout");

        switch(logout){
            case 'session':
                showToast("error", "Your session has timed out. Please log in again.")
                break;
            case 'logout':
                
                break;
            default: break;
        }

        if(this.state.clients.length>0){
            //form for client selection
            form = (
                <form onSubmit={(e) => {e.preventDefault();this.login()}}>
                    <SelectBox
                        id={'clientSelectionBox'}
                        dataSource={this.state.clients}
                        placeholder={'Client'}
                        displayExpr={'name'}
                        valueExpr={'id'}
                        onValueChanged={(e:any) => { this.setState({client: e.value})}}
                        />
                    <br/>
                    <Button
                        width={"100%"}
                        text={this.state.loggingIn?("Logging in"):("Login")}
                        className={"login-btn"}
                        useSubmitBehavior={true}
                        type="default"
                        height={'36px'}
                        disabled={this.state.loggingIn}
                        />  
                </form>
            )
        }

        let logo = require("../../components/ClientStyle/logo_appaki.svg")
        
        return (
            <div id={"login-background"}>
                <link rel="stylesheet" type="text/css" href="/styles/base.css" ></link>
                <Row className={"login-header-row"}>
                    <Col md={10}>&nbsp;</Col>
                    <Col md={2}>
                        <ClaimBox 
                            app={this.props.appState.application}
                            style={{"marginTop":0,"marginRight":"2rem"}}
                        />
                    </Col>
                </Row> 
                <Container style={{"height":"80%"}}>
                    <Row className="spacer" style={{"height":"30%"}}>
                        <Col>&nbsp;</Col>
                        <Col>&nbsp;</Col>
                        <Col>&nbsp;</Col>
                    </Row>     
                    <Row>
                        <Col>&nbsp;</Col>
                        <Col md={5} id={"login-box"}>
                            <img src={logo} id={"login-logo"} className={"center"} alt={""} title={""}/>
                            <h1 className={"center"}>Sell IT smart!</h1>
                            <br/>
                            {form}
                            {this.renderTooltips()}
                        </Col>
                        <Col>&nbsp;</Col>
                    </Row>
                    {/* <Row>
                        <Col>&nbsp;</Col>
                    </Row> */}
                </Container>
            </div>
        );
    }
}

