import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys } from "../../models/config";
import { AdminConfig } from "../../components/MasterDetail/types";

import { size, prd_siz } from "../../models/config_all";

export function Size(){
    let config = formatConfig(size);
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'prd_siz':
                relConfig = prd_siz;
                break;
            default:
                relConfig = size;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    return(
        <Overview 
            model="size" 
            pk="siz_id" 
            config={config}
        />
    )
}

export default Size;


