import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys } from "../../models/config";
import { AdminConfig } from '../../components/MasterDetail/types';

import { product_relation_type, prd_prd } from '../../models/config_all';

export function ProductRelationType(){
    let config = formatConfig(product_relation_type);
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'art_pbk':
                relConfig = prd_prd;
                break;
            default:
                relConfig = product_relation_type;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    return(
        <Overview 
            model="product_relation_type" 
            pk="prt_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default ProductRelationType;


