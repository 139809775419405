export const dashboardMock = [
    {
        user:{
            name:"Wick",
            vorname:"Amélie",
            user:"awick"
        },
        count: 28
    },
    {
        user:{
            name:"Jüttner",
            vorname:"Jens",
            user:"jjuettner"
        },
        count: 78
    },
    {
        user:{
            name:"Kausch",
            vorname:"Stefan",
            user:"skausch"
        },
        count: 82
    },
    {
        user:{
            name:"Diederich",
            vorname:"Michael",
            user:"mdiederich"
        },
        count: 19
    },
    {
        user:{
            name:"User",
            vorname:"Demo",
            user:"demo"
        },
        count: 129
    },
]