import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import './SalesApp.css'
import { List, SelectBox, TextArea, TextBox } from 'devextreme-react';
import Loading from '../../components/Loading/Loading';
import { DataLoader } from '../../models/store';
import { PcfPcs, ProcessForm } from '../../models/types_generated';
import { Process } from '../../models/types';
import { salesExtension } from '../../constants';
import Ajax from '../../models/ajax';

type Entry = ProcessForm & {
    pcf_pcs: PcfPcs[]
}

const initialEntry:Entry = {
    prc_id: null,
    pcf_id: "",
    name: null,
    description: null,
    pcf_pcs: [],
    usr_id: null,
    completed: false
};

export function Checklist (): JSX.Element{
    const [selectedChecklist, setSelectedChecklist] = useState<Process|undefined>(undefined);
    const [checklistData, setChecklistData] = useState<Process[]|undefined>(undefined);
    const [userRecords, setUserRecords] = useState<any>(undefined);
    const [entry, setEntry] = useState<any>(initialEntry);
    const [loading, setLoading] = useState<boolean>(true);

    const compare = (a:any,b:any) => (a["position"] < b["position"]) ? -1 : (a["position"] > b["position"]) ? 1 : 0

    useEffect(()=>{
        const dataLoader:DataLoader = new DataLoader();

        //fetch processes and their steps
        dataLoader.getDatafromAPI('sales/process',{
            include:'process_step'
        })
        .then(result => {
            result.data.sort(compare);
            result.data.forEach((item:any) => {
                item.process_step.sort(compare);
            });
            setChecklistData(result.data);
            setSelectedChecklist(result.data[0]);
            setEntry({...entry,prc_id: result.data[0].prc_id});
        })
    },[]) // eslint-disable-line

    useEffect(()=>{
        if(selectedChecklist !== undefined && userRecords !== undefined){
            setLoading(false);
        }
    },[selectedChecklist,userRecords]) // eslint-disable-line

    useEffect(()=>{
        fetchUserRecords();
    },[selectedChecklist]) // eslint-disable-line

    const fetchUserRecords = () => {
        const dataLoader:DataLoader = new DataLoader();
        let options:any = {include:'pcf_pcs',filter:{completed: 0}};
        if(selectedChecklist !== undefined && options.filter !== undefined){
            options.filter.prc_id = selectedChecklist.prc_id;
        }
        dataLoader.getDatafromAPI('sales/process_form',options)
        .then(result => {
            setUserRecords(result.data);            
        })
    }

    const submitForm = () => {
        setLoading(true);
        let finalEntry = entry;
        if(selectedChecklist !== undefined && selectedChecklist.process_step !== undefined){
            finalEntry = {
                ...finalEntry,
                completed: selectedChecklist.process_step.length === entry.pcf_pcs.length ? 1 : 0
            };
        }

        const update = finalEntry.pcf_id.length > 0 ;
        new Ajax(salesExtension + 'process_form/' + (update ? finalEntry.pcf_id : ''),{
            method: update ? "PATCH" : "POST",
            body: JSON.stringify(finalEntry)
        }).setMessages("Process submitted!","Error: could not be saved!")
        .execute().finally(()=>{
            setEntry(initialEntry);
            fetchUserRecords();
            setLoading(false);
        })
    }
    
    const selectRecord = (pcf_id:string) => {
        userRecords.forEach((record:Entry) => {
            if(record.pcf_id === pcf_id){
                setEntry(record);
            }
        })
    }

    const curTime = new Date();
    const currentTimeString = `${curTime.getFullYear()}-${curTime.getUTCMonth()}-${curTime.getUTCDay()}`+
        ` ${curTime.getHours()}:${curTime.getMinutes()}`;

    return (<>
        <Loading textMessage="Loading..." show={loading}/>
        {!loading &&
            <div id={"detailContainer"} className={"checklistContainer"}>
                <Row>
                    <Col>
                        <h1>Process Documentation</h1>
                    </Col>
                </Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row>
                    <Col sm={2}>
                        <p className={"dropdown-label"}>Select Checklist:</p>
                        <p className={"dropdown-label"}>Open Cases:</p><br/>
                    </Col>
                    <Col sm={10}>
                        <SelectBox
                            elementAttr={{"data-testid":'wubbalubbadubdub'}}
                            placeholder={'Select checklist'}
                            dataSource={checklistData}
                            displayExpr={"label"}
                            valueExpr={"prc_id"}
                            value={(selectedChecklist as Process).prc_id}
                            onValueChanged={(e:any) => {
                                let curList = (checklistData as Process[]).find((list:Process) => list.prc_id === e.value);
                                if(curList !== undefined){
                                    setSelectedChecklist(curList);
                                    setEntry({...entry,prc_id:curList.prc_id});
                                    setLoading(true);
                                }
                            }}
                        />
                        <SelectBox
                            placeholder={'Select existing Record'}
                            dataSource={userRecords}
                            displayExpr={"name"}
                            valueExpr={"pcf_id"}
                            value={(selectedChecklist as Process).prc_id}
                            onValueChanged={(e:any) => {
                                selectRecord(e.value);
                            }}
                        />
                        <p>{(selectedChecklist as Process).description}</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>
                        <p className={"dropdown-label"}>Usage:</p><br/>
                        <p className={"dropdown-label"}>Comment:</p>
                    </Col>

                    <Col sm={10}>
                        <TextBox 
                            placeholder={`New Entry (${currentTimeString})`}
                            value={entry.name}
                            onValueChanged={(e:any)=>{
                                setEntry({...entry,name: e.value})
                            }}
                        />
                        <TextArea
                            value={entry.description}
                            onValueChanged={(e:any)=>{
                                setEntry({...entry,description: e.value})
                            }}
                        />
                    </Col>
                </Row>
                <hr/>
                {checklistData !== undefined && !loading && <>
                    <Row>
                        <Col className={"detailbackdrop"}>
                        <List 
                            items={(selectedChecklist as Process).process_step}
                            displayExpr={"label"}
                            keyExpr={"pcs_id"}
                            selectedItemKeys={entry.pcf_pcs.map((item:PcfPcs)=>item.pcs_id)}
                            showSelectionControls={true}
                            selectionMode={"multiple"}  
                            itemRender={ChecklistItem}  
                            onOptionChanged={(e:any) => {
                                if(e.name === "selectedItems"){
                                    let newValues:PcfPcs[] = [];
                                    e.value.forEach((val:any) => {
                                        newValues.push({
                                            pcf_id: entry.pcf_id,
                                            pcs_id: val.pcs_id,
                                            completed: new Date()
                                        });
                                    });
                                    setEntry({...entry,  pcf_pcs: newValues});
                                }
                            }}
                        />
                        <Button className="btn-detail"
                            variant="primary"
                            onClick={()=>{
                                submitForm();
                                setLoading(true);
                        }}>
                            <span className = 'fa fa-save'></span> Save
                        </Button>
                        </Col>
                    </Row>
                </>}
            </div>
        }
    </>
)}

function ChecklistItem (item: any): JSX.Element {
    return (
        <div className="checkListItem">
            {item.label}
            <hr/>
        </div>
    )
}