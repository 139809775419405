import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { maintenance } from '../../models/config_all';

export function Maintenance(props:any){
    let config = formatConfig(maintenance);
    props = {
        pageOptions:{hideDetailPage:true},
        editorOptions:{
            mode:"form",
            allowAdding:true,
            allowUpdating:true,
            allowDeleting:true
        },
        ...props
    }
    return(
        <Overview 
            model="maintenance" 
            pk="mnt_id" 
            config={config}
            {...props}
        />
    )
}

export default Maintenance;


