import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys } from "../../models/config";
import { AdminConfig } from '../../components/MasterDetail/types';

import { audience, aud_rcp } from '../../models/config_all';

export function Audience(){
    let config = formatConfig(audience);
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'aud_rcp':
                relConfig = aud_rcp;
                break;
            default:
                relConfig = audience;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });

    return(
        <Overview 
            model="audience" 
            pk="aud_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default Audience;


