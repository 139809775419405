import React, { useState, useEffect } from 'react';

import {ColumnDefinition, OverviewConfig, DetailAction } from './types';
import { TextBox, SelectBox, NumberBox, CheckBox, DateBox } from 'devextreme-react';

import './Property.css';
import Loading from '../Loading/Loading';
import { DataLoader } from '../../models/store';
import { clientKey } from '../../constants';
import { Row, Col } from 'react-bootstrap';

type PropertyProps = {
  entry: Entry,
  config: OverviewConfig,
  deDispatch: (action: DetailAction) => void,
  fkData?: object,
  readOnly?: boolean
};

type Entry = {
  [key:string]: any
}

export function Property (props:PropertyProps) {
  let columns:JSX.Element[] = [];

  const [fkData,setFkData] = useState(props.fkData !== undefined ? props.fkData : {})
  const fkDataExists:boolean = Object.keys(fkData).length === Object.keys(props.config.fks).length;
  
  useEffect( () => {
    //get fk data if not provided by parent component
    if(!fkDataExists){
      //get all dependencies in array of promises
      const dataLoader:DataLoader = new DataLoader();
      let promises:any[] = Object.keys(props.config.fks).map((key) => dataLoader.getDatafromAPI(props.config.fks[key].model));
      //resolve all promises at the same time
      Promise.all(promises)
      .then((result)=>{
        let res:{[key:string]:object} = {};
        for(let i=0;i<result.length;i++){
          const curKey = Object.keys(props.config.fks)[i];
          res[curKey] = result[i].data
        }
        setFkData(res)
      });
    }
  },[fkDataExists,props.config.fks])

  let validateField = (colDef:ColumnDefinition, value:string | number | Date) => {
    //checks if field is empty and if a value is required
    if (colDef.is_required_field === 1) {
      if (value === undefined || value === "" || value === null) {
        return false;
      }
    }
    return true;
  }

  const allReadOnly = props.readOnly || false

  props.config.columns.forEach((colDef:ColumnDefinition) => {
    const key:string = colDef.dataField;
    if(key !== clientKey){
      
      let fieldDefaultValue = null
      if(colDef.default_value !== undefined && colDef.default_value !== null){
        fieldDefaultValue = colDef.default_value
      }

      let fieldValue = props.entry[key]
      if(fieldValue === undefined || fieldValue === null){
        fieldValue = fieldDefaultValue
      }

      let fieldReadOnly = false
      if(colDef.is_read_only === 1 || allReadOnly){
        fieldReadOnly = true
      }
      
      if(props.config.fks[key] === undefined){
        let inputElement = null;
        if(colDef.type === 'number'){
          inputElement = (
            <NumberBox 
              isValid={validateField(colDef, props.entry[key])}
              readOnly = {fieldReadOnly}
              format={colDef.format}
              value={fieldValue} 
              onValueChanged={(e:any) => {
                props.deDispatch({type:'valueChange', data:{[key]:e.value}}
              )}}/>
          )
        }else if(colDef.type === 'boolean'){
          inputElement = (
            <CheckBox 
              value={typeof props.entry[key] === 'number' && props.entry[key] !== 0} 
              readOnly = {fieldReadOnly}
              onValueChanged={(e:any) => {
                props.deDispatch({type:'valueChange', data:{[key]:e.value?1:0}}
              )}}/>
          )
        }else if(colDef.type === 'date'){
          inputElement = (
            <DateBox 
              isValid={validateField(colDef, props.entry[key])}
              value={fieldValue} 
              readOnly = {fieldReadOnly}
              width={"100%"}
              onValueChanged={(e:any) => {
                props.deDispatch({type:'valueChange', data:{[key]:e.value}}
              )}}/>
          )
        }else if(colDef.type === 'datetime'){
          if(fieldValue !== null){
            fieldValue = new Date(props.entry[key]+' UTC')
          }

          if( !(fieldReadOnly && fieldValue === null) ){
            inputElement = (
              <DateBox 
              isValid={validateField(colDef, props.entry[key])}
              value={fieldValue} 
              readOnly = {fieldReadOnly}
              width={"100%"}
              type={'datetime'}
              onValueChanged={(e:any) => {
                props.deDispatch({type:'valueChange', data:{[key]:e.value}}
                )}}/>
            )
          }else{
            inputElement = (<TextBox 
              value={fieldValue}
              readOnly = {fieldReadOnly}
            />)
          }
        }else{
          inputElement = (
            <TextBox 
              isValid={validateField(colDef, props.entry[key])}
              value={fieldValue}
              readOnly = {fieldReadOnly}
              onValueChanged={(e:any) => {
                props.deDispatch({type:'valueChange', data:{[key]:e.value}}
              )}}/>
          )
        }
        const formfield = (
          <div className="formfield" key={key}>
            <span className="formfieldLabel">{colDef.description_short}</span>
            <div className="formFieldInput">{inputElement}</div>
          </div>
        )
        columns.push(formfield)
      }else{
        const dropdownElement = (
          <div className="formfield" key={key}>
            <span className="formfieldLabel">{colDef.description_short}</span>
            <div className="formFieldInput">
              <SelectBox
                isValid={validateField(colDef, props.entry[key])}
                items = {(fkData as any)[key]}
                readOnly = {fieldReadOnly}
                displayExpr={props.config.fks[key].displayExpr}
                valueExpr={props.config.fks[key].valueExpr}
                value={fieldValue}
                defaultValue={fieldDefaultValue}
                showClearButton={colDef.is_required_field === 0}
                onValueChanged={(e:any) => {
                  props.deDispatch({type:'valueChange', data:{[key]:e.value}}
                )}}
              />
            </div>
          </div>
        );
        columns.push(dropdownElement)
      }
    }
  });

  Object.keys(props.entry).forEach((key:string) => {
    if(typeof props.entry[key] !== 'object'){ //dont add include data () as hidden fields
      let match = false;
      props.config.columns.forEach((colDef:ColumnDefinition) => {
        if(colDef.dataField === key){
          match = true;
        }
      });

      if(!match){
        const hiddenField = (
          <TextBox value={props.entry[key]} key={key} visible={false} />
        );
        columns.push(hiddenField);
      }
    }
  });
  
  return (
    <>
      <Loading textMessage={<span>Loading&#8230;</span>} show={!fkDataExists} />
      <Row>
        <Col>
          <div className="propertyColumns">
            {columns}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Property;


