import React from "react";
import { Row, Col } from "react-bootstrap";
import { SelectBox } from "devextreme-react";
import { ApiRow, ApiHit } from "./types";

type ComparisonListProps = {
  apiResult: ApiRow[],
  setApiResult: (a: ApiRow[]) => void
}

export function ComparisonList(props: ComparisonListProps): JSX.Element {
  const rows = props.apiResult.map((item: ApiRow, index: number) => {
    //change function for current row

    const setSelectedRow = (index:number, hit:ApiHit) => {
      let newApiResult = [...props.apiResult];
      newApiResult[index].oSelected = hit
      props.setApiResult(newApiResult);
    }

    const rowItem = {
      oSearch: {...item.oSearch},
      oSelected: item.oSelected,
      aHits: [
        ...item.aHits,
        {
          article_id: "",
          article_label: "(empty)",
          sku: "",
          price: 0
        }
      ]
    };

    return (
      <ComparisonListRow
        key={"Row_" + index}
        item={rowItem} index={index}
        setSelectedRow={setSelectedRow}
      />
    )
  })
  return <>{rows}</>
}

type ComparisonListRowProps = {
  item: ApiRow,
  index: number,
  setSelectedRow: (i:number,o:ApiHit) => void
}

export function ComparisonListRow(props: ComparisonListRowProps): JSX.Element {
  // rate how the selected value matches our database value
  let sku_match = getSKUMatch(props.item.oSearch.sSKU,props.item.oSelected.sku)
  let price_match = getPriceMatch(props.item.oSearch.nPrice,props.item.oSelected.price)
  const identical:boolean = (
      props.item.oSearch.sSKU === props.item.oSelected.sku && 
      props.item.oSearch.nPrice === props.item.oSelected.price && 
      props.item.oSelected.article_id === props.item.aHits[0].article_id
  );

  const onChange = (e:any) => {
    if (e.value !== null) {
      if (e.value.length > 0) {
        // Set the oSelected object to the selected hit
        for (let iHit = 0; iHit < props.item.aHits.length; iHit++) {
          if (props.item.aHits[iHit].article_id === e.value) {
            props.setSelectedRow(props.index,props.item.aHits[iHit])
            break;
          }
        }
      } else {
        //selected props.item is (empty) - clear the oSelected Object
        const hit:ApiHit = {
          price: -1,
          sku: "-",
          article_label: "(empty)",
          article_id: ""
        };
        props.setSelectedRow(props.index,hit)
      }
    }
  }

  const priceFormatOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  var className = "importRow";
  className = identical ? className += " identical": className;
  className = props.item.aHits.length === 1 ? className += " empty": className;

  return (
    <Row key={"Row_" + props.index} data-testid={"comparisonRow"} className={className}>
    {/* <Row key={"Row_" + props.index} className={"importRow" + identical ? "identical" : ""}> */}
      <Col>
        <Row className={"baseRow"}>
          <Col className={"no-padding match-name"} sm={9} lg={9}>
            {props.item.oSearch.sSearchWord}
          </Col>
          <Col className={"match-price match-" + price_match} sm={1} lg={1}>
            {props.item.oSearch.nPrice.toLocaleString(
              navigator.language,
              priceFormatOptions
            )}
          </Col>
          <Col className={"match-sku match-" + sku_match} sm={2} lg={2}>
            {props.item.oSearch.sSKU}
          </Col>
        </Row>
        <hr />
        <Row className={"selectRow"}>
          <Col className={"no-padding match-name"} sm={9} lg={9}>
            <SelectBox
              id={'selectBox_' + props.index}
              displayExpr={"article_label"}
              valueExpr={"article_id"}
              placeholder={"Select the record that matches the article above"}
              dataSource={props.item.aHits}
              defaultValue={props.item.oSelected.article_id !== undefined ? props.item.oSelected.article_id : ""}
              onValueChanged={onChange}
            />
          </Col>
          <Col className={"match-price match-" + price_match} sm={1} lg={1}>
            {props.item.oSelected.price > -1 &&
              props.item.oSelected.price.toLocaleString(
                navigator.language,
                priceFormatOptions
              )}
            {props.item.oSelected.price === -1 && <>-</>}
          </Col>
          <Col className={"match-sku match-" + sku_match} sm={2} lg={2}>
            {props.item.oSelected.sku}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function getSKUMatch(baseSku:string,skuToMatch:string){
  let sku_match = "average";

  if(skuToMatch !== undefined){
    // SKU: good: identical, average: at least first half matches, bad: neither
    if (skuToMatch === baseSku) {
      sku_match = "good";
    } else {
      const halfChars = Math.floor(baseSku.length / 2);
      if (baseSku.indexOf(skuToMatch.substring(0, halfChars)) === -1) {
        sku_match = "bad";
      }
    }
  }
  return sku_match
}


function getPriceMatch(basePrice:number,priceToMatch:number){
  // Price: good: less than 10% deviation, average: 10-30% deviation, bad: 30+% deviation
  let price_match = "average";
  if(priceToMatch !== undefined){
    let priceDeviation = Math.abs(
      (priceToMatch - basePrice) / basePrice
    );
    if (priceDeviation < 0.1) {
      price_match = "good";
    } else {
      if (priceDeviation > 0.3) {
        price_match = "bad";
      }
    }
  }
  return price_match
}

export default ComparisonList;
