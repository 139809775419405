import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig, formatColumns, formatForeignKeys } from "../../models/config";
import { AdminConfig } from '../../components/MasterDetail/types';

import { process_step, pcf_pcs } from '../../models/config_all';

export function ProcessStep(props:any){
    const defaultVisibleColumns = ["name","label","description","position","prc_id"]
    const visibleColumns = props.visibleColumns !== undefined ? props.visibleColumns : defaultVisibleColumns

    let config = formatConfig(process_step,{visibleColumns: visibleColumns});
    config.relations.forEach((relationItem) => {
        let relConfig:AdminConfig;
        switch(relationItem.model){
            case 'pct_prd':
                relConfig = pcf_pcs;
                break;
            default:
                relConfig = process_step;
        }
        relationItem.pks = relConfig.primary_keys;
        relationItem.relation_table.columns = formatColumns(relConfig,{},true);
        relationItem.relation_table.fks = formatForeignKeys(relConfig.columns);
    });


    return(
        <Overview 
            model="process_step" 
            pk="pcs_id" 
            config={config}
            {...props}
        />
    )
}

export default ProcessStep;


