import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { platform } from '../../models/config_all';

export function Platform(){
    let config = formatConfig(platform);
    return(
        <Overview 
            model="platform" 
            pk="plt_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default Platform;


