import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { program } from "../../models/config_all";

export function Program(){
    let config = formatConfig(program);
    return(
        <Overview 
            model="program" 
            pk="prg_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default Program;


