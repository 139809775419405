import { Bullet, DataGrid, DateBox, SelectBox } from 'devextreme-react'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Loading from '../../components/Loading/Loading'
import { dashboardMock } from './__mocks__/dashboard';

import './SalesApp.css'
import '../../index.css'
import { Column, FilterRow } from 'devextreme-react/data-grid'
import { Font, Margin, Size, Tooltip } from 'devextreme-react/bullet'


const brands = [
    {brd_id: "1", label:"Sonicwall"},
    {brd_id: "2", label:"erwin"},
]


export function SmartSellerDashboard (props: any): JSX.Element{
    let start = new Date();
    start.setMonth(start.getMonth() > 0 ? start.getMonth() - 1 : 11);

    const [startDate, setStartDate] = useState<any>(start);
    const [endDate, setEndDate] = useState<any>(new Date());
    const [data] = useState<any>(dashboardMock);
    const [brand, setBrand] = useState<any>("1");
    const [loading, setLoading] = useState<boolean>(true);

    if(loading === true){
        const timeout = Math.floor(Math.random() * 1000);
        setTimeout(()=>{setLoading(false);},timeout);
    }

    let maxValue = 0;
    data.forEach((item: any)=>{
        if(item.count > maxValue){
            maxValue = item.count;
        }
    })


    const renderBullet = (cellData:any): JSX.Element => {
        return (
            <Bullet
                showTarget={false}
                showZeroLevel={true}
                value={cellData.value}
                startScaleValue={0}
                endScaleValue={maxValue}
                color={"#59a1ff"}
            >
                <Size width={"auto"} height={35} />
                <Margin top={5} bottom={0} left={5} />
                <Tooltip
                    enabled={true}
                    paddingTopBottom={2}
                    zIndex={5}
                    customizeTooltip={customizeTooltip}
                >
                    <Font size={18} />
                </Tooltip>
            </Bullet>
        )    
    }
    
    const customizeTooltip = (data:any) => {
        return ({text:`${parseInt(data.value)}`});
    }


    return (
        <>
        <Loading textMessage="Loading..." show={loading}/>
        <div id="detailContainer" className={"dashboardContainer"}>
            <Row>
                <Col>
                    <h1>Dashboard</h1>
                </Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row>
                <Col sm={2}>
                    <p>Start Date</p>
                    <DateBox
                        id={"start-date"}
                        value={startDate}
                        width={"100%"}
                        onValueChanged={(e:any)=>{
                            setStartDate(e.value);
                            setLoading(true);
                        }}
                    />
                </Col>
                <Col sm={2}>
                    <p>End Date</p>
                    <DateBox
                        id={"end-date"}
                        value={endDate}
                        width={"100%"}
                        onValueChanged={(e:any)=>{
                            setEndDate(e.value);
                            setLoading(true);
                        }}
                    />
                </Col>
                <Col sm={8}>
                    <p>Brand</p>
                    <SelectBox
                        elementAttr={{"data-testid":'wubbalubbadubdub'}}
                        placeholder={'Select checklist'}
                        dataSource={brands}
                        displayExpr={"label"}
                        valueExpr={"brd_id"}
                        value={brand}
                        onValueChanged={(e:any) => {
                            setBrand(e.value);
                            setLoading(true);
                        }}
                    />
                </Col>
            </Row>
            <Row><Col>&nbsp;</Col></Row>
            <Row>
                <Col>
                    <DataGrid 
                        columnChooser={{enabled:true, mode:'select'}} 
                        dataSource={data}
                    >
                        <FilterRow visible={true}/>
                        <Column caption={"Name"} dataField={"user.name"} dataType={"string"} />
                        <Column caption={"First Name"} dataField={"user.vorname"} dataType={"string"} />
                        <Column caption={"Usage"} dataField={"count"} cellRender={renderBullet} width={350} alignment={"left"}/>
                    </DataGrid>

                </Col>
            </Row>
        </div>
        </>
    )
}
