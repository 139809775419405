import React, { useState } from "react"
import { Prd_prd, Product } from "../../models/types"
import { ProductRelationType } from "../../models/types_generated";

type RelationTableProps = {
    relations: Prd_prd[],
    prt: string
}


export function RelationTable (props:RelationTableProps): JSX.Element{
    const [applyFilter,setApplyFilter] = useState(false);


    let filteredRelations = props.relations.filter((ele)=>ele.prt_id === props.prt);
    let relationData = applyFilter ? filteredRelations : props.relations;

    return (<>
        <h1>Existing Relations ({relationData.length} / {props.relations.length})</h1>

        <input id="filterCheckbox" type="checkbox" checked={applyFilter} onChange={(e)=>{setApplyFilter(e.target.checked)} }/> 
        <label htmlFor="filterCheckbox">filter relation type</label>

        {relationData.length > 0 &&
            <table className= {"relationTable"}>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Base</th>
                        <th>Target</th>
                    </tr>
                </thead>
                <tbody>
                    {relationData.map((elem:Prd_prd)=>
                        <tr key={elem.base_prd_id+"_"+elem.prd_id+"_"+(elem.product_relation_type as ProductRelationType).prt_id}>
                            <td>{(elem.product_relation_type as ProductRelationType).name || elem.prt_id}</td>
                            <td>{(elem.base_product as Product).name || elem.base_prd_id}</td>
                            <td>{(elem.product as Product).name|| elem.prd_id}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        }

        
    </>);
}