import React, { useState, useEffect } from 'react';
import { SelectBox, TextBox, Validator, ValidationSummary } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import { Pricelist } from '../../models/types';

import './PricebookShare.css';
import {ExcelPricebook, CsvPricebook} from './ExportPricebook';
import { DataLoader } from '../../models/store';

export function PricebookShare(props:any) : JSX.Element {
    const [fileFormat, setFileFormat] = useState<string|undefined>(undefined); 
    const [pricelist, setPricelist] = useState<string|undefined>(undefined);
    const [fileName, setFileName] = useState(props.name); 

    // dropdown data
    const [pricelists, setPricelists] = useState<Pricelist[]>([]); 
    useEffect(()=>{
        new DataLoader().getDatafromAPI("pricelist", {
            filter: {pbk_id: props.pbk_id},
            order: "label"
        })
        .then(result => {
                console.log(result.data);
                console.log(result.data[0]);
                if(result.status === 200){
                    setPricelists(result.data);
                    // setPricelist(result.data.length ? result.data[0].pli_id : undefined);
                    setPricelist(undefined);
                }
            }
        )
    },[props.pbk_id]) // eslint-disable-line

    const formats = ["Excel","CSV"];


    return (
        <div className="detailbackdrop shareDetail">
            <div className="propertyColumns">
                
                <div className="formfield">
                    <span className="formfieldLabel">File type</span>
                    <SelectBox 
                        placeholder={"File type"}
                        dataSource={formats}
                        onValueChanged = {(e:any) => setFileFormat(e.value)}
                        value={fileFormat}
                    >
                        <Validator>
                            <RequiredRule message={'File type is required'} />
                        </Validator>
                    </SelectBox>
                </div>

                <div className="formfield">
                    <span className="formfieldLabel">File name</span>
                    <TextBox 
                        id="file_name"
                        name={"file_name"}
                        onValueChanged = {(e:any) => setFileName(e.value)}
                        placeholder={"File name"}
                        value={fileName}
                    > 
                        <Validator>
                            <RequiredRule message={'Name is required'} />
                        </Validator>
                    </TextBox>
                </div>

                {fileFormat === "CSV" && 
                    <div className="formfield">
                        <span className="formfieldLabel">Price List</span>
                        <SelectBox 
                            value={pricelist}
                            displayExpr={'label'}
                            valueExpr={'pli_id'}
                            placeholder={"Price List"}
                            dataSource={pricelists}
                            onValueChanged = {(e:any) => setPricelist(e.value)}
                        >
                            <Validator>
                                <RequiredRule message={'Price List is required'} />
                            </Validator>
                        </SelectBox>
                    </div>
                }
            </div>
            <ValidationSummary id={'summary'}></ValidationSummary>
            {fileFormat === "Excel" && <ExcelPricebook 
                pbk_id = {props.pbk_id}
                fileName = {fileName}
                valid_from = {props.valid_from}
                valid_to = {props.valid_to}
            />}

            {fileFormat === "CSV" && pricelist !== undefined &&
                <CsvPricebook 
                    pbk_id = {props.pbk_id}
                    pli_id = {pricelist || ""}
                    fileName = {fileName}
                />
            } 
        </div>
    );
}

export default PricebookShare;