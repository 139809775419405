import React, { useReducer } from 'react';
import { useEffect } from "react";
import Property from '../../components/MasterDetail/Property';
import { pricebook } from '../../models/config_all';
import Loading from '../../components/Loading/Loading';
import { formatConfig, setColumnProp } from '../../models/config';
import { Pricebook } from '../../models/types';
import { APIStore } from '../../models/store';
import { showToast } from "../../components/handleError";
import { draftPbkStatusId } from '../../constants';

type PricebookPropertyProps = {
    pbk: Pricebook,
    setPbk: (prb:Pricebook) => void,
    setSave: (b:boolean) => void,
    saveState: boolean,
    isNew: boolean,
    readOnly?: boolean
}

export function PricebookProperty(props:PricebookPropertyProps) : JSX.Element {
    const[state, dispatch] = useReducer(reducer, undefined);

    useEffect (() => dispatch({type:'init',data:props.pbk}),[props.pbk])
    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && props.setSave !== undefined) {
            const store = new APIStore().create("pricebook", "pbk_id");
            let promise:Promise<any>
            if (props.pbk.pbk_id !== undefined && props.pbk.pbk_id !== null && props.pbk.pbk_id !== "") {
                promise = store.update(props.pbk.pbk_id, state);
            } else {
                promise = store.insert(state);
            }
            promise.then((result) => {
                if (result.status >= 200 && result.status <= 299) {
                    props.setPbk(result.data);
                    if (props.isNew) {
                        showToast("info", "Refresh menu to show the new pricebook.");
                    }
                }
                props.setSave(false);
            }).catch(()=>props.setSave(false)); 
        }
    }, [props.saveState]); // eslint-disable-line

    const config = formatConfig(pricebook);
    config.columns = setColumnProp(config.columns,"pbs_id","default_value",draftPbkStatusId)

    return (
        <div className="detailbackdrop">
            {state === undefined && <Loading textMessage={<span>Loading&#8230;</span>} show={true} />}
            {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
            {state !== undefined && <Property 
                readOnly={props.readOnly || false}
                entry={state} 
                config={config} 
                deDispatch={dispatch}
            />}
        </div>
    )
}

function reducer(state: any, action:any) {
    let newState = {...state};
    switch(action.type){
        case 'init':
            newState = action.data;
            break;
        case 'valueChange':
            newState = Object.assign(newState,action.data)
            break;
        default:
            return state;
    }
    return newState
}


export default PricebookProperty;
