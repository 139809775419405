import React, { useReducer } from 'react'
import { SmartSellerSearch } from './SmartSellerSearch'
import { SmartSellerDetail } from './SmartSellerDetail'

import './SmartSeller.css'
import { Col, Container, Row } from 'react-bootstrap'
import { SmartSellerPin, SmartSellerPins } from './SmartSellerPins'

type SmartSellerState = {
    pinned: SmartSellerPin[],
    art_id?: string,
    pbk_id?: string,
    pli_id?: string
}

export type SmartSellerAction = {
    type: string,
    data?: any
}

function reducer(state:SmartSellerState, action:SmartSellerAction) {
    let newState = {...state};
    let savePins = false;
    switch(action.type){
        case "set":
            if(action.data.art_id !== undefined){
                newState.art_id = action.data.art_id;
            }
            if(action.data.pbk_id !== undefined){
                newState.pbk_id = action.data.pbk_id;
            }
            if(action.data.pli_id !== undefined){
                newState.pli_id = action.data.pli_id;
            }
            break;
        case "setArtID":
            newState.art_id = action.data.art_id
            break;
        case "setPbkID":
            newState.pbk_id = action.data.pbk_id
            break;
        case "setPliID":
            newState.pli_id = action.data.pli_id
            break;
        case "addPin":
            let duplicate = false;
            for(let pin of state.pinned){
                if(pin.article.art_id === action.data.article.art_id){
                    if(pin.pricelist.pli_id === action.data.pricelist.pli_id){
                        duplicate = true;
                        break;
                    }
                }
            }
            if(!duplicate){
                newState.pinned.push(action.data);
                savePins = true;
            }
            break;
        case "removePin":
            newState.pinned = [];
            for(let curPin of state.pinned){
                if(!(curPin.article.art_id === action.data.article.art_id 
                    && curPin.pricelist.pli_id === action.data.pricelist.pli_id)){
                    newState.pinned.push(curPin);
                }
            }
            savePins = true;
            break;
        case "selectPin":
            newState.art_id = action.data.article.art_id;
            newState.pbk_id = action.data.pricelist.pbk_id;
            newState.pli_id = action.data.pricelist.pli_id;
            break;
        case "clearPins":
            newState.pinned = [];
            savePins = true;
            break;
        case "goBack":
            newState.art_id = undefined;
            newState.pbk_id = undefined;
            break;
        default:
    }
    if(savePins){
        localStorage.setItem("pins",JSON.stringify(newState.pinned));
    }
    return newState;
}


export function SmartSellerMain (props: any): JSX.Element{
    let pins = localStorage.getItem("pins") !== null ? JSON.parse(localStorage.getItem("pins") as string) : [];
    
    const [state, dispatch] = useReducer(reducer, {
        pinned:pins,
        art_id:props.art_id || undefined,
        pbk_id:props.pbk_id || undefined
    });

    let activePage:JSX.Element
    if(state.art_id !== undefined && state.pbk_id !== undefined){
        activePage = <SmartSellerDetail 
            art_id={state.art_id as string} 
            pbk_id={state.pbk_id as string} 
            pli_id={state.pli_id}
            pinned={state.pinned}
            dispatch={dispatch}
        /> 
    }else{
        activePage = <SmartSellerSearch dispatch={dispatch}/>
    }


    return (
        <Container id="detailContainer">
            <Row>
                <Col sm={9}>
                    {activePage}
                </Col>
                <Col sm={3} className="pinnedList">
                    <SmartSellerPins 
                        pins={state.pinned}
                        art_id={state.art_id}
                        pbk_id={state.pbk_id}
                        pli_id={state.pli_id}
                        dispatch={dispatch}
                    />
                </Col>
            </Row>
        </Container>
    )
}