import React, { useEffect, useState } from "react"
import { DataLoader } from "../../../models/store";
import Loading from "../../../components/Loading/Loading";
import { Button } from "react-bootstrap";
import { SmartSellerAction } from "./SmartSellerMain";
import { Pricelist } from "../../../models/types_generated";
import showToast from "../../../components/handleError";

//#######################################################################################
//   S E L E C T E D     A R T I C L E (left side)
//#######################################################################################

type ArticleDetailProps = {
    art_id: string,
    pli_id: string,
    dispatch: (action:SmartSellerAction) => void,
    navigateTo?: (a:number) => void,
    pricelists?: Pricelist[]
}

const dataLoader:DataLoader = new DataLoader();

export function ArticleDetail (props: ArticleDetailProps): JSX.Element{
    const [selectedArticle,setSelectedArticle] = useState<any|undefined>(undefined)
    
    useEffect(()=>{
        if(props.art_id.length > 0 && props.pli_id !== undefined && props.pli_id !== ""){
            let url = "sales/smartseller/"+props.art_id
            if(props.pli_id.length > 0){
                url += '?pli_id='+props.pli_id
            }
            dataLoader.getDatafromAPI(url)
            .then((result)=>{
                setSelectedArticle(result.data)
            })
        }
    },[props.art_id,props.pli_id])

    const restartSmartSeller = (id:string) => {
        if(props.dispatch !== undefined){
            props.dispatch({type:"set",data:{
                art_id:id,
                pli_id:props.pli_id
            }})
            if(props.navigateTo !== undefined){
                props.navigateTo(0)
            }
        }
    }

    if(selectedArticle === undefined){
        return (
            <Loading 
                textMessage={<span>Loading&#8230;</span>} 
                show={selectedArticle === undefined} 
            />
        )
    }else{
        let priceFormatOptions:any = {style:"decimal",minimumFractionDigits:2,maximumFractionDigits:2}
        if(selectedArticle.article.price.currency.short_name.length){
            priceFormatOptions = {
                style: "currency",
                currency: selectedArticle.article.price.currency.short_name 
            }
        }
        let formattedPrice = ' - ';
        let formattedMargin = ' - ';
        if(selectedArticle.article.price.value !== null){
            formattedPrice = selectedArticle.article.price.value.toLocaleString(window.navigator.language,priceFormatOptions);
            formattedMargin = (selectedArticle.article.list_price - selectedArticle.article.price.value).toLocaleString(window.navigator.language,priceFormatOptions);
        }

        return (
            <div className="articleDetail">
                <table><tbody>
                    <tr>
                        <td className="mainCaption">
                            SKU &nbsp;
                            <i className="fas fa-copy" onClick={()=>{
                                if(navigator.clipboard){
                                    navigator.clipboard.writeText(selectedArticle.article.sku)
                                    showToast("success","SKU copied to clipboard!")
                                }else{
                                    window.prompt("Copy to clipboard: Ctrl+C, Enter", selectedArticle.article.sku);
                                }
                            }}></i>
                        </td>
                        <td>
                            {selectedArticle.article.sku}
                        </td>
                    </tr>
                    <tr>
                        <td className="mainCaption">Price</td><td>{formattedPrice}</td>
                    </tr>
                    <tr>
                        <td className="mainCaption">Margin</td><td>{formattedMargin}</td>
                    </tr>
                    <tr>
                        <td className="mainCaption">Product</td><td></td>
                    </tr>
                    <tr>
                        <td className="subCaption">Brand</td><td>{selectedArticle.product.family.brand.label}</td>
                    </tr>
                    <tr>
                        <td className="subCaption">Family</td><td>{selectedArticle.product.family.label}</td>
                    </tr>
                    {selectedArticle.categories.map((cat:any)=>{
                            return (
                                <tr key={cat.pct_id}>
                                    <td className="subCaption">{cat.product_category.name}</td><td>{cat.pct_values.value}</td>
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td className="subCaption">Name</td><td>{selectedArticle.product.label}</td>
                    </tr>
                    {/* <tr>
                        <td className="subCaption">Description</td><td>{selectedArticle.product.description}</td>
                    </tr> */}

                    {selectedArticle.maintenance !== null &&
                        <>
                            <tr>
                                <td className="mainCaption">Maintenance</td><td></td>
                            </tr>
                            <tr>
                                <td className="subCaption">Type</td><td>{selectedArticle.maintenance.maintenance_type.name || "-"}</td>
                            </tr>
                            <tr>
                                <td className="subCaption">Name</td><td>{selectedArticle.maintenance.name || "-"}</td>
                            </tr>
                            <tr>
                                <td className="subCaption">Duration</td><td>{selectedArticle.maintenance.duration || "-"}</td>
                            </tr>
                        </>
                    }

                </tbody></table>
                <Button onClick={(e:any)=>
                {
                    let activeList:Pricelist|undefined = undefined;
                    for(let curList of props.pricelists as Pricelist[]){
                        if(curList.pli_id === props.pli_id){
                            activeList = curList;
                            break;
                        }
                    }
                    return props.dispatch({
                        type:"addPin",
                        data:{
                            article: selectedArticle.article,
                            pricelist: activeList
                        }
                    }) as unknown as ((action: SmartSellerAction) => void)
                }
                }>
                    <i className="fas fa-thumbtack"></i>&nbsp; pin this article
                </Button>
                <Button onClick={(e:any)=>restartSmartSeller(selectedArticle.article.art_id)}>
                    <i className="fas fa-redo"></i>&nbsp; restart with this article
                </Button>
            </div>
        )
    }

}

export default ArticleDetail