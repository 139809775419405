import React, { useState, useEffect } from 'react';
import {User, Audience} from  '../../models/types';
import Loading from '../../components/Loading/Loading';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Row, Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { SelectBox, DataGrid } from 'devextreme-react';
import { Editing, Paging, Pager, FilterRow, Column, RequiredRule, EmailRule } from 'devextreme-react/data-grid';
import { Ajax } from '../../models/ajax';

export function PartnerManage(props:any) : JSX.Element {
    const [save, setSave] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean|undefined>(true);
    const [audiences, setAudiences]= useState<Audience[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [activeAudience, setActiveAudience] = useState<Audience|undefined>(undefined);
    const [dataGridInstance, setDataGridInstance]= useState<any>(undefined);

    //get Audiences
    useEffect (() => {
        new Ajax('audience?clt_id=' + localStorage.getItem("client"),{
        }).setMessages("").execute()
        .then(result=>{
            if(result.data.length){
                setActiveAudience(result.data[0].aud_id)
            }
            setAudiences(result.data)})
        .catch(()=>setAudiences([]))
        
    }, [props]); // eslint-disable-line

    //get users in active Audience | ToDo: alle möglichen bekannten User
    useEffect (() => {
        if (activeAudience !== undefined) {
            setLoading(true)
            new Ajax('usr_aud/audience?aud_id=' + activeAudience,{
            }).setMessages("").execute()
            .then(result=>setUsers(result.data))
            .catch(()=>setUsers([]))
            .finally(()=>calculateLoadingState())
        }
    }, [activeAudience]); // eslint-disable-line

    //update dataGrid Selection, when selected User or audience Change
    // useEffect (() => {
    //     var selection:string[] = [];
    //     if (selectedUsers !== undefined) {
    //         selectedUsers.forEach((pliaud:Pli_Aud) => {
    //             selection.push(pliaud.aud_id);
    //         });
    //     }
    //     if (dataGridInstance !== undefined) {
    //         dataGridInstance.selectRows(selection, false);
    //     }
    // }, [selectedUsers, activeAudience]); // eslint-disable-line

    const calculateLoadingState = () => {
        if(audiences !== undefined){
            setLoading(false)
        }
    }
    
    const store = new ArrayStore({
        key: "usr_id", 
        data: users
    });
    const ds = new DataSource({
        store: store,
        sort: "name"
    });

    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(save === true && dataGridInstance !== undefined) {
            new Ajax('usr_aud/save',{
                method: "POST",
                body: JSON.stringify({data: users, aud_id: activeAudience})
            }).setMessages("Save successful!","Save failed!")
            .execute().finally(()=>setSave(false))
        }
    }, [save]); // eslint-disable-line

    //Check if user with mail exists
    const checkMail = () =>{
        // setLoading(true);
        // console.log("check");
        // new Ajax('user/mail?email='+e.data.email,{
        // }).setMessages("User found!","User doesn't exists!").execute()
        // .then(result=>{
        //     setUsers(... result.data);
        // })
        // .finally(()=>calculateLoadingState())
    };

    return(<>
        <h4 className="overviewTitle">Manage Partner</h4>
        <div id="detailContainer">
            <Loading textMessage={<span>Saving&#8230;</span>} show={save} />
            <Loading textMessage={<span>Loading&#8230;</span>} show={loading} />
            <Button className="btn-detail"
                variant="primary"
                onClick={() => {
                    setSave(true)
                }}>
                <span className = 'fa fa-save'></span> Save
            </Button>
            <Row className={"DropownContainer formfield"}>
                <Col xs sm = {"auto"} className={"DropdownDescription formfieldLabel"}>
                    <span className={"noMargin"}>Select audience:</span>
                </Col>
                <Col>
                    <SelectBox
                        value = {activeAudience}
                        displayExpr={'name'}
                        valueExpr={'aud_id'}
                        placeholder={'Select audience'}
                        dataSource={audiences}
                        onValueChanged={(e:any) => {setActiveAudience(e.value)}}
                        height = {"30px"}
                    />
                </Col>
            </Row>
            
            <div id="gridContainer" className="noOuterPadding">
                <DataGrid 
                    dataSource={ds}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onRowInserting={checkMail}
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    columnChooser={{enabled:false, mode:'select'}} 
                    loadPanel={{enabled:false}}
                    >
                    <Editing
                        useIcons={true}
                        mode = {"row"}
                        allowAdding = {true}
                        allowDeleting = {true}
                    />
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                    <Column 
                        dataField = {"name"}
                        caption = {"Last Name"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        allowEditing={false}
                    />             
                    <Column 
                        dataField = {"vorname"}
                        caption = {"First Name"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        allowEditing={false}
                    />     
                    <Column 
                        dataField = {"email"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        allowEditing={true}
                    >
                        <RequiredRule />
                        <EmailRule />
                    </Column>            
                </DataGrid>
            </div>
        </div>
    </>
    );
}

export default PartnerManage;