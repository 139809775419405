import React from 'react';

import './Header.css';
import { Link } from 'react-router-dom';

type HeaderProps = {
  [key:string]: any,
  app: "pricebooks"|"sales",
  handleLogout: (e:React.MouseEvent<any>) => void
}

export function Header(props:HeaderProps){
  let mainTarget = props.app === "sales" ? '/sales/main' : '/main'
  let loginTarget = props.app === "sales" ? '/sales' : '/'

  return (
    <>
      <div className="header">
        <div className="header-options">
          <label htmlFor="menuSwitch">
            <div className="navPill menuVisible">
              <i className="fas fa-eye-slash"></i>
              <span>Hide Menu</span>
            </div>
            <div className="navPill menuHidden">
              <i className="fas fa-eye"></i>
              <span>Show Menu</span>
            </div>
          </label>
          <Link to={mainTarget} > {/*eslint-disable-line*/}
            <div className="navPill">
              <i className="fa fa-list-alt"></i>
              <span>Index</span>
            </div>
          </Link>
          <Link to={loginTarget} onClick={props.handleLogout}> {/*eslint-disable-line*/}
            <div className="navPill">
              <i className="fa fa-ban"></i>
              <span>Logout</span>
            </div>
          </Link>
        </div>

        <ClaimBox app={props.app}/>
        
      </div>

    </>
  );
}

export function ClaimBox(props:{
  app: "pricebooks" | "sales", 
  style?:{[key:string]:any}
}){
  return (
    <div id={"claim-box"} style={props.style} className={"claim-"+props.app}>
      <i className={"fas fa-circle"} id={"claim-circle"}></i>
      &nbsp;&nbsp; Sell IT smart!
    </div>
  )
}

export default Header;


