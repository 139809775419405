import React from 'react';
import { Overview } from "../../components/MasterDetail/Overview";
import { formatConfig } from "../../models/config";

import { currency } from '../../models/config_all';

export function Currency(){
    let config = formatConfig(currency);

    
    return(
        <Overview 
            model="currency" 
            pk="cur_id" 
            config={config}
            pageOptions={{hideDetailPage:true}}
            editorOptions={{
                mode:"form",
                allowAdding:true,
                allowUpdating:true,
                allowDeleting:true
            }}
        />
    )
}

export default Currency;


