export type MenuObject = {
    [key:string]: string|boolean|object|number|undefined
    id: string,
    text: string,
    level: number,
    type?: string,
    objectType?: string,
    expanded?: boolean,
    items?: MenuObject[],
    icon?: string
}

export const menuStandard:MenuObject[] = [
    //{id:"dashboard", text:"Dashboard", level: 0},
    {
        id:"productCategory",
        text: "Products",
        //icon: "fa fa-barcode",
        icon: "fa fa-tags",
        expanded: true,
        type: "category",
        level: 0,
        items: [
            {id:"brand",text:"Brand", level: 1},
            {
                id:"product_family",
                text:"Product Family",
                expanded: true,
                level:1,
                items: []
            },
        ]
    },
    {
        id: "spacer1",
        type: "empty",
        text: "",
        level: 0,
    },
    {
        id:"relations",
        text: "Relations",
        icon: "fa fa-arrow-right-arrow-left",
        // type: "directRecordLink",
        level: 0
    },
    {
        id: "spacer2",
        type: "empty",
        text: "",
        level: 0,
    },
    {
        id:"pricebookCategory",
        text: "Price Books",
        icon: "fa fa-book-open",
        expanded: true,
        type: "category",
        level: 0,
        items: [
            {
                id:"pricebook",
                text:"Price Book", 
                expanded: true,
                level: 1,
                items: []
            },
            {
                id:"comparePricebooks",
                text:"Compare", 
                level: 1,
            },
        ]
    },
    {
        id: "spacer3",
        type: "empty",
        text: "",
        level: 0,
    },
    {
        id:"partner",
        text: "Partners",
        icon: "fa fa-handshake",
        expanded: true,
        type: "category",
        level: 0,
        items: [
            // {
            //     id:"share",
            //     text:"Share", 
            //     level: 1,
            // },
            {
                id:"manage",
                text:"Manage", 
                level: 1,
            },
            {
                id:"audience",
                text:"Audiences",
                level: 1,
            },
        ]
    },
    {
        id: "spacer4",
        type: "empty",
        text: "",
        level: 0,
    },
    {
        id:"sales",
        text: "Sales",
        icon: "fa fa-dollar",
        expanded: true,
        type: "category",
        level: 0,
        items: [
            {
                id:"process",
                text:"Process", 
                level: 1,
            },
            {
                id:"process_steps",
                text:"Process Steps",
                level: 1,
            },
        ]
    },

]

export const menuConfig:MenuObject[] = [
    {
        id:"productsCategory",
        type: "category",
        text: "Products",
        expanded: true,
        level: 0,
        items: [
            // {id:"article",text:"Article", level: 1},
            {id:"products",text:"Products", level: 1},
            // {id:"product_category",text:"Product Category", level: 1},
            {id:"property",text:"Property", level: 1},
            //{id:"language",text:"Language", level: 1},
            {id:"platform",text:"Platform", level: 1},
            {id:"size",text:"Size", level: 1},
            {id:"status",text:"Product Status" , level: 1},
            {id:"product_relation_type",text:"Product Relation Type", level: 1},
            {id:"product_type",text:"Product Type", level: 1},
            {id:"unit",text:"Unit", level: 1},
            {id:"version",text:"Version", level: 1},
            //{id:"version_relation_type",text:"Version Relation Type", level: 1}
        ]
    },
    {
        id: "spacer1",
        type: "empty",
        text: "",
        level: 0,
    },
    {
        id:"price_books",
        type: "category",
        text: "Price Books",
        expanded: true,
        level: 0,
        items: [
            {id:"currency",text:"Currency", level: 1},
            {id:"maintenance",text:"Maintenance", level: 1},
            {id:"maintenance_type",text:"Maintenance Type", level: 1},
            {id:"partner_tier",text:"Partner Tier", level: 1},
            {id:"program",text:"Program", level: 1},
        ]
    }
]


export const menuSales:MenuObject[] = [
    //{id:"dashboard", text:"Dashboard", level: 0},
    {
        id:"partners",
        text: "Partners",
        appString: "sales/",
        //icon: "fa fa-barcode",
        icon: "fa fa-handshake",
        // expanded: true,
        // type: "category",
        level: 0,
    },
    {
        id: "spacer1",
        type: "empty",
        text: "",
        level: 0,
    },
    {
        id:"pricebookCategory",
        text: "Price Books",
        icon: "fa fa-book-open",
        expanded: true,
        type: "category",
        level: 0,
        items: [
            //{id:"audience",text:"Audience"},
            {
                id:"pricebook",
                text:"Price Books", 
                expanded: true,
                appString: "sales/",
                level: 1,
                items: []
            },
            {
                id:"archive",
                text:"Archive", 
                expanded: false,
                appString: "sales/",
                level: 1,
                items: []
            }
        ]
    },
    {
        id: "spacer2",
        type: "empty",
        text: "",
        level: 0,
    },
    {
        id:"smartseller",
        text: "Smartseller",
        appString: "sales/",
        icon: "fa fa-lightbulb",
        level: 0,
    },
    {
        id:"checklist",
        text: "Process",
        appString: "sales/",
        icon: "fa fa-check-square",
        level: 0,
    },
    {
        id:"dashboard",
        text: "Dashboard",
        appString: "sales/",
        icon: "fa fa-chart-line",
        level: 0,
    },
]