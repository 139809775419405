import React, { useState, useEffect } from 'react';
import { DataGrid } from 'devextreme-react';
import { Paging, Pager, FilterRow, Column, Editing } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { publishedPbkStatusId } from '../../constants';

import { art_pbk, Pricebook } from '../../models/types'; // eslint-disable-line
import Loading from '../../components/Loading/Loading';
import BulkEdit from '../../components/BulkEdit/BulkEdit';
import { Ajax } from '../../models/ajax';

import { DataLoader } from '../../models/store';

type PricebookArticleEditProps = {
    saveState: boolean,
    setSave: (b:boolean)=>void,
    pricebook: Pricebook
}


export function PricebookArticleEdit(props:PricebookArticleEditProps) : JSX.Element {
    const [reload,setReload]:boolean | any = useState(true);
    const [art_pbk, setArt_pbk]:art_pbk[] | any = useState(undefined);
    const [dataGridInstance, setDataGridInstance]: any = useState(undefined);
    const readOnly = props.pricebook.pbs_id === publishedPbkStatusId;

    //get all art_pbk data
    const dl = new DataLoader();
    useEffect(() => {
        dl.getDatafromAPI("art_pbk", {filter: {pbk_id: props.pricebook.pbk_id}, include: "article"})
        .then(result => setArt_pbk(result.data));
    }, [props.pricebook.pbk_id]);  // eslint-disable-line

    //listens on Save State, if state is toggled save content
    useEffect (() => {
        if(props.saveState === true && dataGridInstance !== undefined) {
            new Ajax('pricebook/articles/save/'+props.pricebook.pbk_id,{
                method: "PATCH",
                body: JSON.stringify({art_pbk: art_pbk})
            }).setMessages("Successfully edited pricebook articles!","Could not edit pricebook articles!")
            .execute().finally(()=>props.setSave(false))
        }
    }, [props.saveState]); // eslint-disable-line

    const store = new ArrayStore({
        key: "art_id",
        data: art_pbk
    });
    
    const ds = new DataSource({
        store: store,
        sort: "name"
    });

    var renderPriceColumn = (data:art_pbk) => {
        let articlePrice = data.article !== undefined ? data.article.price : undefined;
        if(articlePrice !== undefined){
            articlePrice *= props.pricebook.factor > 0 ? props.pricebook.factor : 1;
        }

        const priceFormatOptions = {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }
 
        if (articlePrice !== undefined && data.price.toFixed(2) !== articlePrice.toFixed(2)) {
            return (<div>{data.price.toLocaleString(navigator.language,priceFormatOptions)}<br/><span className = "oldValueFromUpdate">Article: {articlePrice.toLocaleString(navigator.language,priceFormatOptions)}</span></div>)
        } else {
            return data.price.toLocaleString(navigator.language,priceFormatOptions);
        }
    }

    return (
        <>
            <Loading textMessage={<span>Loading&#8230;</span>} show={art_pbk === undefined} />
            <Loading textMessage={<span>Saving&#8230;</span>} show={props.saveState} />
            {!readOnly && 
                <BulkEdit
                    dataGridInstance = {dataGridInstance}
                    data = {art_pbk}
                    setData = {setArt_pbk}
                    editColumn = {"price"}
                    dsKey = {"art_id"}
                    options = {{
                        fields: ["name","label","sku","price"],
                        resetSource: "article"
                    }}
                />
            }
            <div id="gridContainer" className="noOuterPadding">
                {art_pbk === undefined && <Loading textMessage={<span>Loading&#8230;</span>} show={true} />}
                {props.saveState && <Loading textMessage={<span>Saving&#8230;</span>} show={true} />}
                <DataGrid
                    dataSource={ds}
                    onContentReady={(e:any) => setDataGridInstance(e.component)}
                    onSelectionChanged={(e:any) => setReload(!reload)}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnChooser={{enabled:false, mode:'select'}}
                    loadPanel={{enabled:false}}
                    selection = {readOnly ? {} : {
                        mode:"multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always",
                        allowSelectAll: true
                    }}
                >
                    <Editing
                        useIcons={true}
                        mode = {"form"}
                        allowUpdating= {!readOnly}
                        allowDeleting= {false} 
                    />
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                    <Column 
                        dataField = {"label"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        width={"70%"}
                    />
                    <Column 
                        caption={"SKU"}
                        dataField = {"sku"}
                        alignment={"left"}
                        showInColumnChooser={false}
                        width={"15%"}
                    />
                    <Column 
                        dataField = {"price"}
                        alignment={"right"}
                        showInColumnChooser={false}
                        dataType={"number"}
                        format={"#,##0.00"}
                        allowEditing={true}
                        cellRender={(e:any) => renderPriceColumn(e.data)}
                        width={"15%"}
                    />
                    
                </DataGrid>
            </div>
        </>
    );
}

export default PricebookArticleEdit;