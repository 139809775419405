import React, { useState, useEffect } from 'react';
import DetailLayout from '../../components/Layout/Layout';
import { Tab } from 'react-bootstrap';
import PricebookArticle from './PricebookArticle';
import PricebookProperty from './PricebookProperty';
import PricebookArticleEdit from './PricebookArticleEdit';
import PricelistArticles from './PricelistArticles';
import PricebookVisibility from './PricebookVisibility';
import PricebookShare from './PricebookShare';
import {Pricebook} from  '../../models/types';
import { Ajax } from '../../models/ajax';
import PriceList from '../Overviews/PriceList';
import { pbkPropertyInfo, pbkArticlesInfo, pricelistArticleInfo, pbkArticlesEditInfo, pbkShareInfo, priceBookPublishInfo } from '../../models/InfoTexts';
import PublishPricebook from './PublishPricebook';
import { publishedPbkStatusId } from '../../constants';

const pricebookTemplate = {
    pbk_id: "",
    valid_from: "",
    valid_to: "",
    name: "",
    label: "",
    factor: 1,
    cur_id: null,
    pbs_id: null,
    clt_id: localStorage.getItem("client"),
    lng_id: null
}

export function PricebookWorkflow(props:any) : JSX.Element {
    const [pricebook, setPricebook]:Pricebook | any = useState(pricebookTemplate);
    const [saveButton, setSaveButton]:boolean | any = useState(false);

    const denyWorkflow = (pricebook.pbk_id === undefined || pricebook.pbk_id === "");

    let pbk_id:string = "";
    if (props.match !== undefined && props.match.params.id !== undefined)
        pbk_id = props.match.params.id;
    else if (pricebook.pbk_id !== "")
        pbk_id = pricebook.pbk_id;
    else if (props.pbk_id !== undefined)
        pbk_id = props.pbk_id;
    
    //API call
    useEffect(() => {
        if (pbk_id !== "") {
            new Ajax('pricebook/'+pbk_id+'?include=pricebook_status',{})
            .setMessages("").execute()
            .then(result=>setPricebook(result.data));
        } else {
            setPricebook(pricebookTemplate);
        }
    }, [pbk_id,saveButton]);

    let readOnly = false
    if(pricebook.pbs_id === publishedPbkStatusId){
        readOnly = true
    }

    //saveFunction
    let doSave:(b:boolean)=>void = (b:boolean) => {
        setSaveButton(b);
    }

    const content = {
        pricebookProperty: {
            shortHelpText: pbkPropertyInfo.short,
            fullHelpText: pbkPropertyInfo.long, 
            showSaveButton: !readOnly,
            component: (
                <PricebookProperty 
                    readOnly={readOnly}
                    pbk={pricebook} 
                    setPbk={setPricebook}
                    setSave = {doSave} 
                    saveState = {saveButton}
                    isNew = {denyWorkflow}
                />
            )
        },
        articles: {
            shortHelpText: pbkArticlesInfo.short ,
            fullHelpText: pbkArticlesInfo.long, 
            showSaveButton: !readOnly,
            component: (
                <PricebookArticle
                    pbk_id = {pbk_id}
                    setSave = {doSave} 
                    saveState = {saveButton}
                    pbk_factor = {pricebook.factor}
                />
            )
        },
        articles_edit: {
            shortHelpText: pbkArticlesEditInfo.short ,
            fullHelpText: pbkArticlesEditInfo.long,
            showSaveButton: !readOnly, 
            component: (
                <PricebookArticleEdit
                    setSave = {doSave} 
                    saveState = {saveButton}
                    pricebook = {pricebook}
                />
            )
        },
        pricelist: {
            component: (
                <PriceList 
                    filter={["pbk_id", "=", pbk_id ]} 
                    selectedPKs={{pbk_id:pbk_id}}
                    pageOptions={{hideHeader:true,hideDetailPage:true}}
                    editorOptions={{mode:"form",allowAdding:!readOnly,allowUpdating:!readOnly,allowDeleting:!readOnly}} 
                />
            )
        },
        prices: {
            shortHelpText: pricelistArticleInfo.short,
            fullHelpText: pricelistArticleInfo.long,
            showSaveButton: !readOnly, 
            component: (
                <PricelistArticles 
                    pbk_id={pbk_id} 
                    setSave = {doSave} 
                    saveState = {saveButton}
                    readOnly = {readOnly}
                />
            )
        },
        publish: {
            shortHelpText: priceBookPublishInfo.short,
            fullHelpText: priceBookPublishInfo.long,
            showSaveButton: !readOnly,
            extendHelpText: true, 
            component: (
                <PublishPricebook 
                    pbk={pricebook}
                    saveButton={saveButton}
                    setSaveButton={setSaveButton}/>
            )
        },
        visibility: {
            shortHelpText: "Configure which audience will be able to see yout price lists.",
            fullHelpText: "Select a price list from the dropdown above. Check all audiences that should be able to see this list and hit save.", 
            component: (
                <PricebookVisibility
                    pbk_id={pbk_id} 
                    setSave = {doSave} 
                    saveState = {saveButton}
                    readOnly = {readOnly}
                />

            )
        },
        share: {
            shortHelpText: pbkShareInfo.short,
            fullHelpText: pbkShareInfo.long, 
            component: (
                <PricebookShare 
                    pbk_id={pbk_id} 
                    name={pricebook.name}
                />

            )
        }
    };

    const backFunction = props.dispatch === undefined ? undefined : () => {props.dispatch({type:"back"})}

    return(
        <DetailLayout 
            content={content} 
            pageTitle={pricebook.name} 
            backFunction={backFunction}
            setSave = {doSave} 
            saveState = {saveButton}
            isWorkflow = {true}
        >
            <Tab eventKey="pricebookProperty" title="Properties"/>
            <Tab eventKey="articles" title="Articles" disabled={denyWorkflow}/>
            <Tab eventKey="articles_edit" title="Edit Articles" disabled={denyWorkflow}/>
            <Tab eventKey="pricelist" title="Price List" disabled={denyWorkflow}/>
            <Tab eventKey="prices" title="Prices" disabled={denyWorkflow}/>
            <Tab eventKey="publish" title="Publish" disabled={denyWorkflow}/>
            <Tab eventKey="visibility" title="Visibility" disabled={denyWorkflow}/>
            <Tab eventKey="share" title="Share" disabled={denyWorkflow}/>
        </DetailLayout>
    );
}

export default PricebookWorkflow;
