import React, { useEffect, useState } from 'react';
import DetailLayout from '../../components/Layout/Layout';
import { Tab, Row, Col } from 'react-bootstrap';
import { SelectBox, Button } from 'devextreme-react';
import PricebookArticleCompare from './PricebookArticleCompare';
import { pricebookCompareInfo } from '../../models/InfoTexts';
import { Pricebook } from '../../models/types';
import { DataLoader } from '../../models/store';

export function ComparePricebooksLayout(props:any) : JSX.Element {
    const [pricebooks, setPricebooks]:Pricebook[] | any = useState(undefined);
    const [basePbk, setBasePbk]:string | any = useState(undefined);
    const [targetPbk, setTargetPbk]:string | any = useState(undefined);
    const [reload, setReload]:boolean | any = useState(false);

    const dataLoader:DataLoader = new DataLoader();
    
    //API call
    useEffect(() => {
        dataLoader.getDatafromAPI("pricebook", {order: "label"})
        .then(result=>setPricebooks(result.data));
    }, []); // eslint-disable-line


    const content = {
        //Implementierung in Planung
        /* 
        propertyComparison: {
            shortHelpText: "",
            fullHelpText: "",
            component: (<PricebookPropertyCompare basePbk = {basePbk} targetPbk = {targetPbk} reload = {reload} setReload = {setReload}/>)
        },    
        pricelistComparison: {
            shortHelpText: "",
            fullHelpText: "",
            component: (<PricebookPricelistCompare basePbk = {basePbk} targetPbk = {targetPbk} reload = {reload} setReload = {setReload}/>)
        }    
        */
        articleComparison: {
            shortHelpText: pricebookCompareInfo.short,
            fullHelpText: pricebookCompareInfo.long,
            component: (
                <PricebookArticleCompare 
                    pricebooks = {pricebooks}
                    basePbk = {basePbk}
                    targetPbk = {targetPbk}  
                    reload = {reload}  
                    setReload = {setReload}
                />
            )
        },
        export: {
            shortHelpText: "",
            fullHelpText: "",
            component: (
                <></>
            )
        }
    };

    return(
        <>
            <div id = {"detailContainer"} className = {"container-fluid"}>
                <h4 className = {"pageTitle"}>Compare price books</h4>
                <Row className = {"pricebookCompareSelectionWrapper"}>
                    <Col xs sm = {5}>
                        <span className="formfieldLabel">Select base price book:</span>
                        <SelectBox
                            value = {basePbk}
                            displayExpr={'label'}
                            valueExpr={'pbk_id'}
                            placeholder={'Select price book'}
                            dataSource={pricebooks}
                            onValueChanged={(e:any) => setBasePbk(e.value)}
                            height = {"30px"}
                        />
                    </Col>
                    <Col xs sm = {5}>
                        <span className="formfieldLabel">Select target price book:</span>
                        <SelectBox
                            value = {targetPbk}
                            displayExpr={'label'}
                            valueExpr={'pbk_id'}
                            placeholder={'Select price book'}
                            dataSource={pricebooks}
                            onValueChanged={(e:any) => setTargetPbk(e.value)}
                            height = {"30px"}
                        />
                    </Col>
                    <Col>
                        <div style={{height: 27}}></div>
                        <Button
                            className = {"btn-primary"}
                            text={"Compare"}
                            onClick={() => setReload(true)}
                            height = {"30px"}
                            width = {"120px"}
                            disabled = {basePbk === undefined || targetPbk === undefined}
                        />
                    </Col>
                </Row>
            </div>
            <DetailLayout 
                content={content} 
                pageTitle={" "} 
                backFunction={undefined}
                setSave = {undefined} 
                saveState = {undefined}
            >
                <Tab eventKey="articleComparison" title="Articles"/>
                <Tab eventKey="export" title="" disabled={true}/>
            </DetailLayout>
        </>
    );
}

export default ComparePricebooksLayout;
