import React, { useEffect, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import { Paging, Pager, FilterRow, Column, Lookup } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Article, Pricebook } from '../../models/types';
import { Ajax } from '../../models/ajax';
import Loading from '../../components/Loading/Loading';

import './ComparePricebooksLayout.css';

type ArticleCompare = {
    type: "new"|"deleted"|"edited"|"equal",
    art_id: string,
    basePbk: Article | null,
    targetPbk: Article | null,
}

type ArticleCompareProps = {
    pricebooks:Pricebook[] | any,
    basePbk:string | any,
    targetPbk:string | any,
    reload: boolean,
    setReload: (b:boolean) => void,
}

export function PricebookArticleCompare(props:ArticleCompareProps) : JSX.Element {
    const [articles, setArticles]:ArticleCompare[] | any = useState([]);

    useEffect(() => {
        if (props.basePbk !== undefined && props.targetPbk !== undefined && props.reload === true) {
            //get Articles
            new Ajax('pricebook/compare?base_pbk_id='+props.basePbk+'&target_pbk_id='+props.targetPbk,{
                method: "GET",
            }).setMessages("").execute()
            .then(result=>{
                setArticles(result.data);
                props.setReload(false);
            });
        };
    },[props.reload, props.basePbk, props.targetPbk]); // eslint-disable-line

    //set type Lookup for Type Column
    const compTypes = [
        {type: "equal"}, 
        {type: "deleted"}, 
        {type: "edited"},
        {type: "new"}
    ];
            

    const store = new ArrayStore({
        key: "art_id",
        data: articles
    });
    const ds = new DataSource({
        store: store,
        sort: "type"
    });

    //Column value calculator
    const getCellValue = (data:ArticleCompare, key:"name" | "label" | "sku") => {
        let result:string = "";
        if (data.basePbk !== undefined && data.basePbk !== null) {
            result += (data.basePbk[key] as string);
        }
        if (data.basePbk !== undefined && data.basePbk !== null) {
            if (result !== "") {
                result += " ";
            }
            result += (data.basePbk[key] as string);
        }
        return result;
    }
    //Column Renderers
    const renderColumn = (data:any, key:string) => {
        let baseValue:undefined|string|number;
        let targetValue:undefined|string|number;

        const priceFormatOptions = {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }

        //set values
        if (data.basePbk !== undefined && data.basePbk !== null) {
            if (key === "price") {
                baseValue = data.basePbk[key].toLocaleString(navigator.language,priceFormatOptions);
            } else {
                baseValue = data.basePbk[key];
            }
        }
        if (data.targetPbk !== undefined && data.targetPbk !== null) {
            if (key === "price") {
                targetValue = data.targetPbk[key].toLocaleString(navigator.language,priceFormatOptions);
            } else {
                targetValue = data.targetPbk[key];
            }
        }


        //return cases
        switch (data.type) {
            case "equal":
            case "deleted":
                return <div>{baseValue}</div>;
            case "new":
                return <div>{targetValue}</div>;
            case "edited":
                if (baseValue === targetValue) {
                    return <div>{baseValue}</div>;
                } else {
                    return <div>{baseValue}<br/>{targetValue}</div>;
                }
        }
        if (baseValue === targetValue) {
            return <div>{baseValue}</div>
        } else if (baseValue === undefined) {
            return <div>{targetValue}</div>
        }

    };
    const renderTypeColumn = (data:any) => {
        switch (data.value) {
            case "equal":
                return <span className = "fa fa-equals typeIcon"></span>;
            case "new":
                return <span className = "fa fa-plus typeIcon"></span>;
            case "edited":
                return <span className = "fa fa-not-equal typeIcon"></span>;
            case "deleted":
                return <span className = "fa fa-minus typeIcon"></span>;
        }
    };  

    return (
        <div>
            <Loading textMessage={<span>Loading&#8230;</span>} show={(props.pricebooks === undefined || props.reload === true)} />

            <div id="gridContainer" className="noOuterPadding">
                <DataGrid 
                    dataSource={ds}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnChooser={{enabled:true, mode:'select'}} 
                    loadPanel={{enabled:false}}
                    >
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 25, 50]}
                        />
                    <FilterRow visible={true}/>

                    <Column 
                        alignment={"center"}
                        dataField = {"type"}
                        cellRender = {renderTypeColumn}
                        cssClass = "verticalAlignment col_type"
                        showInColumnChooser={false}
                        width = {"150px"}
                        >
                        <Lookup
                            dataSource = {compTypes}
                            valueExpr = {"type"}
                            displayExpr = {"type"}
                        />
                    </Column>
                    <Column 
                        caption={"Label"}
                        dataField = {"basePbk.label"}
                        cellRender = {(data:any) => renderColumn(data.data, "label")}
                        calculateCellValue = {(data:ArticleCompare) => getCellValue(data, "label")}
                        //Todo: Custom Filter anlegen
                        /*
                        calculateFilterExpression = {(value:any, operator:any, target:any) => {
                            let column = this as any;
                            return [column.calculateCellValue, operator, value];
                        }}
                        */
                        cssClass = "verticalAlignment"
                        alignment = {"left"}
                    />
                    <Column 
                        caption={"Name"}
                        dataField = {"basePbk.name"}
                        cellRender = {(data:any) => renderColumn(data.data, "name")}
                        cssClass = "verticalAlignment"
                        alignment={"left"}
                        visible = {false}
                    />
                    <Column 
                        caption={"SKU"}
                        dataField = {"basePbk.sku"}
                        cellRender = {(data:any) => renderColumn(data.data, "sku")}
                        cssClass = "verticalAlignment"
                        alignment={"left"}
                    />
                    <Column 
                        caption={"Price"}
                        dataField = {"basePbk.price"}
                        cellRender = {(data:any) => renderColumn(data.data, "price")}
                        cssClass = "verticalAlignment"
                        alignment={"right"}
						format={"#,##0.00"}
                    />
                    
                </DataGrid>
            </div>
        </div>
    );
}

export default PricebookArticleCompare;
